import { StyleSheet } from 'react-native';
import { colors } from '../../../screenStyles/GlobalStyles';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  // styles pour le refresh
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingHorizontal: 12,
    paddingVertical: 8,
    backgroundColor: 'transparent',
  },
  refreshButton: {
    width: 40,
    height: 40,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  refreshIcon: {
    transform: [{ scale: 1 }],
  },

  // Styles des filtres
  filterContainer: {
    flexDirection: 'row',
    marginTop: 4,
    marginHorizontal: 12,
  },
  filterButton: {
    marginRight: 8,
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
    justifyContent: 'center',  // Ajout
    alignItems: 'center',      // Ajout
  },
  activeFilter: {
    backgroundColor: colors.primary + '15',
  },
  filterButtonText: {
    color: colors.text,
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',      // Ajout
  },
  activeFilterText: {
    color: colors.primary,
    fontWeight: '600',
  },

  // Barre de recherche
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    marginHorizontal: 12,
    marginVertical: 8,
    paddingHorizontal: 12,
    height: 48,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E0E0E0',
  },
  searchInput: {
    flex: 1,
    marginLeft: 8,
    fontSize: 14,
    color: colors.text,
  },

  // Onglets (À venir / Historique)
  tabContainer: {
    flexDirection: 'row',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    marginHorizontal: 12,
    marginVertical: 8,
    borderRadius: 12,
    padding: 4,
  },
  tab: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    borderRadius: 8,
    gap: 8,
  },
  activeTab: {
    backgroundColor: '#FFFFFF',
  },
  tabText: {
    color: colors.text,
    fontSize: 14,
    fontWeight: '500',
  },
  activeTabText: {
    color: colors.primary,
    fontWeight: '600',
  },

  // Carte de rendez-vous
  appointmentList: {
    padding: 12,
  },
  appointmentCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    padding: 16,
    marginBottom: 12,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  appointmentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 12,
  },
  appointmentDate: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 4,
    textTransform: 'capitalize',
  },
  appointmentTime: {
    fontSize: 14,
    color: colors.primary,
  },
  statusBadge: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 16,
  },
  statusText: {
    color: '#FFFFFF',
    fontSize: 12,
    fontWeight: '500',
  },

  // Contenu
  mainContent: {
    marginBottom: 12,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  serviceInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  serviceName: {
    fontSize: 15,
    fontWeight: '500',
    color: colors.text,
    flex: 1,
  },
  finalPrice: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.primary,
  },
  supplements: {
    marginTop: 8,
  },
  supplementText: {
    fontSize: 14,
    color: colors.text,
    marginBottom: 4,
  },
  reward: {
    marginTop: 8,
    padding: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: 6,
  },
  rewardText: {
    fontSize: 14,
    color: colors.text,
    fontStyle: 'italic',
  },

  // Info client
  clientInfo: {
    marginTop: 12,
  },
  clientName: {
    fontSize: 15,
    fontWeight: '500',
    color: colors.text,
    marginBottom: 2,
  },
  clientPhone: {
    fontSize: 14,
    color: colors.text,
    opacity: 0.8,
  },

  // Actions
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 12,
    paddingTop: 12,
    borderTopWidth: 1,
    borderTopColor: '#E0E0E0',
  },
  actionButton: {
    padding: 8,
  },

 // Modal Base Styles
 modalOverlay: {
  flex: 1,
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  justifyContent: 'center',
  alignItems: 'center',
},
overlay: {
  flex: 1,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  justifyContent: 'center',
  alignItems: 'center',
},
webViewContainer: {
  width: '90%',
  height: '70%',
  backgroundColor: 'white',
  borderRadius: 8,
  overflow: 'hidden',
},
webView: {
  flex: 1,
},
modalContent: {
  backgroundColor: '#FFFFFF',
  borderRadius: 12,
  width: '100%',
  maxWidth: 400,
},
modalHeader: {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingVertical: 12,
  paddingHorizontal: 16,
  borderBottomWidth: 1,
  borderBottomColor: '#E0E0E0',
  position: 'relative', // Pour le positionnement absolu du bouton
},
modalTitle: {
  fontSize: 18,
  fontWeight: '600',
  color: colors.text,
},
modalBody: {
  padding: 16,
},

// InfoModal Styles
infoLabel: {
  fontSize: 14,
  color: colors.text,
  opacity: 0.7,
  marginBottom: 8,
},
infoValue: {
  fontSize: 15,
  color: colors.text,
  marginBottom: 2,
},
infoHighlight: {
  fontSize: 15,
  color: colors.primary,
  fontWeight: '500',
  marginBottom: 4,
},
infoSeparator: {
  height: 1,
  backgroundColor: '#E0E0E0',
  marginVertical: 12,
},
priceRow: {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
},
priceValue: {
  fontSize: 16,
  fontWeight: '600',
  color: colors.text,
},
supplementRow: {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 6,
},
supplementName: {
  fontSize: 14,
  color: colors.text,
  flex: 1,
},
supplementPrice: {
  fontSize: 14,
  color: colors.primary,
  fontWeight: '500',
},
totalPrice: {
  fontSize: 18,
  fontWeight: '600',
  color: colors.primary,
  marginTop: 4,
},
clientName: {
  fontSize: 15,
  fontWeight: '500',
  color: colors.text,
},
paymentStatus: {
  marginTop: 8,
  paddingVertical: 12,
  paddingHorizontal: 16,
  alignItems: 'center',
  borderRadius: 8,
},
paymentStatusText: {
  color: '#FFFFFF',
  fontSize: 14,
  fontWeight: '600',
},

// CancelModal Styles
warningIcon: {
  alignItems: 'center',
  marginBottom: 24,
},
warningTitle: {
  fontSize: 16,
  fontWeight: '600',
  color: colors.text,
  textAlign: 'center',
  marginBottom: 16,
},
warningText: {
  fontSize: 14,
  color: colors.text,
  textAlign: 'center',
  marginBottom: 8,
},
warningSubtext: {
  fontSize: 14,
  color: colors.error,
  textAlign: 'center',
  marginBottom: 24,
  fontStyle: 'italic',
},
modalActions: {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 12,
  marginTop: 8,
},
modalButton: {
  flex: 1,
  paddingVertical: 12,
  paddingHorizontal: 16,
  borderRadius: 8,
  alignItems: 'center',
  justifyContent: 'center',
},
cancelButton: {
  backgroundColor: '#F3F4F6',
},
confirmButton: {
  backgroundColor: colors.error,
},
buttonText: {
  fontSize: 14,
  fontWeight: '600',
  color: colors.text,
},
confirmText: {
  color: '#FFFFFF',
},

// ValidationModal Styles
validationText: {
  fontSize: 14,
  color: colors.text,
  textAlign: 'center',
  marginBottom: 24,
},
validationButton: {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingVertical: 12,
  borderRadius: 8,
  marginBottom: 12,
  gap: 8,
},
completedButton: {
  backgroundColor: colors.success,
},
noShowButton: {
  backgroundColor: colors.error,
},
validationButtonText: {
  color: '#FFFFFF',
  fontSize: 14,
  fontWeight: '600',
},

// Media Modal Styles
modalContent: {
  backgroundColor: 'white',
  borderRadius: 15,
  padding: 20,
  width: '90%',
  maxHeight: '80%',
},
modalTitle: {
  fontSize: 18,
  fontWeight: '600',
  color: colors.text,
},
closeButton: {
  padding: 8,
  borderRadius: 20,
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  zIndex: 10,
},
headerCloseButton: {
  position: 'absolute',
  right: 16,
  top: '50%',
  transform: [{ translateY: -16 }], // Moitié de la hauteur du bouton
},
modalBody: {
  flex: 1,
},
mediaScroll: {
  flexGrow: 0,
  height: 120,
  marginBottom: 20,
},
mediaScrollContent: {
  paddingHorizontal: 5,
  paddingVertical: 5,
},
mediaContainer: {
  position: 'relative',
  width: 100,
  height: 100,
  marginRight: 10,
  borderRadius: 10,
  backgroundColor: colors.background,
  borderWidth: 1,
  borderColor: colors.border,
  overflow: 'hidden',
},
mediaPreview: {
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f0f0f0',
  borderRadius: 8,
},
videoOverlay: {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: [{ translateX: -16 }, { translateY: -16 }], // Centre l'icône
  zIndex: 5,
},
mediaTypeIndicator: {
  position: 'absolute',
  top: 5,
  right: 5,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: 4,
  borderRadius: 12,
},
mediaTypeIcon: {
  color: 'white',
},
emptyMedia: {
  alignItems: 'center',
  justifyContent: 'center',
  padding: 20,
  height: 120,
  backgroundColor: colors.background,
  borderRadius: 10,
},
emptyMediaText: {
  marginTop: 10,
  color: colors.textLight,
  fontSize: 16,
  textAlign: 'center',
},
uploadButton: {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.primary,
  padding: 12,
  borderRadius: 8,
  marginTop: 10,
},
uploadButtonText: {
  color: 'white',
  fontSize: 16,
  marginLeft: 8,
  fontWeight: '500',
},
fullScreenImage: {
  width: '90%',
  height: '80%',
  borderRadius: 8,
},
downloadButton: {
  position: 'absolute',
  bottom: 40,
  right: 40,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: 12,
  borderRadius: 30,
},
deleteButton: {
  position: 'absolute',
  top: 5,
  right: 5,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  borderRadius: 12,
  padding: 4,
  zIndex: 10, // Assure que le bouton est toujours visible
},

});