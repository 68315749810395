import { db } from '../firebase';
import { collection, query, getDocs, Timestamp } from 'firebase/firestore';

class BusinessAnalyticsService {
  async getBusinessMetrics() {
    try {
      // Récupération des rendez-vous
      const appointmentsRef = collection(db, 'appointments');
      const snapshot = await getDocs(appointmentsRef);
      const appointments = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        dateTime: doc.data().dateTime instanceof Timestamp ? 
          doc.data().dateTime.toDate() : 
          new Date(doc.data().dateTime)
      }));

      // Récupération du total des clients
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const totalCustomers = usersSnapshot.docs.length;

      // Récupération des rendez-vous complétés et payés
      const validAppointments = appointments.filter(apt => 
        apt.status === 'completed' && 
        apt.payment?.status === 'paid'
      );

      // Calculer les métriques par période
      const now = new Date();
      const periods = {
        jour: this.getPeriodMetrics(appointments, validAppointments, 'day', now),
        semaine: this.getPeriodMetrics(appointments, validAppointments, 'week', now),
        mois: this.getPeriodMetrics(appointments, validAppointments, 'month', now),
        total: this.getPeriodMetrics(appointments, validAppointments, 'all', now)
      };

      // Analyser les services
      const servicesStats = this.analyzeServices(validAppointments);

      // Analyser les clients
      const customerStats = this.analyzeCustomers(validAppointments);

      // Analyser les horaires populaires
      const timeStats = this.analyzePopularTimes(validAppointments);

      return {
        periods,
        services: servicesStats,
        customers: customerStats,
        timeStats,
        totalCustomers
      };
    } catch (error) {
      console.error('Erreur lors de la récupération des métriques:', error);
      throw error;
    }
  }

  getPeriodMetrics(allAppointments, validAppointments, periodType, now) {
    const periodStart = this.getPeriodStart(now, periodType);
    const lastPeriodStart = this.getPeriodStart(periodStart, periodType, -1);

    const currentPeriodAppointments = validAppointments.filter(apt => 
      apt.dateTime >= periodStart && apt.dateTime <= now
    );

    const lastPeriodAppointments = validAppointments.filter(apt => 
      apt.dateTime >= lastPeriodStart && apt.dateTime < periodStart
    );

    const currentRevenue = this.calculateRevenue(currentPeriodAppointments);
    const currentCount = currentPeriodAppointments.length;

    const metrics = {
      appointments: currentCount,
      revenue: currentRevenue,
      upcomingRevenue: this.calculateUpcomingRevenue(allAppointments, now),
      averageTicket: currentCount > 0 ? currentRevenue / currentCount : 0,
      newCustomers: this.countNewCustomers(currentPeriodAppointments),
      comparisonLastPeriod: this.calculateComparison(
        currentPeriodAppointments,
        lastPeriodAppointments
      )
    };

    return metrics;
  }

  calculateUpcomingRevenue(appointments, now) {
    const upcomingAppointments = appointments.filter(apt => 
      new Date(apt.dateTime) > now &&
      apt.status !== 'cancelled' &&
      apt.status !== 'completed'
    );
    
    return upcomingAppointments.reduce((sum, apt) => {
      const price = parseFloat(apt.service?.price || 0);
      return sum + price;
    }, 0);
  }

  getPeriodStart(date, periodType, offset = 0) {
    const result = new Date(date);
    
    switch (periodType) {
      case 'day':
        result.setHours(0, 0, 0, 0);
        result.setDate(result.getDate() + offset);
        break;
      case 'week':
        result.setHours(0, 0, 0, 0);
        result.setDate(result.getDate() - result.getDay() + (offset * 7));
        break;
      case 'month':
        result.setHours(0, 0, 0, 0);
        result.setDate(1);
        result.setMonth(result.getMonth() + offset);
        break;
      case 'all':
        return new Date(0);
    }
    return result;
  }

  calculateRevenue(appointments) {
    return appointments.reduce((sum, apt) => {
      const price = parseFloat(apt.finalPrice || apt.payment?.finalPrice || 0);
      return sum + price;
    }, 0);
  }

  calculateComparison(current, previous) {
    const currentRevenue = this.calculateRevenue(current);
    const previousRevenue = this.calculateRevenue(previous);
    
    return {
      appointments: previous.length > 0 ? 
        ((current.length - previous.length) / previous.length) * 100 : 0,
      revenue: previousRevenue > 0 ? 
        ((currentRevenue - previousRevenue) / previousRevenue) * 100 : 0
    };
  }

  countNewCustomers(appointments) {
    const customerFirstVisits = new Map();
    appointments.forEach(apt => {
      if (!apt.userId) return;
      
      if (!customerFirstVisits.has(apt.userId) || 
          apt.dateTime < customerFirstVisits.get(apt.userId)) {
        customerFirstVisits.set(apt.userId, apt.dateTime);
      }
    });
    return customerFirstVisits.size;
  }

  analyzeServices(appointments) {
    const servicesMap = new Map();

    appointments.forEach(apt => {
      if (!apt.service?.name) return;
      
      const serviceName = apt.service.name;
      if (!servicesMap.has(serviceName)) {
        servicesMap.set(serviceName, {
          name: serviceName,
          count: 0,
          revenue: 0,
          basePrice: apt.service.price || 0
        });
      }

      const stats = servicesMap.get(serviceName);
      stats.count++;
      stats.revenue += parseFloat(apt.finalPrice || apt.payment?.finalPrice || 0);
    });

    return Array.from(servicesMap.values())
      .map(service => ({
        ...service,
        averagePrice: service.count > 0 ? service.revenue / service.count : 0
      }))
      .sort((a, b) => b.revenue - a.revenue);
  }

  analyzePopularTimes(appointments) {
    const hourCounts = new Array(24).fill(0);
    const dayCounts = new Array(7).fill(0);

    appointments.forEach(apt => {
      const date = new Date(apt.dateTime);
      hourCounts[date.getHours()]++;
      dayCounts[date.getDay()]++;
    });

    const mostPopularHour = hourCounts.indexOf(Math.max(...hourCounts));
    const mostPopularDay = dayCounts.indexOf(Math.max(...dayCounts));

    return {
      mostPopularHour,
      mostPopularDay,
      hourlyDistribution: hourCounts,
      dailyDistribution: dayCounts
    };
  }

  analyzeCustomers(appointments) {
    const customersMap = new Map();
  
    appointments.forEach(apt => {
      if (!apt.userId || !apt.userInfo) return;
  
      const customerId = apt.userId;
      if (!customersMap.has(customerId)) {
        customersMap.set(customerId, {
          id: customerId,
          name: `${apt.userInfo.firstName || ''} ${apt.userInfo.lastName || ''}`.trim(),
          email: apt.userInfo.email,
          visits: 0,
          totalSpent: 0,
          lastVisit: null
        });
      }
  
      const stats = customersMap.get(customerId);
      stats.visits++;
      stats.totalSpent += parseFloat(apt.finalPrice || apt.payment?.finalPrice || 0);
      
      if (!stats.lastVisit || apt.dateTime > stats.lastVisit) {
        stats.lastVisit = apt.dateTime;
      }
    });
  
    return Array.from(customersMap.values())
      .map(customer => ({
        ...customer,
        averageSpent: customer.visits > 0 ? customer.totalSpent / customer.visits : 0,
        daysSinceLastVisit: customer.lastVisit ? 
          Math.floor((new Date() - new Date(customer.lastVisit)) / (1000 * 60 * 60 * 24)) : null
      }))
      .sort((a, b) => b.totalSpent - a.totalSpent);
  }
}

export const businessAnalytics = new BusinessAnalyticsService();