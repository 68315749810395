import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, ActivityIndicator, TouchableOpacity, StyleSheet } from 'react-native';
import { colors } from '../../../screenStyles/GlobalStyles';
import { businessAnalytics } from '../../../services/analyticsService';
import { Ionicons } from '@expo/vector-icons';

const PERIODES = {
  JOUR: 'jour',
  SEMAINE: 'semaine',
  MOIS: 'mois',
  TOTAL: 'total'
};

const JOURS = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

const BusinessAnalyticsManager = () => {
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState(PERIODES.MOIS);

  useEffect(() => {
    fetchMetrics();
  }, []);

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const data = await businessAnalytics.getBusinessMetrics();
      setMetrics(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des métriques:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderGrowthIndicator = (value) => {
    if (!value) return null;
    const isPositive = value > 0;
    
    return (
      <View style={[
        styles.growthIndicator,
        isPositive ? styles.growthPositive : styles.growthNegative
      ]}>
        <Ionicons 
          name={isPositive ? 'trending-up' : 'trending-down'} 
          size={16} 
          color={isPositive ? '#22c55e' : '#ef4444'} 
        />
        <Text style={[
          styles.growthText,
          isPositive ? styles.growthTextPositive : styles.growthTextNegative
        ]}>
          {isPositive ? '+' : ''}{value?.toFixed(1) || 0}%
        </Text>
      </View>
    );
  };

  const renderTimeFilter = () => (
    <View style={styles.filterContainer}>
      {Object.values(PERIODES).map((period) => (
        <TouchableOpacity
          key={period}
          style={[
            styles.filterButton,
            selectedPeriod === period && styles.filterButtonActive
          ]}
          onPress={() => setSelectedPeriod(period)}
        >
          <Text style={[
            styles.filterButtonText,
            selectedPeriod === period && styles.filterButtonTextActive
          ]}>
            {period.charAt(0).toUpperCase() + period.slice(1)}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );

  const renderMetricsGrid = () => (
    <View style={styles.metricsGrid}>
      <View style={styles.metricCard}>
        <Text style={styles.metricLabel}>Chiffre d'affaires</Text>
        <Text style={styles.metricValue}>
          {metrics?.periods?.[selectedPeriod]?.revenue?.toFixed(0) || 0}€
        </Text>
        {renderGrowthIndicator(metrics?.periods?.[selectedPeriod]?.comparisonLastPeriod?.revenue)}
      </View>

      <View style={styles.metricCard}>
        <Text style={styles.metricLabel}>Rendez-vous</Text>
        <Text style={styles.metricValue}>
          {metrics?.periods?.[selectedPeriod]?.appointments || 0}
        </Text>
        {renderGrowthIndicator(metrics?.periods?.[selectedPeriod]?.comparisonLastPeriod?.appointments)}
      </View>

      <View style={styles.metricCard}>
        <Text style={styles.metricLabel}>Panier Moyen</Text>
        <Text style={styles.metricValue}>
          {metrics?.periods?.[selectedPeriod]?.averageTicket?.toFixed(0) || 0}€
        </Text>
      </View>

      <View style={styles.metricCard}>
        <Text style={styles.metricLabel}>Montant à venir</Text>
        <Text style={[styles.metricValue, styles.upcomingValue]}>
          {metrics?.periods?.[selectedPeriod]?.upcomingRevenue?.toFixed(0) || 0}€
        </Text>
      </View>

      <View style={styles.metricCard}>
        <Text style={styles.metricLabel}>Nouveaux Clients</Text>
        <Text style={styles.metricValue}>
          {metrics?.periods?.[selectedPeriod]?.newCustomers || 0}
        </Text>
      </View>

      <View style={styles.metricCard}>
        <Text style={styles.metricLabel}>Total Clients</Text>
        <Text style={styles.metricValue}>
          {metrics?.totalCustomers || 0}
        </Text>
      </View>
    </View>
  );

  const renderPopularTimes = () => (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Horaires Populaires</Text>
      <View style={styles.infoCard}>
        <View style={styles.infoRow}>
          <Ionicons name="calendar-outline" size={20} color={colors.primary}/>
          <Text style={styles.infoLabel}>Jour le plus fréquenté:</Text>
          <Text style={styles.infoValue}>
            {JOURS[metrics?.timeStats?.mostPopularDay] || 'N/A'}
          </Text>
        </View>
        <View style={styles.infoRow}>
          <Ionicons name="time-outline" size={20} color={colors.primary} />
          <Text style={styles.infoLabel}>Heure la plus populaire:</Text>
          <Text style={styles.infoValue}>
            {`${metrics?.timeStats?.mostPopularHour || 0}h00`}
          </Text>
        </View>
      </View>
    </View>
  );

  const renderTopServices = () => (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Top Services</Text>
      {(metrics?.services || []).slice(0, 3).map((service, index) => (
        <View key={index} style={styles.serviceCard}>
          <View style={styles.serviceMain}>
            <Text style={styles.serviceName}>{service.name}</Text>
            <Text style={styles.serviceCount}>{service.count} prestations</Text>
          </View>
          <View style={styles.serviceStats}>
            <Text style={styles.serviceRevenue}>{service.revenue.toFixed(0)}€</Text>
            <Text style={styles.serviceAverage}>
              moy. {service.averagePrice.toFixed(0)}€
            </Text>
          </View>
        </View>
      ))}
    </View>
  );

  const renderTopCustomers = () => (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Top Clients</Text>
      {(metrics?.customers || []).slice(0, 5).map((customer, index) => (
        <View key={index} style={styles.customerCard}>
          <View style={styles.customerHeader}>
            <Text style={styles.customerName}>
              {customer.name || 'Client inconnu'}
            </Text>
            <Text style={styles.customerTotal}>{customer.totalSpent.toFixed(0)}€</Text>
          </View>
          <View style={styles.customerMetrics}>
            <View style={styles.metricItem}>
              <Text style={styles.metricItemLabel}>Visites</Text>
              <Text style={styles.metricItemValue}>{customer.visits}</Text>
            </View>
            <View style={styles.metricItem}>
              <Text style={styles.metricItemLabel}>Moy/visite</Text>
              <Text style={styles.metricItemValue}>
                {customer.averageSpent.toFixed(0)}€
              </Text>
            </View>
            <View style={styles.metricItem}>
              <Text style={styles.metricItemLabel}>Dernière visite</Text>
              <Text style={styles.metricItemValue}>
                {customer.daysSinceLastVisit ? 
                  `Il y a ${customer.daysSinceLastVisit}j` : 
                  'N/A'}
              </Text>
            </View>
          </View>
        </View>
      ))}
    </View>
  );

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  return (
    <ScrollView style={styles.container}>
      <TouchableOpacity 
        style={styles.refreshButton}
        onPress={fetchMetrics}
      >
        <Ionicons name="refresh-outline" size={24} style={styles.refreshButtonIcon} />
      </TouchableOpacity>
      <View style={styles.content}>
        {renderTimeFilter()}
        {renderMetricsGrid()}
        {renderPopularTimes()}
        {renderTopServices()}
        {renderTopCustomers()}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  content: {
    padding: 16,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 16,
    gap: 8,
  },
  filterButton: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 8,
    backgroundColor: '#f3f4f6',
  },
  filterButtonActive: {
    backgroundColor: colors.primary,
  },
  filterButtonText: {
    fontSize: 14,
    fontWeight: '500',
    color: '#374151',
  },
  filterButtonTextActive: {
    color: '#ffffff',
  },
  metricsGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 12,
    marginBottom: 24,
  },
  metricCard: {
    flex: 1,
    minWidth: '45%',
    backgroundColor: '#ffffff',
    borderRadius: 12,
    padding: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
  },
  metricLabel: {
    fontSize: 14,
    color: '#6b7280',
    marginBottom: 4,
  },
  metricValue: {
    fontSize: 24,
    fontWeight: '700',
    color: colors.primary,
  },
  upcomingValue: {
    color: '#6366f1',
  },
  section: {
    marginBottom: 24,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 16,
    color: '#111827',
  },
  infoCard: {
    backgroundColor: '#ffffff',
    borderRadius: 12,
    padding: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  infoLabel: {
    fontSize: 14,
    color: '#6b7280',
    marginLeft: 8,
    flex: 1,
  },
  infoValue: {
    fontSize: 14,
    fontWeight: '600',
    color: '#111827',
  },
  serviceCard: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#ffffff',
    borderRadius: 12,
    padding: 16,
    marginBottom: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  serviceMain: {
    flex: 1,
  },
  serviceName: {
    fontSize: 16,
    fontWeight: '600',
    color: '#111827',
    marginBottom: 4,
  },
  serviceCount: {
    fontSize: 14,
    color: '#6b7280',
  },
  serviceStats: {
    alignItems: 'flex-end',
  },
  serviceRevenue: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.primary,
  },
  serviceAverage: {
    fontSize: 13,
    color: '#6b7280',
  },
  customerCard: {
    backgroundColor: '#ffffff',
    borderRadius: 12,
    padding: 16,
    marginBottom: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  customerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  customerName: {
    fontSize: 16,
    fontWeight: '600',
    color: '#111827',
  },
  customerTotal: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.primary,
  },
  customerMetrics: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  metricItem: {
    alignItems: 'center',
  },
  metricItemLabel: {
    fontSize: 12,
    color: '#6b7280',
    marginBottom: 4,
  },
  metricItemValue: {
    fontSize: 14,
    fontWeight: '500',
    color: '#111827',
  },
  growthIndicator: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 12,
    marginTop: 8,
    alignSelf: 'flex-start',
  },
  growthPositive: {
    backgroundColor: 'rgba(34, 197, 94, 0.1)',
  },
  growthNegative: {
    backgroundColor: 'rgba(239, 68, 68, 0.1)',
  },
  growthText: {
    fontSize: 14,
    fontWeight: '500',
    marginLeft: 4,
  },
  growthTextPositive: {
    color: '#22c55e',
  },
  growthTextNegative: {
    color: '#ef4444',
  },
  refreshButton: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    zIndex: 1000,
  },
  refreshButtonIcon: {
    color: '#ffffff',
  },
});

export default BusinessAnalyticsManager;