import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, TouchableOpacity, TextInput, Alert, ActivityIndicator, Pressable, StyleSheet, Modal } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { colors } from '../../../screenStyles/GlobalStyles';
import { db } from '../../../firebase';
import { 
  collection, 
  query, 
  getDocs, 
  doc, 
  runTransaction,
  addDoc,
  serverTimestamp,
  updateDoc,
  onSnapshot
} from 'firebase/firestore';

const LoyaltyManager = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pointsToAdjust, setPointsToAdjust] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateType, setDateType] = useState(null); // 'start' ou 'end'
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    setupRealtimeUsers();
    return () => {
      if (unsubscribeUsers) {
        unsubscribeUsers();
      }
    };
  }, []);

  let unsubscribeUsers;

  const setupRealtimeUsers = async () => {
    try {
      setIsLoading(true);
      const usersRef = collection(db, 'users');
      
      unsubscribeUsers = onSnapshot(usersRef, (snapshot) => {
        const usersData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          points: parseInt(doc.data().loyaltyPoints) || 0,
          isVIP: doc.data().isVIP || false,
          vipStartDate: doc.data().vipStartDate,
          vipEndDate: doc.data().vipEndDate
        })).sort((a, b) => {
          const nameA = `${a.firstName || ''} ${a.lastName || ''}`.trim().toLowerCase();
          const nameB = `${b.firstName || ''} ${b.lastName || ''}`.trim().toLowerCase();
          return nameA.localeCompare(nameB);
        });

        setUsers(usersData);
        setFilteredUsers(searchQuery ? 
          usersData.filter(user => 
            user.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.lastName?.toLowerCase().includes(searchQuery.toLowerCase())
          ) : usersData
        );
        setIsLoading(false);
      }, (error) => {
        console.error('Error in realtime users:', error);
        Alert.alert('Erreur', 'Impossible de charger les utilisateurs en temps réel');
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Error setting up realtime users:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredUsers(users);
      return;
    }

    const lowercaseQuery = searchQuery.toLowerCase();
    const filtered = users.filter(user => 
      user.email?.toLowerCase().includes(lowercaseQuery) ||
      user.firstName?.toLowerCase().includes(lowercaseQuery) ||
      user.lastName?.toLowerCase().includes(lowercaseQuery)
    );
    setFilteredUsers(filtered);
  }, [searchQuery, users]);

  const handleToggleVIP = async (userId, currentVIPStatus) => {
    try {
      setIsLoading(true);
      const userRef = doc(db, 'users', userId);
      
      const now = new Date();
      const endDate = new Date(now);
      endDate.setDate(endDate.getDate() + 30);
      
      await updateDoc(userRef, {
        isVIP: !currentVIPStatus,
        vipStartDate: !currentVIPStatus ? now.toISOString() : null,
        vipEndDate: !currentVIPStatus ? endDate.toISOString() : null,
        lastUpdated: serverTimestamp()
      });

      setUsers(users.map(user => {
        if (user.id === userId) {
          return { 
            ...user, 
            isVIP: !currentVIPStatus,
            vipStartDate: !currentVIPStatus ? now.toISOString() : null,
            vipEndDate: !currentVIPStatus ? endDate.toISOString() : null
          };
        }
        return user;
      }));

      Alert.alert(
        'Succès', 
        `Statut VIP ${!currentVIPStatus ? 'activé' : 'désactivé'} avec succès`
      );
    } catch (error) {
      console.error('Error toggling VIP status:', error);
      Alert.alert('Erreur', 'Impossible de modifier le statut VIP');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdjustPoints = async (userId, pointsChange) => {
    if (!pointsChange || isNaN(parseInt(pointsChange))) {
      Alert.alert('Erreur', 'Veuillez entrer un nombre valide');
      return;
    }

    try {
      setIsLoading(true);
      const pointsToAdd = parseInt(pointsChange);
      const userRef = doc(db, 'users', userId);

      await runTransaction(db, async (transaction) => {
        const userDoc = await transaction.get(userRef);
        if (!userDoc.exists()) {
          throw new Error('Utilisateur non trouvé');
        }

        const currentPoints = parseInt(userDoc.data().loyaltyPoints) || 0;
        const newPoints = Math.max(0, currentPoints + pointsToAdd);

        transaction.update(userRef, {
          loyaltyPoints: newPoints.toString()
        });

        const historyRef = doc(collection(db, 'pointsHistory'));
        const historyData = {
          userId,
          userEmail: userDoc.data().email,
          pointsChange: pointsToAdd,
          type: pointsToAdd > 0 ? 'adjustment_add' : 'adjustment_subtract',
          description: `Ajustement manuel: ${pointsToAdd > 0 ? '+' : ''}${pointsToAdd} points`,
          timestamp: serverTimestamp(),
          finalPoints: newPoints,
          adminAdjustment: true
        };
        
        transaction.set(historyRef, historyData);
      });

      setUsers(users.map(user => {
        if (user.id === userId) {
          const newPoints = Math.max(0, user.points + parseInt(pointsChange));
          return { ...user, points: newPoints };
        }
        return user;
      }));

      setPointsToAdjust('');
      Alert.alert('Succès', 'Points ajustés avec succès');
    } catch (error) {
      console.error('Error adjusting points:', error);
      Alert.alert('Erreur', 'Impossible d\'ajuster les points');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const handleSetCustomDate = async (userId, dateType, date) => {
    try {
      setIsLoading(true);
      const userRef = doc(db, 'users', userId);
      
      const updateData = {
        lastUpdated: serverTimestamp()
      };

      if (dateType === 'start') {
        updateData.vipStartDate = date.toISOString();
      } else {
        updateData.vipEndDate = date.toISOString();
      }
      
      await updateDoc(userRef, updateData);

      setUsers(users.map(user => {
        if (user.id === userId) {
          return { 
            ...user, 
            [dateType === 'start' ? 'vipStartDate' : 'vipEndDate']: date.toISOString()
          };
        }
        return user;
      }));

      Alert.alert('Succès', `Date ${dateType === 'start' ? 'de début' : 'de fin'} VIP mise à jour`);
    } catch (error) {
      console.error('Error updating VIP date:', error);
      Alert.alert('Erreur', 'Impossible de mettre à jour la date');
    } finally {
      setIsLoading(false);
      setShowDatePicker(false);
    }
  };

  const renderDatePicker = () => (
    <Modal
      visible={showDatePicker}
      transparent={true}
      animationType="fade"
      onRequestClose={() => setShowDatePicker(false)}
    >
      <TouchableOpacity 
        style={styles.modalOverlay} 
        activeOpacity={1}
        onPress={() => setShowDatePicker(false)}
      >
        <TouchableOpacity 
          style={styles.datePickerContainer}
          activeOpacity={1}
          onPress={(e) => e.stopPropagation()} // Empêcher la propagation du clic
        >
          <Text style={styles.datePickerTitle}>
            Sélectionner la date {dateType === 'start' ? 'de début' : 'de fin'}
          </Text>
          
          <input
            type="date"
            value={selectedDate.toISOString().split('T')[0]}
            onChange={(e) => setSelectedDate(new Date(e.target.value))}
            style={styles.dateInput}
          />
          
          <View style={styles.datePickerButtons}>
            <TouchableOpacity 
              style={[styles.dateButton, styles.confirmButton]}
              onPress={() => handleSetCustomDate(selectedUserId, dateType, selectedDate)}
            >
              <Text style={styles.dateButtonText}>Confirmer</Text>
            </TouchableOpacity>
            
            <TouchableOpacity 
              style={[styles.dateButton, styles.cancelButton]}
              onPress={() => setShowDatePicker(false)}
            >
              <Text style={styles.dateButtonText}>Annuler</Text>
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );

  const renderUserCard = (user) => {
    const isSelected = selectedUser?.id === user.id;

    const handlePointsInput = (text) => {
      // N'accepte que les chiffres et les caractères - et + au début
      const cleanedText = text.replace(/[^0-9\-+]/g, '');
      // S'assure qu'il n'y a qu'un seul - ou + au début
      const formattedText = cleanedText.replace(/^[+-]+/, match => match[0]);
      setPointsToAdjust(formattedText);
    };

    return (
      <TouchableOpacity
        key={user.id}
        style={[styles.userCard, isSelected && styles.selectedCard]}
        onPress={() => setSelectedUser(isSelected ? null : user)}
      >
        <View style={styles.userHeader}>
          <View style={styles.userInfo}>
            <Text style={styles.userName}>
              {user.firstName} {user.lastName}
              {user.isVIP && <Text style={styles.vipBadge}> VIP</Text>}
            </Text>
            <Text style={styles.userEmail}>{user.email}</Text>
          </View>

          <TouchableOpacity
            style={[styles.vipButton, user.isVIP && styles.vipButtonActive]}
            onPress={() => handleToggleVIP(user.id, user.isVIP)}
          >
            <Ionicons
              name={user.isVIP ? "star" : "star-outline"}
              size={20}
              color={user.isVIP ? "#fff" : colors.primary}
            />
            <Text style={[styles.vipButtonText, user.isVIP && styles.vipButtonTextActive]}>
              {user.isVIP ? 'VIP' : 'Standard'}
            </Text>
          </TouchableOpacity>
        </View>
  
        <View style={styles.pointsInfo}>
          <Text style={styles.currentPoints}>
            Points actuels: {user.points}
          </Text>
        </View>
  
        {user.isVIP && (
          <View style={styles.vipDatesContainer}>
            <View style={styles.vipDateRow}>
              <Text style={styles.vipDatesText}>
                Début VIP : {formatDate(user.vipStartDate)}
              </Text>
              <TouchableOpacity
                style={styles.editDateButton}
                onPress={() => {
                  setDateType('start');
                  setSelectedUserId(user.id);
                  setSelectedDate(new Date(user.vipStartDate || Date.now()));
                  setShowDatePicker(true);
                }}
              >
                <Ionicons name="calendar-outline" size={20} color={colors.primary} />
              </TouchableOpacity>
            </View>
            
            <View style={styles.vipDateRow}>
              <Text style={styles.vipDatesText}>
                Fin VIP : {formatDate(user.vipEndDate)}
              </Text>
              <TouchableOpacity
                style={styles.editDateButton}
                onPress={() => {
                  setDateType('end');
                  setSelectedUserId(user.id);
                  setSelectedDate(new Date(user.vipEndDate || Date.now()));
                  setShowDatePicker(true);
                }}
              >
                <Ionicons name="calendar-outline" size={20} color={colors.primary} />
              </TouchableOpacity>
            </View>

            <TouchableOpacity
              style={styles.editDatesButton}
              onPress={() => {
                const newEndDate = new Date(user.vipEndDate);
                newEndDate.setDate(newEndDate.getDate() + 30);
                handleUpdateVIPDates(user.id, newEndDate.toISOString());
              }}
            >
              <Text style={styles.editDatesButtonText}>Prolonger de 30 jours</Text>
            </TouchableOpacity>
          </View>
        )}
  
        {isSelected && (
          <Pressable 
            style={styles.adjustPointsContainer}
            onPress={(e) => e.stopPropagation()}
          >
            <TextInput
              style={styles.pointsInput}
              value={pointsToAdjust}
              onChangeText={handlePointsInput}
              placeholder="Nombre de points (ex: -50 ou +100)"
              keyboardType="default"
              placeholderTextColor="rgba(0, 0, 0, 0.4)"
            />
            <View style={styles.adjustButtonsContainer}>
              <TouchableOpacity
                style={styles.addButton}
                onPress={() => handleAdjustPoints(user.id, pointsToAdjust)}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ActivityIndicator color="#fff" size="small" />
                ) : (
                  <Text style={styles.adjustButtonText}>Ajuster les points</Text>
                )}
              </TouchableOpacity>
            </View>
          </Pressable>
        )}
      </TouchableOpacity>
    );
  };

  const handleUpdateVIPDates = async (userId, newEndDate) => {
    try {
      setIsLoading(true);
      const userRef = doc(db, 'users', userId);
      
      await updateDoc(userRef, {
        vipEndDate: newEndDate,
        lastUpdated: serverTimestamp()
      });

      setUsers(users.map(user => {
        if (user.id === userId) {
          return { ...user, vipEndDate: newEndDate };
        }
        return user;
      }));

      Alert.alert('Succès', 'Date de fin VIP mise à jour avec succès');
    } catch (error) {
      console.error('Error updating VIP dates:', error);
      Alert.alert('Erreur', 'Impossible de mettre à jour les dates VIP');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ScrollView style={styles.container}>
      <View style={styles.searchContainer}>
        <Ionicons name="search" size={20} color={colors.text} />
        <TextInput
          style={styles.searchInput}
          placeholder="Rechercher un client..."
          value={searchQuery}
          onChangeText={setSearchQuery}
          placeholderTextColor="rgba(0, 0, 0, 0.4)"
        />
      </View>

      <View style={styles.section}>
        {isLoading && !users.length ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color={colors.primary} />
          </View>
        ) : filteredUsers.length > 0 ? (
          filteredUsers.map(renderUserCard)
        ) : (
          <View style={styles.noResultsContainer}>
            <Text style={styles.noResultsText}>Aucun utilisateur trouvé</Text>
          </View>
        )}
      </View>
      {renderDatePicker()}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
  },
  userCard: {
    backgroundColor: '#fff',
    borderRadius: 15,
    padding: 16,
    marginBottom: 12,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
  },
  selectedCard: {
    borderColor: colors.primary,
    borderWidth: 2,
  },
  userHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  userInfo: {
    flex: 1,
    marginRight: 12,
  },
  userName: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 4,
  },
  userEmail: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  vipBadge: {
    color: colors.primary,
    fontWeight: '700',
  },
  vipButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: colors.primary,
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 20,
    gap: 4,
  },
  vipButtonActive: {
    backgroundColor: colors.primary,
    borderColor: colors.primary,
  },
  vipButtonText: {
    color: colors.primary,
    fontSize: 14,
    fontWeight: '500',
  },
  vipButtonTextActive: {
    color: '#fff',
  },
  pointsInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: 12,
    borderRadius: 8,
    marginBottom: 8,
  },
  currentPoints: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.primary,
  },
  adjustPointsContainer: {
    marginTop: 12,
    paddingTop: 12,
    borderTopWidth: 1,
    borderTopColor: 'rgba(0, 0, 0, 0.05)',
  },
  pointsInput: {
    height: 45,
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: 8,
    paddingHorizontal: 12,
    marginBottom: 10,
    fontSize: 15,
    color: colors.text,
  },
  adjustButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  addButton: {
    backgroundColor: colors.primary,
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  adjustButtonText: {
    color: '#fff',
    fontSize: 15,
    fontWeight: '600',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    margin: 12,
    padding: 12,
    borderRadius: 12,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  searchInput: {
    flex: 1,
    marginLeft: 10,
    fontSize: 15,
    color: colors.text,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  noResultsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 12,
    marginHorizontal: 12,
  },
  noResultsText: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
  },
  section: {
    marginBottom: 20,
  },
  vipDatesContainer: {
    marginTop: 8,
    padding: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    borderRadius: 8,
  },
  vipDatesText: {
    fontSize: 14,
    color: colors.text,
    marginBottom: 4,
  },
  editDatesButton: {
    backgroundColor: colors.primary,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 6,
    marginTop: 8,
    alignSelf: 'flex-start',
  },
  editDatesButtonText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: '500',
  },
  vipDateRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  editDateButton: {
    padding: 8,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  datePickerContainer: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 12,
    width: '90%',
    maxWidth: 400,
  },
  datePickerTitle: {
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 20,
    textAlign: 'center',
    color: colors.text,
  },
  dateInput: {
    width: '100%',
    height: 40,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 8,
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  datePickerButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 10,
  },
  dateButton: {
    flex: 1,
    padding: 12,
    borderRadius: 8,
    alignItems: 'center',
  },
  confirmButton: {
    backgroundColor: colors.primary,
  },
  cancelButton: {
    backgroundColor: colors.secondary,
  },
  dateButtonText: {
    color: '#fff',
    fontWeight: '600',
  },
});

export default LoyaltyManager;