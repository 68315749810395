import { StyleSheet, Dimensions } from 'react-native';
import { colors } from './GlobalStyles';

const { width } = Dimensions.get('window');
const CELL_SIZE = Math.floor((width - 48) / 7); // Ajusté pour tenir compte des paddings

export default StyleSheet.create({
  // Calendar Container
  calendarContainer: {
    backgroundColor: '#fff',
    paddingTop: 8,
    paddingBottom: 15,
    borderRadius: 12,
  },

  // Header Calendar
  calendarHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginBottom: 16,
  },

  monthContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },

  monthText: {
    fontSize: 24,
    fontWeight: '600',
    color: colors.text,
  },

  yearText: {
    fontSize: 24,
    color: '#71717A',
    marginLeft: 8,
  },

  navButton: {
    padding: 12,
    borderRadius: 12,
    backgroundColor: '#F3F4F6',
  },

  disabledNavButton: {
    opacity: 0.4,
  },

  // Week View
  weekViewContainer: {
    paddingHorizontal: 16,
  },

  weekDaysHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },

  weekDayCell: {
    width: CELL_SIZE,
    alignItems: 'center',
  },

  weekDayText: {
    fontSize: 15,
    color: '#71717A',
    fontWeight: '500',
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },

  weekRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: CELL_SIZE + 24,
  },

  dateCellWrapper: {
    alignItems: 'center',
    width: CELL_SIZE,
  },

  dateCell: {
    width: CELL_SIZE - 8, // Réduit pour laisser de l'espace
    height: CELL_SIZE - 8,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F8F9FA',
  },

  dateText: {
    fontSize: 17,
    fontWeight: '600',
    color: colors.text,
  },

  selectedDate: {
    backgroundColor: colors.primary,
    shadowColor: colors.primary,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
  },

  selectedDateText: {
    color: 'white',
    fontWeight: '600',
  },

  todayDate: {
    backgroundColor: '#E8F0FE',
    borderWidth: 1.5,
    borderColor: colors.primary,
  },

  todayDateText: {
    color: colors.primary,
    fontWeight: '600',
  },

  pastDate: {
    opacity: 0.5,
  },

  pastDateText: {
    color: colors.text,
  },

  pressedDate: {
    opacity: 0.8,
    transform: [{scale: 0.96}],
  },

  monthLabel: {
    position: 'absolute',
    top: -20,
    fontSize: 12,
    color: colors.primary,
    fontWeight: '600',
  },

  // Time Slots Section
  timeSlotsContainer: {
    marginTop: 16,
    marginHorizontal: 16,
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: 16,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
  },

  loadingContainer: {
    padding: 20,
    alignItems: 'center',
  },

  loadingText: {
    marginTop: 12,
    fontSize: 14,
    color: colors.text,
    textAlign: 'center',
  },
});