import React, { useEffect } from 'react';
import { View, Text, Animated, Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { colors } from '../screenStyles/GlobalStyles';
import styles from '../componentStyles/NotificationStyles';

const Notification = ({
  type = 'info',
  title,
  message,
  duration = 3000,
  onClose
}) => {
  const opacity = new Animated.Value(0);
  const translateY = new Animated.Value(-20);

  useEffect(() => {
    // Animation d'entrée
    Animated.parallel([
      Animated.timing(opacity, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false, // Changé à false pour la compatibilité web
      }),
      Animated.timing(translateY, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false, // Changé à false pour la compatibilité web
      })
    ]).start();

    // Timer pour la fermeture
    if (duration > 0) {
      const timer = setTimeout(() => {
        // Animation de sortie
        Animated.parallel([
          Animated.timing(opacity, {
            toValue: 0,
            duration: 300,
            useNativeDriver: false, // Changé à false pour la compatibilité web
          }),
          Animated.timing(translateY, {
            toValue: -20,
            duration: 300,
            useNativeDriver: false, // Changé à false pour la compatibilité web
          })
        ]).start(() => onClose());
      }, duration - 300);

      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  const getNotificationStyle = () => {
    switch (type) {
      case 'success':
        return {
          backgroundColor: '#DFF2BF',
          borderColor: '#4CAF50',
          icon: 'checkmark-circle',
          iconColor: '#4CAF50'
        };
      case 'error':
        return {
          backgroundColor: '#FFEBEE',
          borderColor: '#FF5252',
          icon: 'alert-circle',
          iconColor: '#FF5252'
        };
      default:
        return {
          backgroundColor: '#E3F2FD',
          borderColor: '#2196F3',
          icon: 'information-circle',
          iconColor: '#2196F3'
        };
    }
  };

  const notificationStyle = getNotificationStyle();

  return (
    <Animated.View
      style={[
        styles.container,
        {
          backgroundColor: notificationStyle.backgroundColor,
          borderColor: notificationStyle.borderColor,
          opacity,
          transform: [{ translateY }]
        }
      ]}
    >
      <View style={styles.content}>
        <Ionicons
          name={notificationStyle.icon}
          size={24}
          color={notificationStyle.iconColor}
          style={styles.icon}
        />
        <View style={styles.textContainer}>
          {title && (
            <Text style={[styles.title, { color: notificationStyle.iconColor }]}>
              {title}
            </Text>
          )}
          <Text style={styles.message}>{message}</Text>
        </View>
        <Pressable 
          onPress={onClose}
          style={({ pressed }) => [
            styles.closeButton,
            pressed && styles.closeButtonPressed
          ]}
        >
          <Ionicons name="close" size={20} color={colors.text} />
        </Pressable>
      </View>
    </Animated.View>
  );
};

export default Notification;