import React from 'react';
import { View, Text, Pressable } from 'react-native';
import { colors } from '../screenStyles/GlobalStyles';
import styles from '../screenStyles/BookingStyles';

const DAYS = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM'];

const WeekView = ({ 
  dates, 
  selectedDate, 
  onSelectDate, 
  isLoading 
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const isToday = (date) => {
    return date.toDateString() === today.toDateString();
  };

  const isPastDate = (date) => {
    return date < today;
  };

  const isMonthChange = (date, index) => {
    if (index === 0) return true;
    return dates[index - 1].getMonth() !== date.getMonth();
  };

  const formatMonth = (date) => {
    return date.toLocaleDateString('fr-FR', { month: 'short' })
      .replace('.', '')
      .toUpperCase();
  };

  return (
    <View style={styles.weekViewContainer}>
      {/* En-tête des jours de la semaine */}
      <View style={styles.weekDaysHeader}>
        {DAYS.map((day, index) => (
          <View key={index} style={styles.weekDayCell}>
            <Text style={styles.weekDayText}>{day}</Text>
          </View>
        ))}
      </View>

      {/* Grille des dates */}
      <View style={styles.weekRow}>
        {dates.map((date, index) => {
          if (!date) return null;

          const isSelected = selectedDate?.toDateString() === date.toDateString();
          const dateIsToday = isToday(date);
          const dateIsPast = isPastDate(date);
          const showMonth = isMonthChange(date, index);

          return (
            <View key={index} style={styles.dateCellWrapper}>
              {showMonth && (
                <Text style={styles.monthLabel}>
                  {formatMonth(date)}
                </Text>
              )}
              <Pressable
                onPress={() => !dateIsPast && !isLoading && onSelectDate(date)}
                disabled={dateIsPast || isLoading}
                style={({ pressed }) => [
                  styles.dateCell,
                  isSelected && styles.selectedDate,
                  dateIsToday && styles.todayDate,
                  dateIsPast && styles.pastDate,
                  pressed && styles.pressedDate
                ]}
              >
                <Text style={[
                  styles.dateText,
                  isSelected && styles.selectedDateText,
                  dateIsToday && styles.todayDateText,
                  dateIsPast && styles.pastDateText,
                ]}>
                  {date.getDate()}
                </Text>
              </Pressable>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default React.memo(WeekView);