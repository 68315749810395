import { StyleSheet, Dimensions, Platform } from 'react-native';
import { colors } from '../GlobalStyles';

const { width } = Dimensions.get('window');

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
    paddingBottom: Platform.OS === 'web' ? 
      'calc(85px + env(safe-area-inset-bottom, 34px))' : 85, // Ajout de la marge
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    backgroundColor: colors.primary,
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    marginBottom: 12,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    elevation: 3,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: '#fff',
    textAlign: 'center',
  },
  tabContainer: {
    flexDirection: 'row',
    backgroundColor: '#f8f9fa', // Changé de '#fff' à une teinte légèrement grise
    borderRadius: 12,
    position: 'fixed',
    top: Platform.OS === 'web' ? 
      'calc(75px + env(safe-area-inset-top, 47px))' : 15, // Augmenté l'espacement
    left: 12,
    right: 12,
    zIndex: 1000,
    minHeight: 56,
    padding: 4,
    overflow: 'hidden',
    shadowColor: "#000", // Ajout d'une ombre
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
  },
  tab: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    zIndex: 1,
  },
  tabContent: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
  },
  tabIcon: {
    marginBottom: 4,
    width: 18,
    height: 18,
  },
  tabText: {
    fontSize: 11,
    fontWeight: '600',
    color: colors.text,
    textAlign: 'center',
    marginTop: 4,
  },
  activeTabText: {
    color: colors.secondary,
    fontWeight: '700',
  },
  slider: {
    position: 'absolute',
    height: '85%',
    backgroundColor: '#ffffff', // Blanc pur pour le slider
    borderRadius: 8,
    zIndex: 0,
    top: '7.5%',
    ...(Platform.OS === 'web' ? {
      transform: 'translateZ(0)',
      backfaceVisibility: 'hidden'
    } : {}),
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15, // Légèrement augmenté pour plus de contraste
    shadowRadius: 3,
    elevation: 2,
  },
  content: {
    flex: 1,
    paddingHorizontal: 12,
    paddingBottom: Platform.OS === 'web' ? 
      'calc(85px + env(safe-area-inset-bottom, 34px))' : 85,
    marginTop: 83, // Augmenté pour compenser le nouvel espacement du tabContainer
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 20,
    fontWeight: '600',
    marginBottom: 15,
    color: colors.text,
    paddingHorizontal: 12,
  },
  appointmentList: {
    flex: 1,
    paddingHorizontal: 4,
  },
  appointmentListGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 2,
  },
  appointmentCard: {
    backgroundColor: '#fff',
    borderRadius: 15,
    padding: 16,
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
  },
  appointmentCardCompact: {
    width: '48.5%',
    aspectRatio: 1,
    backgroundColor: '#fff',
    borderRadius: 6,
    padding: 6,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 1,
    elevation: 1,
  },
  appointmentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 12,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.05)',
  },
  appointmentHeaderCompact: {
    marginBottom: 3,
  },
  appointmentDate: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 4,
  },
  appointmentDateCompact: {
    fontSize: 14,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 1,
  },
  appointmentTime: {
    fontSize: 15,
    color: colors.primary,
    fontWeight: '500',
  },
  appointmentTimeCompact: {
    fontSize: 13,
    color: colors.primary,
    fontWeight: '500',
  },
  statusBadge: {
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 20,
    alignSelf: 'flex-start',
  },
  statusText: {
    color: 'white',
    fontSize: 13,
    fontWeight: '600',
  },
  mainContent: {
    marginBottom: 12,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.05)',
  },
  mainContentCompact: {
    flex: 1,
    justifyContent: 'space-between',
  },
  clientBasicInfo: {
    marginBottom: 10,
  },
  clientBasicInfoCompact: {
    marginBottom: 3,
  },
  clientName: {
    fontSize: 17,
    fontWeight: '600',
    color: colors.text,
  },
  clientNameCompact: {
    fontSize: 15,
    fontWeight: '600',
    color: colors.text,
  },
  mainService: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: 12,
    borderRadius: 8,
  },
  mainServiceCompact: {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: 4,
    borderRadius: 4,
    marginBottom: 3,
  },
  serviceName: {
    fontSize: 15,
    fontWeight: '500',
    color: colors.text,
    flex: 1,
    marginRight: 8,
  },
  serviceNameCompact: {
    fontSize: 13,
    fontWeight: '500',
    color: colors.text,
    marginBottom: 1,
  },
  servicePrice: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.primary,
  },
  servicePriceCompact: {
    fontSize: 14,
    fontWeight: '600',
    color: colors.primary,
  },
  appointmentActions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  appointmentActionsCompact: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 'auto',
    paddingTop: 3,
  },
  actionButton: {
    padding: 3,
    marginRight: 3,
  },
  uploadButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.primary,
    paddingHorizontal: 4,
    paddingVertical: 3,
    borderRadius: 3,
  },
  uploadText: {
    marginLeft: 4,
    color: '#fff',
    fontWeight: '500',
    fontSize: 14,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'flex-end',
  },
  modalContent: {
    backgroundColor: '#fff',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    maxHeight: '80%',
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: colors.text,
  },
  closeButton: {
    padding: 5,
  },
  modalScrollView: {
    padding: 20,
  },
  modalSection: {
    marginBottom: 20,
  },
  modalSectionTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 10,
  },
  modalText: {
    fontSize: 15,
    color: colors.text,
    marginBottom: 5,
  },
  supplementItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5,
  },
  supplementName: {
    fontSize: 14,
    color: colors.text,
  },
  supplementPrice: {
    fontSize: 14,
    color: colors.primary,
    fontWeight: '500',
  },
  chart: {
    marginVertical: 8,
    borderRadius: 16,
    backgroundColor: '#fff',
    padding: 15,
  },
  statsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 12,
  },
  statCard: {
    width: (width - 48) / 2,
    backgroundColor: '#fff',
    borderRadius: 15,
    padding: 15,
    margin: 6,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
  },
  statTitle: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  statValue: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.primary,
    marginTop: 5,
  },
  serviceStatCard: {
    backgroundColor: '#fff',
    borderRadius: 12,
    padding: 15,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  serviceCount: {
    color: colors.primary,
    fontWeight: '600',
  },
  paymentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    padding: 6,
    borderRadius: 8,
    minWidth: 100,
  },
  paymentLabel: {
    marginRight: 8,
    fontSize: 14,
    color: colors.text,
    fontWeight: '500',
  },

  // Styles spécifiques au LoyaltyManager
  userCard: {
    backgroundColor: '#fff',
    borderRadius: 15,
    padding: 16,
    marginBottom: 12,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
  },
  selectedCard: {
    borderColor: colors.primary,
    borderWidth: 2,
  },
  userInfo: {
    marginBottom: 12,
  },
  userName: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 4,
  },
  userEmail: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  pointsInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    padding: 12,
    borderRadius: 8,
    marginBottom: 8,
  },
  currentPoints: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.primary,
  },
  adjustPointsContainer: {
    marginTop: 12,
    paddingTop: 12,
    borderTopWidth: 1,
    borderTopColor: 'rgba(0, 0, 0, 0.05)',
  },
  pointsInput: {
    height: 45,
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: 8,
    paddingHorizontal: 12,
    marginBottom: 10,
    fontSize: 15,
    color: colors.text,
  },
  adjustButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  addButton: {
    backgroundColor: colors.primary,
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  adjustButtonText: {
    color: '#fff',
    fontSize: 15,
    fontWeight: '600',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    margin: 12,
    padding: 12,
    borderRadius: 12,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  searchInput: {
    flex: 1,
    marginLeft: 10,
    fontSize: 15,
    color: colors.text,
},
noResultsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    marginTop: 20,
    backgroundColor: '#fff',
    borderRadius: 12,
    marginHorizontal: 12,
},
noResultsText: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
},
historyIcon: {
    padding: 8,
    borderRadius: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
},
pointsBadge: {
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderRadius: 12,
    backgroundColor: colors.primary + '15',
},
pointsBadgeText: {
    fontSize: 13,
    color: colors.primary,
    fontWeight: '500',
},
refreshButton: {
    position: 'absolute',
    right: 12,
    bottom: 12,
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
},
refreshButtonIcon: {
    color: '#fff',
},
errorContainer: {
    padding: 16,
    margin: 12,
    backgroundColor: '#fee2e2',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#ef4444',
},
errorText: {
    color: '#dc2626',
    fontSize: 14,
},
successContainer: {
    padding: 16,
    margin: 12,
    backgroundColor: '#dcfce7',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#22c55e',
},
successText: {
    color: '#16a34a',
    fontSize: 14,
},
infoContainer: {
    padding: 12,
    marginHorizontal: 12,
    marginTop: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
},
infoIcon: {
    marginRight: 8,
},
infoText: {
    flex: 1,
    fontSize: 14,
    color: colors.text,
    opacity: 0.7,
},
pagination: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 16,
    gap: 8,
},
paginationButton: {
    padding: 8,
    borderRadius: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
},
paginationButtonActive: {
    backgroundColor: colors.primary,
},
paginationText: {
    color: colors.text,
    fontSize: 14,
    fontWeight: '500',
},
paginationTextActive: {
    color: '#fff',
},
adminControls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 12,
    marginTop: 8,
    marginBottom: 16,
},
adminButton: {
    flex: 1,
    marginHorizontal: 4,
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: colors.primary,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
},
adminButtonText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: '500',
    marginLeft: 8,
},
separator: {
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    marginVertical: 12,
},
// Styles pour les graphiques et statistiques
section: {
  marginBottom: 20,
  paddingHorizontal: 12,
},
sectionTitle: {
  fontSize: 18,
  fontWeight: '600',
  color: colors.text,
  marginBottom: 12,
},
statsGrid: {
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 12,
  marginBottom: 12,
},
statCard: {
  flex: 1,
  minWidth: '30%',
  backgroundColor: '#fff',
  borderRadius: 12,
  padding: 16,
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.1,
  shadowRadius: 3,
  elevation: 3,
},
statHeader: {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 4,
},
statValue: {
  fontSize: 20,
  fontWeight: '700',
  color: colors.text,
  marginBottom: 4,
},
statLabel: {
  fontSize: 13,
  color: 'rgba(0, 0, 0, 0.6)',
},
growthIndicator: {
  flexDirection: 'row',
  alignItems: 'center',
  padding: 4,
  borderRadius: 8,
  gap: 4,
},
growthText: {
  fontSize: 13,
  fontWeight: '600',
},
serviceCard: {
  backgroundColor: '#fff',
  borderRadius: 12,
  padding: 16,
  marginBottom: 8,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.1,
  shadowRadius: 2,
  elevation: 2,
},
serviceMain: {
  flex: 1,
},
serviceName: {
  fontSize: 15,
  fontWeight: '600',
  color: colors.text,
  marginBottom: 4,
},
serviceCount: {
  fontSize: 13,
  color: 'rgba(0, 0, 0, 0.6)',
},
serviceMetrics: {
  alignItems: 'flex-end',
},
serviceRevenue: {
  fontSize: 16,
  fontWeight: '600',
  color: colors.primary,
},
serviceAverage: {
  fontSize: 13,
  color: 'rgba(0, 0, 0, 0.6)',
},
supplementCard: {
  backgroundColor: '#fff',
  borderRadius: 12,
  padding: 12,
  marginBottom: 8,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.1,
  shadowRadius: 2,
  elevation: 2,
},
supplementName: {
  fontSize: 15,
  color: colors.text,
},
supplementMetrics: {
  alignItems: 'flex-end',
},
supplementCount: {
  fontSize: 14,
  color: colors.text,
},
supplementRevenue: {
  fontSize: 13,
  color: colors.primary,
  fontWeight: '500',
},
planningGrid: {
  flexDirection: 'row',
  gap: 12,
},
planningCard: {
  flex: 1,
  backgroundColor: '#fff',
  borderRadius: 12,
  padding: 16,
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.1,
  shadowRadius: 2,
  elevation: 2,
},
planningLabel: {
  fontSize: 14,
  color: 'rgba(0, 0, 0, 0.6)',
  marginBottom: 8,
},
planningTime: {
  fontSize: 16,
  fontWeight: '600',
  color: colors.text,
  marginBottom: 4,
},
planningDay: {
  fontSize: 16,
  fontWeight: '600',
  color: colors.text,
  marginBottom: 4,
},
customerCard: {
  backgroundColor: '#fff',
  borderRadius: 12,
  padding: 16,
  marginBottom: 8,
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.1,
  shadowRadius: 2,
  elevation: 2,
},
customerInfo: {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 4,
},
customerMain: {
  flex: 1,
},
customerName: {
  fontSize: 16,
  fontWeight: '600',
  color: colors.text,
},
customerVisits: {
  fontSize: 14,
  color: colors.primary,
  fontWeight: '500',
},
customerMetrics: {
  borderTopWidth: 1,
  borderTopColor: 'rgba(0, 0, 0, 0.05)',
  marginTop: 8,
  paddingTop: 8,
  flexDirection: 'row',
  justifyContent: 'space-between',
},
customerMetricItem: {
  alignItems: 'center',
},
customerMetricValue: {
  fontSize: 15,
  fontWeight: '600',
  color: colors.text,
},
customerMetricLabel: {
  fontSize: 12,
  color: 'rgba(0, 0, 0, 0.6)',
  marginTop: 2,
},serviceCard: {
  backgroundColor: '#fff',
  borderRadius: 12,
  padding: 16,
  marginBottom: 8,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.1,
  shadowRadius: 2,
  elevation: 2,
},
supplementCard: {
  backgroundColor: '#fff',
  borderRadius: 12,
  padding: 16,
  marginBottom: 8,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 1,
  },
  shadowOpacity: 0.1,
  shadowRadius: 2,
  elevation: 2,
},
});