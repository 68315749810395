import { StyleSheet } from 'react-native';
import { colors } from '../screenStyles/GlobalStyles';

export default StyleSheet.create({
  container: {
    position: 'absolute',
    top: 70,
    left: 10,
    right: 10,
    zIndex: 9999,
    borderRadius: 8,
    borderWidth: 1,
    marginHorizontal: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 12,
  },
  icon: {
    marginRight: 10,
  },
  textContainer: {
    flex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 2,
  },
  message: {
    fontSize: 14,
    color: colors.text,
  },
  closeButton: {
    padding: 5,
    marginLeft: 10,
  },
  closeButtonPressed: {
    opacity: 0.7,
  }
});
