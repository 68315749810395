import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  View, 
  Text, 
  TouchableOpacity,
  ScrollView,
  Platform,
  RefreshControl,
  ActivityIndicator,
} from 'react-native';
import { useNavigation, useIsFocused, useRoute } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import globalStyles, { colors } from '../screenStyles/GlobalStyles';
import styles from '../screenStyles/ReservationStyles';
import CustomButton from '../components/CustomButton';
import BookingCalendar from '../components/BookingCalendar';
import AppointmentHistory from '../components/AppointmentHistory';
import Notification from '../components/Notification';
import HeaderWithAnimation from '../components/HeaderWithAnimation';
import { auth, authService } from '../firebase';
import { appointmentService } from '../services/appointmentService';

const ReservationScreen = () => {
  const navigation = useNavigation();
  const isFocused = useIsFocused();
  const route = useRoute();
  const [currentUser, setCurrentUser] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const [userData, setUserData] = useState(null);
  const [appointments, setAppointments] = useState({ upcoming: [], past: [] });
  const [notification, setNotification] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const appointmentHistoryRef = useRef();
  const scrollViewRef = useRef(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setIsInitializing(false);
      if (user) {
        loadUserData(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (route.params?.showAppointmentModal && route.params?.appointmentToShow) {
      const openAppointmentModal = () => {
        const appointment = {
          ...route.params.appointmentToShow,
          dateTime: route.params.appointmentToShow.dateTime instanceof Date 
            ? route.params.appointmentToShow.dateTime 
            : new Date(route.params.appointmentToShow.dateTime)
        };

        // Attendre que le composant soit monté et que le ScrollView soit prêt
        setTimeout(() => {
          if (route.params?.scrollToAppointments && scrollViewRef.current) {
            // Attendre que la mise en page soit complète
            requestAnimationFrame(() => {
              scrollViewRef.current.scrollToEnd({
                animated: true,
                duration: 300
              });
              
              // Attendre que le scroll soit terminé avant d'ouvrir le modal
              setTimeout(() => {
                if (appointmentHistoryRef.current) {
                  appointmentHistoryRef.current.openModal(appointment);
                }
              }, 400);
            });
          } else if (appointmentHistoryRef.current) {
            appointmentHistoryRef.current.openModal(appointment);
          }
        }, 100);
      };

      openAppointmentModal();

      navigation.setParams({
        showAppointmentModal: undefined,
        appointmentToShow: undefined,
        scrollToAppointments: undefined
      });
    }
  }, [route.params]);
  
  const handleOpenAppointmentModal = (appointment) => {
    setSelectedAppointment(appointment);
    setShowInfoModal(true);
  };


  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    try {
      if (currentUser) {
        await Promise.all([
          loadUserData(currentUser.uid),
          loadAppointments(currentUser.uid)
        ]);
      }
    } finally {
      setRefreshing(false);
    }
  }, [currentUser]);

  const showNotification = useCallback((type, title, message) => {
    if (notification) return;
    
    setNotification({ type, title, message });
    setTimeout(() => {
      setNotification(null);
    }, 5000);
  }, [notification]);

  const loadUserData = async (uid) => {
    try {
      const result = await authService.getUserData(uid);
      if (result.success) {
        setUserData(result.data);
      }
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  };

  // const loadAppointments = async (uid) => {
  //   try {
  //     const userAppointments = await appointmentService.getAppointments(uid);
  //     setAppointments(userAppointments);
  //   } catch (error) {
  //     console.error('Error loading appointments:', error);
  //     showNotification('error', 'Erreur', 'Impossible de charger les rendez-vous');
  //   }
  // };

  const checkCurrentUser = async () => {
    try {
      const user = authService.getCurrentUser();
      if (user) {
        setCurrentUser(user);
        await loadUserData(user.uid);
        // Ne plus appeler loadAppointments ici car on utilise les subscriptions
      } else {
        setCurrentUser(null);
        setUserData(null);
        setAppointments({ upcoming: [], past: [] });
      }
    } catch (error) {
      console.error('Error checking user:', error);
      showNotification('error', 'Erreur', 'Une erreur est survenue lors du chargement');
    }
  };

  const handleBookingConfirmed = async (bookingData) => {

    const formatDateForAPI = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}T00:00:00.000Z`;
    };

    try {
      const API_URL = 'https://13-39-76-140.nip.io';
      
      console.log('Booking data received:', JSON.stringify(bookingData, null, 2));
      
      // Validate service data
      if (!bookingData.service || typeof bookingData.service !== 'object' || !bookingData.service.name) {
        console.error('Invalid service data:', bookingData);
        throw new Error('Service information is missing or invalid');
      }
  
      const requestBody = {
        date: formatDateForAPI(new Date(bookingData.date)),
        time: bookingData.time,
        service: {
          name: bookingData.service.name,
          price: bookingData.service.price // Use original service price
        },
        finalPrice: bookingData.finalPrice, // Add finalPrice as separate field
        bookingDetails: {
          supplements: bookingData.supplements || []
        },
        userId: currentUser?.uid,
        userInfo: {
          uid: currentUser?.uid,
          firstName: userData?.firstName || '',
          lastName: userData?.lastName || '',
          email: userData?.email || '',
          phoneNumber: userData?.phoneNumber || '',
        },
        reward: bookingData.reward,
        pointsToEarn: bookingData.pointsToEarn // Add pointsToEarn field
      };
  
      console.log('Sending request with body:', JSON.stringify(requestBody, null, 2));
  
      const response = await fetch(`${API_URL}/api/slots/book`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
  
      const result = await response.json();
      console.log('Received response:', JSON.stringify(result, null, 2));
      
      if (result.success) {
        // Attendre que le changement d'onglet soit complètement terminé
        if (appointmentHistoryRef.current) {
          try {
            await appointmentHistoryRef.current.setActiveTab('upcoming');
            
            // Attendre un court instant pour la stabilisation du DOM
            await new Promise(resolve => setTimeout(resolve, 50));
            
            // Effectuer le scroll
            scrollViewRef.current?.scrollToEnd({
              animated: true,
              duration: 600
            });

            // Attendre que le scroll soit presque terminé avant de montrer la notification
            setTimeout(() => {
              showNotification(
                'success',
                'Réservation confirmée',
                'Le rendez-vous a été enregistré avec succès'
              );
            }, 500);
          } catch (error) {
            console.error('Animation error:', error);
          }
        }
        return true;
      } else {
        throw new Error(result.error || 'Erreur lors de la réservation');
      }
    } catch (error) {
      console.error('Booking error:', error);
      showNotification(
        'error',
        'Erreur',
        error.message || 'Une erreur est survenue lors de la réservation'
      );
      return false;
    }
  };

  useEffect(() => {
    if (isFocused) {
      checkCurrentUser();
    }
  }, [isFocused]);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = appointmentService.subscribeToAppointments(
        currentUser.uid,
        (newAppointments) => {
          setAppointments(newAppointments);
        }
      );
      return () => unsubscribe();
    }
  }, [currentUser]);

  if (isInitializing) {
    return (
      <View style={[globalStyles.container, { justifyContent: 'center', alignItems: 'center' }]}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  if (!currentUser) {
    return (
      <View style={globalStyles.container}>
        <View style={[styles.centerContent, { flex: 1 }]}>
          <Ionicons 
            name="person-circle-outline" 
            size={64} 
            color={colors.primary}
            style={styles.loginIcon}
          />
          <Text style={styles.loginTitle}>Connexion requise</Text>
          <Text style={styles.loginMessage}>
            Connecte-toi pour effectuer une réservation
          </Text>
          <View style={styles.buttonContainer}>
            <CustomButton
              title="Se connecter"
              onPress={() => navigation.navigate('Compte', { mode: 'login' })}
              style={styles.loginButton}
            />
            <CustomButton
              title="S'inscrire"
              color={colors.secondary}
              onPress={() => {
                navigation.reset({
                  index: 0,
                  routes: [{ name: 'Compte', params: { mode: 'register', fromReservation: true } }],
                });
              }}
              style={[styles.loginButton, { marginTop: 10 }]}
              type="secondary"
            />
          </View>
        </View>
      </View>
    );
  }

  return (
  <View style={[globalStyles.container, { flex: 1 }]}>

    {notification && (
      <Notification
        type={notification.type}
        title={notification.title}
        message={notification.message}
        onClose={() => setNotification(null)}
      />
    )}
    {/* View container pour limiter le ScrollView */}
    <View style={{ flex: 1 }}>
      <ScrollView 
        ref={scrollViewRef}
        contentContainerStyle={{ paddingBottom: 90 }}
        style={globalStyles.scrollView}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            colors={[colors.primary]}
          />
        }
      >
        <Text style={styles.welcome}>
          Bonjour {userData?.firstName || 'client'} ! 👋
        </Text>
        
        <Text style={styles.sectionTitle}>
          Réserver un nouveau rendez-vous
        </Text>
        
        <BookingCalendar
          user={{...userData, loyaltyPoints: userData?.loyaltyPoints}}
          onBookingConfirmed={handleBookingConfirmed}
          showNotification={showNotification}
        />

        <Text style={[styles.sectionTitle, {marginTop: 26}, {marginBottom: 0}]}>
          Liste des rendez-vous
        </Text>

        <AppointmentHistory
          ref={appointmentHistoryRef}
          appointments={appointments}
          onAppointmentUpdate={(updatedAppointment) => {
            // Les mises à jour sont gérées automatiquement par la subscription
            console.log('Appointment updated:', updatedAppointment);
          }}
          showNotification={showNotification}
          userData={userData}
        />
        {/* Ajout de l'espaceur en bas */}
        <View style={globalStyles.bottomSpacer} />
      </ScrollView>
    </View>
  </View>
);
};

export default ReservationScreen;
