// components/GoogleSignInButton.js
import React from 'react';
import { Pressable, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import styles from '../screenStyles/AuthStyles';
import { colors } from '../screenStyles/GlobalStyles';

const GoogleSignInButton = ({ onPress, disabled }) => {
  return (
    <Pressable
      style={({ pressed }) => [
        styles.inputContainer,
        {
          backgroundColor: '#ffffff',
          marginBottom: 15,
          opacity: pressed ? 0.9 : 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderColor: disabled ? '#E0E0E0' : '#4285F4'
        },
        disabled && styles.disabledButton
      ]}
      onPress={onPress}
      disabled={disabled}
    >
      <Ionicons name="logo-google" size={24} color="#4285F4" />
      <Text
        style={[
          styles.buttonText,
          {
            color: '#757575',
            marginLeft: 12,
            fontSize: 16,
            fontWeight: '500'
          }
        ]}
      >
        Continuer avec Google
      </Text>
    </Pressable>
  );
};

export default GoogleSignInButton;