import React, { useState, useEffect, useRef } from 'react';
import { 
  View, 
  Text, 
  TextInput, 
  ScrollView, 
  Alert,
  ActivityIndicator,
  Modal,
  Pressable,
  Animated,
  Platform,
  KeyboardAvoidingView
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { authService } from '../firebase';
import CustomButton from '../components/CustomButton';
import { colors } from '../screenStyles/GlobalStyles';
import styles from '../screenStyles/AuthStyles';

// Composant FormField amélioré avec animation
const FormField = ({ 
  label, 
  icon, 
  error, 
  value, 
  onChangeText,
  success,
  required,
  ...props 
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <View style={styles.fieldContainer}>
      <View style={styles.fieldLabelContainer}>
        <Text style={styles.fieldLabel}>{label}</Text>
        {required && <Text style={styles.requiredStar}>*</Text>}
      </View>
      
      <View style={[
        styles.inputContainer,
        isFocused && styles.inputContainerFocused,
        error && styles.inputContainerError,
        success && styles.inputContainerSuccess
      ]}>
        <Ionicons 
          name={icon} 
          size={24} 
          color={error ? colors.secondary : isFocused ? colors.primary : colors.text} 
        />
        <TextInput
          style={styles.input}
          value={value}
          onChangeText={onChangeText}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholderTextColor={colors.placeholder}
          {...props}
        />
        {success && !error && (
          <Ionicons 
            name="checkmark-circle" 
            size={20} 
            color={colors.success}
            style={styles.validationIcon} 
          />
        )}
      </View>
      
      {error && (
        <View style={styles.errorContainer}>
          <Ionicons name="alert-circle" size={16} color={colors.secondary} />
          <Text style={styles.errorText}>{error}</Text>
        </View>
      )}
    </View>
  );
};

const EditProfileModal = ({ currentUser, userData: initialUserData, onClose }) => {
  // États des données
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });

  // État de validation du formulaire
  const [isFormValid, setIsFormValid] = useState(false);

  // État des mots de passe
  const [passwords, setPasswords] = useState({
    current: '',
    new: '',
    confirm: '',
  });

  // États de l'interface
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deletePassword, setDeletePassword] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  // État des erreurs
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    deletePassword: '',
  });

  // Refs pour le scroll et les animations
  const scrollViewRef = useRef(null);
  const shakeAnim = useRef(new Animated.Value(0)).current;
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const slideAnim = useRef(new Animated.Value(0)).current;
  const successMessageAnim = useRef(new Animated.Value(0)).current;

  // Effet d'entrée avec animation de pop-up
  useEffect(() => {
    Animated.parallel([
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }),
      Animated.spring(slideAnim, {
        toValue: 1,
        friction: 7,
        tension: 70,
        useNativeDriver: true,
      }),
    ]).start();
  }, []);

  // Chargement initial des données
  useEffect(() => {
    if (initialUserData) {
      setFormData({
        firstName: initialUserData.firstName || '',
        lastName: initialUserData.lastName || '',
        email: initialUserData.email || currentUser.email || '',
        phoneNumber: initialUserData.phoneNumber || currentUser.phoneNumber || '',
      });
    }
  }, [initialUserData, currentUser]);

  // Vérification des changements
  useEffect(() => {
    if (initialUserData) {
      const hasDataChanges = 
        formData.firstName !== (initialUserData.firstName || '') ||
        formData.lastName !== (initialUserData.lastName || '') ||
        formData.email !== (initialUserData.email || currentUser.email || '') ||
        formData.phoneNumber !== (initialUserData.phoneNumber || currentUser.phoneNumber || '');

      const hasPasswordChanges = passwords.new !== '';

      setHasChanges(hasDataChanges || hasPasswordChanges);
    }
  }, [formData, passwords, initialUserData]);

  // Animation du message de succès
  useEffect(() => {
    if (showSuccess) {
      Animated.sequence([
        Animated.spring(successMessageAnim, {
          toValue: 1,
          friction: 8,
          tension: 50,
          useNativeDriver: true,
        }),
        Animated.timing(successMessageAnim, {
          toValue: 0,
          duration: 300,
          delay: 2000,
          useNativeDriver: true,
        }),
      ]).start(() => setShowSuccess(false));
    }
  }, [showSuccess]);

  // Effet pour la validation du formulaire
  useEffect(() => {
    const champsManquants = !formData.firstName || !formData.lastName || !formData.phoneNumber;
    const champsInvalides = Object.values(errors).some(error => error !== '');
    const isValid = !champsManquants && !champsInvalides;
    setIsFormValid(isValid);
  }, [formData, errors]);

  // Fonction pour scroller vers un champ invalide
  const scrollToFirstError = () => {
    const fieldOrder = ['firstName', 'lastName', 'email', 'phoneNumber'];
    const firstErrorField = fieldOrder.find(field => !formData[field] || errors[field]);
    
    if (firstErrorField && scrollViewRef.current) {
      const yOffset = firstErrorField === 'firstName' ? 0 : 
                     firstErrorField === 'lastName' ? 100 :
                     firstErrorField === 'email' ? 200 : 300;
      
      scrollViewRef.current.scrollTo({ y: yOffset, animated: true });
      
      // Animation de shake pour le champ
      Animated.sequence([
        Animated.timing(shakeAnim, { toValue: 10, duration: 100, useNativeDriver: true }),
        Animated.timing(shakeAnim, { toValue: -10, duration: 100, useNativeDriver: true }),
        Animated.timing(shakeAnim, { toValue: 10, duration: 100, useNativeDriver: true }),
        Animated.timing(shakeAnim, { toValue: 0, duration: 100, useNativeDriver: true })
      ]).start();
    }
  };

  // Validation en temps réel
  const validateField = async (fieldName, value) => {
    switch (fieldName) {
      case 'email':
        if (!value) {
          return 'L\'email est requis';
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return 'Format d\'email invalide';
        }
        if (value !== currentUser.email) {
          const emailCheck = await authService.checkEmailExists(value, currentUser.uid);
          if (emailCheck.exists) {
            return 'Cet email est déjà utilisé';
          }
        }
        return '';

      case 'phoneNumber':
        if (!value) {
          return 'Le numéro de téléphone est requis';
        }
        if (!/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(value)) {
          return 'Format de numéro invalide';
        }
        if (value !== currentUser.phoneNumber && value !== initialUserData?.phoneNumber) {
          const phoneCheck = await authService.checkPhoneExists(value, currentUser.uid);
          if (phoneCheck.exists) {
            return 'Ce numéro est déjà utilisé';
          }
        }
        return '';

      case 'firstName':
      case 'lastName':
        if (!value) {
          return 'Ce champ est requis';
        }
        if (value.length < 2) {
          return 'Minimum 2 caractères';
        }
        return '';

      default:
        return '';
    }
  };

  // Handler pour les changements de champs
  const handleFieldChange = async (fieldName, value) => {
    if (fieldName in formData) {
      setFormData(prev => ({ ...prev, [fieldName]: value }));
      const error = await validateField(fieldName, value);
      setErrors(prev => ({ ...prev, [fieldName]: error }));
    } else if (fieldName in passwords) {
      setPasswords(prev => ({ ...prev, [fieldName]: value }));
      if (fieldName === 'new' && passwords.confirm) {
        setErrors(prev => ({
          ...prev,
          confirmPassword: value !== passwords.confirm ? 'Les mots de passe ne correspondent pas' : ''
        }));
      } else if (fieldName === 'confirm') {
        setErrors(prev => ({
          ...prev,
          confirmPassword: value !== passwords.new ? 'Les mots de passe ne correspondent pas' : ''
        }));
      }
    }
  };

  // Gestion de la fermeture
  const handleClose = () => {
    if (currentUser?.requiresCompletion && !isFormValid) {
      scrollToFirstError();
      Alert.alert(
        'Informations requises',
        'Veuillez compléter vos informations personnelles pour continuer.',
        [{ text: 'Compris' }]
      );
      return;
    }
  
    if (showSuccess) {
      onClose();
      return;
    }
  
    if (hasChanges) {
      Alert.alert(
        'Modifications non sauvegardées',
        'Voulez-vous quitter sans sauvegarder vos modifications ?',
        [
          { text: 'Continuer la modification', style: 'cancel' },
          { text: 'Quitter', style: 'destructive', onPress: () => onClose() }
        ]
      );
    } else {
      onClose();
    }
  };

  // Mise à jour du profil
  const handleUpdateProfile = async () => {
    if (!isFormValid) {
      scrollToFirstError();
      Alert.alert(
        'Données invalides',
        'Veuillez corriger les erreurs avant de sauvegarder.',
        [{ text: 'OK' }]
      );
      return;
    }
  
    if (!hasChanges) {
      Alert.alert('Info', 'Aucune modification à enregistrer');
      return;
    }
  
    setIsLoading(true);
    try {
      const updateData = {
        ...formData,
        newPassword: passwords.new || undefined
      };
  
      const result = await authService.updateUserProfile(
        currentUser.uid,
        updateData,
        passwords.new ? passwords.current : undefined
      );
  
      if (result.success) {
        setSuccessMessage('Les modifications ont été enregistrées avec succès');
        setShowSuccess(true);
        setTimeout(() => {
          onClose();
        }, 1500);
      } else {
        if (result.errorCode === 'auth/wrong-password') {
          setErrors(prev => ({
            ...prev,
            currentPassword: 'Mot de passe incorrect'
          }));
        } else {
          Alert.alert('Erreur', result.error);
        }
      }
    } catch (error) {
      Alert.alert('Erreur', 'Une erreur est survenue lors de la mise à jour');
    } finally {
      setIsLoading(false);
    }
  };

  // Handler de suppression de compte
  const handleDeleteAccount = async () => {
    if (currentUser.authMethod === 'google' && !currentUser.hasEmailAuth) {
      // Pour les comptes Google non liés, supprimer directement
      setIsLoading(true);
      try {
        const result = await authService.deleteAccount(
          currentUser.uid, 
          null,
          true
        );
        if (result.success) {
          onClose('Compte supprimé avec succès');
        } else {
          Alert.alert('Erreur', result.error || 'Une erreur est survenue lors de la suppression');
        }
      } catch (error) {
        console.error('Erreur lors de la suppression:', error);
        Alert.alert('Erreur', 'Une erreur est survenue lors de la suppression du compte');
      } finally {
        setIsLoading(false);
      }
      return;
    }

    // Pour les comptes email ou les comptes Google liés à un compte email
    setShowDeleteConfirm(true);
  };

  const handleConfirmDelete = async (password = null) => {
    setIsLoading(true);
    try {
      const result = await authService.deleteAccount(
        currentUser.uid, 
        password,
        currentUser.authMethod === 'google' && !currentUser.hasEmailAuth
      );

      if (result.success) {
        setShowDeleteConfirm(false);
        onClose('Compte supprimé avec succès');
      } else {
        setErrors(prev => ({
          ...prev,
          deletePassword: result.error
        }));
      }
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
      setErrors(prev => ({
        ...prev,
        deletePassword: 'Une erreur est survenue lors de la suppression du compte'
      }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <View style={styles.modalOverlay}>
      <KeyboardAvoidingView 
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.modalContainer}
      >
        <Animated.View 
          style={[
            styles.modalContent,
            {
              opacity: fadeAnim,
              transform: [
                {
                  translateY: slideAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [50, 0],
                  }),
                },
                {scale: slideAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.95, 1],
                })},
              ],
            },
          ]}
        >
          {/* Header fixe */}
          <View style={styles.modalHeader}>
            <Text style={styles.modalHeaderTitle}>Modifier le profil</Text>
            {(!currentUser?.requiresCompletion || isFormValid) && (
              <Pressable 
                onPress={handleClose}
                style={({ pressed }) => [
                  styles.closeButton,
                  pressed && { opacity: 0.7 }
                ]}
              >
                <Ionicons name="close" size={20} color={colors.text} />
              </Pressable>
            )}
          </View>

          {/* Contenu scrollable */}
          <ScrollView 
            ref={scrollViewRef}
            style={[styles.modalScrollView, { paddingTop: 15, marginBottom: 80 }]}
          >
            {/* Success Message Overlay */}
            {showSuccess && (
              <Animated.View 
                style={[
                  styles.successMessage,
                  {
                    opacity: successMessageAnim,
                    transform: [{
                      translateY: successMessageAnim.interpolate({
                        inputRange: [0, 1],
                        outputRange: [-50, 0],
                      }),
                    }],
                  },
                ]}
              >
                <Ionicons 
                  name="checkmark-circle-outline" 
                  size={24} 
                  color="white"
                  style={styles.successIcon}
                />
                <Text style={styles.successText}>{successMessage}</Text>
              </Animated.View>
            )}

            {/* Personal Information Section */}
            <View style={styles.sectionCard}>
              <View style={styles.sectionHeader}>
                <View style={styles.sectionHeaderContent}>
                  <Ionicons 
                    name="person-circle-outline" 
                    size={24} 
                    color={colors.primary}
                    style={styles.sectionIcon}
                  />
                  <Text style={styles.sectionTitle}>Infos Persos</Text>
                </View>
                {hasChanges && (
                  <View style={styles.changesBadge}>
                    <Text style={styles.changesBadgeText}>Modifié</Text>
                  </View>
                )}
              </View>

              <FormField
                label="Nom"
                icon="person-outline"
                error={errors.lastName}
                value={formData.lastName}
                onChangeText={(text) => handleFieldChange('lastName', text)}
                required
                success={formData.lastName && !errors.lastName}
              />

              <FormField
                label="Prénom"
                icon="person-outline"
                error={errors.firstName}
                value={formData.firstName}
                onChangeText={(text) => handleFieldChange('firstName', text)}
                required
                success={formData.firstName && !errors.firstName}
              />

              <FormField
                label="Email"
                icon="mail-outline"
                error={errors.email}
                value={formData.email}
                onChangeText={(text) => handleFieldChange('email', text)}
                keyboardType="email-address"
                autoCapitalize="none"
                required
                success={formData.email && !errors.email}
              />

              <FormField
                label="Téléphone"
                icon="call-outline"
                error={errors.phoneNumber}
                value={formData.phoneNumber}
                onChangeText={(text) => handleFieldChange('phoneNumber', text)}
                keyboardType="phone-pad"
                required
                success={formData.phoneNumber && !errors.phoneNumber}
              />
            </View>

            {/* Security Section */}
            {currentUser?.authMethod !== 'google' && (
              <View style={styles.sectionCard}>
                <View style={styles.sectionHeader}>
                  <View style={styles.sectionHeaderContent}>
                    <Ionicons 
                      name="lock-closed-outline" 
                      size={24} 
                      color={colors.primary}
                      style={styles.sectionIcon}
                    />
                    <Text style={styles.sectionTitle}>Sécurité</Text>
                  </View>
                </View>

                <View style={styles.passwordSection}>
                  <Text style={styles.passwordNote}>
                    Pour modifier votre mot de passe, remplissez les champs ci-dessous :
                  </Text>

                  <FormField
                    label="Mot de passe actuel"
                    icon="lock-closed-outline"
                    error={errors.currentPassword}
                    value={passwords.current}
                    onChangeText={(text) => handleFieldChange('current', text)}
                    secureTextEntry
                    required={!!passwords.new}
                  />

                  <FormField
                    label="Nouveau mot de passe"
                    icon="key-outline"
                    error={errors.newPassword}
                    value={passwords.new}
                    onChangeText={(text) => handleFieldChange('new', text)}
                    secureTextEntry
                  />

                  {passwords.new !== '' && (
                    <FormField
                      label="Confirmer le nouveau mot de passe"
                      icon="lock-closed-outline"
                      error={errors.confirmPassword}
                      value={passwords.confirm}
                      onChangeText={(text) => handleFieldChange('confirm', text)}
                      secureTextEntry
                      required
                    />
                  )}
                </View>
              </View>
            )}

            {/* Danger Zone */}
            <View style={[styles.sectionCard, styles.dangerZone]}>
              <View style={styles.sectionHeader}>
                <View style={styles.sectionHeaderContent}>
                  <Ionicons 
                    name="warning-outline" 
                    size={24} 
                    color="#E53E3E"
                    style={styles.sectionIcon}
                  />
                  <Text style={[styles.sectionTitle, styles.dangerTitle]}>Zone dangereuse</Text>
                </View>
              </View>
              
              <Text style={styles.warningText}>
                ⚠️ La suppression du compte est irréversible. Toutes vos données seront définitivement 
                effacées.
              </Text>

              <CustomButton 
                title="Supprimer mon compte"
                onPress={() => setShowDeleteConfirm(true)}
                style={styles.deleteButton}
                disabled={isLoading}
                icon="trash-outline"
              />
            </View>
          </ScrollView>

          {/* Footer fixe avec les boutons d'action */}
          <View style={styles.modalFooter}>
            <View style={styles.buttonGroup}>
              <CustomButton 
                title="Annuler"
                onPress={handleClose}
                style={[
                  styles.cancelButton,
                  (!isFormValid && currentUser?.requiresCompletion) && styles.disabledButton
                ]}
                disabled={!isFormValid && currentUser?.requiresCompletion || isLoading}
                icon="close-circle-outline"
              />
              <CustomButton 
                title="Enregistrer"
                onPress={handleUpdateProfile}
                style={[
                  styles.saveButton,
                  (!hasChanges || !isFormValid || isLoading) && styles.disabledButton
                ]}
                disabled={!hasChanges || !isFormValid || isLoading}
                icon="save-outline"
              />
            </View>
          </View>
        </Animated.View>

        {/* Delete Account Confirmation Modal */}
        <Modal
          visible={showDeleteConfirm}
          transparent={true}
          animationType="fade"
          onRequestClose={() => setShowDeleteConfirm(false)}
        >
          <View style={styles.confirmationModal}>
            <View style={styles.confirmationContent}>
              <View style={styles.confirmationHeader}>
                <Text style={styles.confirmationTitle}>⚠️ Supprimer le compte ?</Text>
              </View>
              
              <View style={styles.confirmationBody}>
                <Text style={styles.warningText}>
                  Cette action est irréversible. Toutes vos données seront définitivement supprimées.
                </Text>
                {(currentUser.authMethod !== 'google' || currentUser.hasEmailAuth) && (
                  <>
                    <Text style={styles.confirmationText}>
                      Pour confirmer, saisissez votre mot de passe :
                    </Text>
                    <FormField
                      label="Mot de passe"
                      icon="lock-closed-outline"
                      error={errors.deletePassword}
                      value={deletePassword}
                      onChangeText={(text) => {
                        setDeletePassword(text);
                        setErrors(prev => ({ ...prev, deletePassword: '' }));
                      }}
                      secureTextEntry
                      required
                    />
                  </>
                )}
              </View>

              <View style={styles.buttonGroup}>
                <CustomButton
                  title="Annuler"
                  onPress={() => {
                    setShowDeleteConfirm(false);
                    setDeletePassword('');
                    setErrors(prev => ({ ...prev, deletePassword: '' }));
                  }}
                  style={styles.cancelButton}
                  icon="close-circle-outline"
                />
                <CustomButton
                  title="Supprimer"
                  onPress={() => handleConfirmDelete(deletePassword)}
                  style={styles.deleteButton}
                  icon="trash-outline"
                />
              </View>
            </View>
          </View>
        </Modal>

        {/* Loading Overlay */}
        {isLoading && (
          <View style={styles.loadingOverlay}>
            <ActivityIndicator size="large" color={colors.secondary} />
          </View>
        )}
      </KeyboardAvoidingView>
    </View>
  );
};

export default EditProfileModal;