import React, { useEffect, useRef } from 'react';
import { View, Text, ScrollView, Pressable, ActivityIndicator, Alert, StyleSheet, Animated } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { colors } from '../screenStyles/GlobalStyles';

const TimeSlots = ({ 
  date, 
  slots = [], 
  onSelectSlot, 
  isLoading,
  user,
  onRefresh,
  isRefreshing
}) => {
  if (!date) return null;

  const formatDate = (date) => {
    const formattedDate = date.toLocaleDateString('fr-FR', {
      weekday: 'long',
      day: 'numeric',
      month: 'long'
    });
    return formattedDate.split(' ').map((word, index) => {
      if (index === 3) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    }).join(' ');
  };

  const handleSlotSelection = (slot) => {
    if (!user) {
      Alert.alert(
        'Connexion requise',
        'Veuillez vous connecter pour réserver un créneau.',
        [{ text: 'OK' }]
      );
      return;
    }
    onSelectSlot(slot);
  };

  // Déterminer si on doit afficher le chargement
  const showLoading = isLoading || isRefreshing;

  // Animation
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const loadingAnim = useRef(new Animated.Value(0)).current;
  const containerHeightAnim = useRef(new Animated.Value(150)).current; // Initialiser à la hauteur du conteneur de chargement

  useEffect(() => {
    if (!showLoading && slots.length > 0) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 500,
        useNativeDriver: false,
      }).start();
      Animated.timing(containerHeightAnim, {
        toValue: Math.min(400, 100 + Math.ceil(slots.length / 2) * 60), // Ajuster la hauteur en fonction du nombre de créneaux
        duration: 500,
        useNativeDriver: false,
      }).start();
    }
  }, [showLoading, slots]);

  useEffect(() => {
    if (showLoading) {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start(() => {
        Animated.timing(loadingAnim, {
          toValue: 1,
          duration: 500,
          useNativeDriver: false,
        }).start();
        Animated.timing(containerHeightAnim, {
          toValue: 220, // Hauteur du conteneur de chargement avec un peu de marge
          duration: 500,
          useNativeDriver: false,
        }).start();
      });
    } else {
      Animated.timing(loadingAnim, {
        toValue: 0,
        duration: 500,
        useNativeDriver: false,
      }).start();
    }
  }, [showLoading]);

  return (
    <Animated.View style={[styles.container, { height: containerHeightAnim }]}>
      <View style={styles.header}>
        <View style={styles.titleContainer}>
          <Ionicons
            name="calendar-outline"
            size={20}
            color={colors.text}
            style={styles.titleIcon}
          />
          <Text style={styles.title} numberOfLines={2}>
            Créneaux disponibles : {formatDate(date)}
          </Text>
        </View>
        
        <Pressable
          onPress={onRefresh}
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          style={({ pressed }) => [
            styles.refreshButton,
            pressed && styles.refreshButtonPressed,
            showLoading && styles.refreshButtonDisabled
          ]}
          disabled={showLoading}
        >
          <Ionicons
            name="refresh"
            size={20}
            color={showLoading ? colors.text : colors.primary}
            style={[
              styles.refreshIcon,
              showLoading && { opacity: 0.5 },
              isRefreshing && styles.refreshingIcon
            ]}
          />
        </Pressable>
      </View>

      <ScrollView 
        style={styles.scrollView}
        contentContainerStyle={styles.scrollContent}
        showsVerticalScrollIndicator={false}
      >
        {showLoading ? (
          <Animated.View style={[styles.loadingContainer, { opacity: loadingAnim }]}>
            <ActivityIndicator size="large" color={colors.primary} />
            <Text style={styles.loadingText}>
              {isRefreshing ? 'Rafraîchissement des créneaux...' : 'Chargement des créneaux disponibles...'}
            </Text>
          </Animated.View>
        ) : slots && slots.length > 0 ? (
          <Animated.View style={{ opacity: fadeAnim }}>
            <View style={styles.slotsGrid}>
              {slots.map((slot, index) => (
                <Pressable
                  key={index}
                  style={({ pressed }) => [
                    styles.slot,
                    pressed && styles.slotPressed
                  ]}
                  onPress={() => handleSlotSelection(slot)}
                >
                  <Text style={styles.slotText}>{slot.time}</Text>
                </Pressable>
              ))}
            </View>
          </Animated.View>
        ) : (
          <View style={styles.emptyState}>
            <View style={styles.emptyIconContainer}>
              <Ionicons
                name="calendar-outline"
                size={32}
                color={colors.text}
                style={styles.emptyIcon}
              />
            </View>
            <Text style={styles.emptyText}>
              Aucun créneau disponible pour cette date
            </Text>
          </View>
        )}
      </ScrollView>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#ffffff',
    borderRadius: 12,
    marginHorizontal: 10,
    marginTop: 8,
    marginBottom: 16,
    shadowColor: "#1a237e",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    overflow: 'hidden',
    maxHeight: '60%',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#E5E7EB',
    backgroundColor: '#ffffff',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingRight: 8,
  },
  titleIcon: {
    marginRight: 8,
  },
  title: {
    fontSize: 15,
    fontWeight: '600',
    color: colors.text,
    flex: 1,
  },
  refreshButton: {
    width: 32,
    height: 32,
    borderRadius: 8,
    backgroundColor: '#F3F4F6',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: colors.primary,
  },
  refreshButtonPressed: {
    opacity: 0.7,
    transform: [{ scale: 0.95 }],
  },
  refreshButtonDisabled: {
    borderColor: '#ccc',
  },
  refreshIcon: {
    transform: [{ rotateZ: '0deg' }],
  },
  refreshingIcon: {
    transform: [{ rotateZ: '180deg' }],
  },
  scrollView: {
    flex: 1,
  },
  scrollContent: {
    padding: 12,
  },
  slotsGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 8,
  },
  slot: {
    width: '48%',
    backgroundColor: colors.primary,
    padding: 12,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 4, // Réduire la marge du bas
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  slotPressed: {
    opacity: 0.8,
    transform: [{ scale: 0.98 }],
  },
  slotText: {
    color: 'white',
    fontSize: 15,
    fontWeight: '600',
  },
  emptyState: {
    alignItems: 'center',
    padding: 20,
    backgroundColor: 'rgba(240, 242, 245, 0.5)',
    borderRadius: 8,
  },
  emptyIconContainer: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 12,
  },
  emptyIcon: {
    opacity: 0.6,
  },
  emptyText: {
    fontSize: 15,
    color: colors.text,
    textAlign: 'center',
    fontWeight: '500',
  },
  loadingContainer: {
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 150,
  },
  loadingText: {
    marginTop: 12,
    fontSize: 14,
    color: colors.text,
    textAlign: 'center',
  },
});

export default React.memo(TimeSlots);