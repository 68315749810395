// loyaltyService.js
import { db } from '../firebase';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  collection, 
  addDoc,
  query, 
  where, 
  getDocs,
  orderBy,
  limit,
  runTransaction,
  serverTimestamp
} from 'firebase/firestore';

class LoyaltyService {
  // Configuration des récompenses avec prix
  REWARDS = {
    'half-off': {
      id: 'half-off',
      name: '-50% sur une coupe',
      points: 125,
      discount: 5,
      description: 'Réduction de 50% sur votre prochaine coupe'
    },
    'free-cut': {
      id: 'free-cut',
      name: 'Coupe gratuite',
      points: 250,
      discount: 10,
      description: 'Une coupe homme gratuite'
    },
    'free-full': {
      id: 'free-full',
      name: 'Coupe + Barbe gratuite',
      points: 375,
      discount: 15,
      description: 'Une coupe et une taille de barbe gratuites'
    }
  };

  // Configuration des points par service avec prix
  SERVICES_POINTS = {
    CUT: { points: 50, price: 10, name: 'Coupe Homme' },
    CUT_BEARD: { points: 75, price: 15, name: 'Coupe + Barbe' },
    BEARD: { points: 25, price: 5, name: 'Barbe' },
    CONTOURS: { points: 25, price: 5, name: 'Contours' },
    ENHANCEMENT: { points: 10, price: 2, name: 'Enhancement' }
  };

  async getUserPoints(userId) {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        return parseInt(userDoc.data().loyaltyPoints) || 0;
      }
      return 0;
    } catch (error) {
      console.error('Error getting user points:', error);
      throw error;
    }
  }

  calculatePointsToEarn(finalPrice, hasUsedReward = false) {
    // 5 points par euro sur le prix final
    let basePoints = Math.floor(finalPrice * 5);
    return hasUsedReward ? Math.floor(basePoints / 2) : basePoints;
  }

  checkRewardAvailability(userPoints, rewardId) {
    const reward = this.REWARDS[rewardId];
    if (!reward) {
      return { available: false, error: 'Récompense non reconnue' };
    }

    return {
      available: userPoints >= reward.points,
      pointsNeeded: Math.max(0, reward.points - userPoints),
      reward
    };
  }

  async getPointsHistory(userId) {
    try {
      const historyRef = collection(db, 'pointsHistory');
      const q = query(
        historyRef,
        where('userId', '==', userId),
        orderBy('timestamp', 'desc'),
        limit(50)
      );

      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        bookingDetails: {
          ...doc.data().bookingDetails,
          service: {
            ...doc.data().bookingDetails?.service,
            price: doc.data().bookingDetails?.service?.price
          },
          finalPrice: doc.data().bookingDetails?.finalPrice
        }
      }));
    } catch (error) {
      console.error('Error getting points history:', error);
      throw error;
    }
  }

  async checkVIPStatus(userId) {
    try {
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) return false;
      
      const userData = userDoc.data();
      if (!userData.isVIP) return false;
      
      const now = new Date();
      const endDate = new Date(userData.vipEndDate);
      
      if (now > endDate) {
        // VIP expiré, mettre à jour le statut
        await updateDoc(userRef, {
          isVIP: false,
          vipStartDate: null,
          vipEndDate: null,
          lastUpdated: serverTimestamp()
        });
        return false;
      }
      
      return {
        isVIP: true,
        startDate: userData.vipStartDate,
        endDate: userData.vipEndDate
      };
    } catch (error) {
      console.error('Error checking VIP status:', error);
      return false;
    }
  }
}

export const loyaltyService = new LoyaltyService();