import React from 'react';
import { Pressable, Text, StyleSheet } from 'react-native';
import { colors } from '../screenStyles/GlobalStyles';

const CustomButton = ({ title, onPress, style, disabled, color }) => (
  <Pressable 
    style={({ pressed }) => [
      { backgroundColor: color || colors.primary },
      styles.button,
      style,
      disabled && styles.disabledButton,
      pressed && styles.pressedButton
    ]}
    onPress={onPress}
    disabled={disabled}
  >
    <Text style={[
      styles.buttonText,
      disabled && styles.disabledButtonText
    ]}>
      {title}
    </Text>
  </Pressable>
);

const styles = StyleSheet.create({
  button: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: '600',
  },
  disabledButton: {
    opacity: 0.5,
  },
  disabledButtonText: {
    color: '#CCCCCC',
  },
  pressedButton: {
    opacity: 0.8,
  },
});

export default CustomButton;