import { StyleSheet } from 'react-native';
import { colors } from './GlobalStyles';

export default StyleSheet.create({
  // Styles de base
  scrollView: {
    flex: 1,
  },
  authContainer: {
    paddingHorizontal: 20,
    flex: 1,
    marginVertical: 80,
  },
  formContainer: {
    width: '100%',
    maxWidth: 400,
    alignSelf: 'center',
  },

  // Styles des inputs
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    paddingHorizontal: 15,
    borderWidth: 1,
    borderColor: '#E0E0E0',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    height: 56,
    transition: 'all 0.3s ease',
  },
  inputContainerFocused: {
    borderColor: '#D0D0D0',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.15,
    shadowRadius: 8,
    elevation: 5,
  },
  input: {
    flex: 1,
    height: 50,
    marginLeft: 10,
    fontSize: 16,
    color: colors.text,
    outlineStyle: 'none',
  },
  inputContainerError: {
    borderColor: colors.secondary,
    backgroundColor: 'rgba(255, 99, 71, 0.05)',
    shadowColor: colors.secondary,
    shadowOpacity: 0.15,
  },
  inputContainerSuccess: {
    borderColor: colors.success,
    shadowColor: colors.success,
    shadowOpacity: 0.15,
  },
  validationIcon: {
    marginLeft: 10,
  },

  // Styles des onglets
  tabContainer: {
    flexDirection: 'row',
    marginBottom: 20,
    backgroundColor: '#E8E8E8',
    borderRadius: 10,
    padding: 5,
    position: 'relative',
  },
  tab: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    borderRadius: 8,
    zIndex: 1,
  },
  slider: {
    position: 'absolute',
    top: 5,
    left: 5,
    height: 40,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
    zIndex: 0,
  },
  tabText: {
    marginLeft: 5,
    color: colors.text,
    fontSize: 14,
    fontWeight: '500',
  },
  activeTabText: {
    color: colors.secondary,
    fontWeight: 'bold',
  },

  // Styles des modals
  modalContainer: {
    ...StyleSheet.absoluteFillObject,  // Pour couvrir tout l'écran
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Arrière-plan plus opaque
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    width: '90%',
    maxWidth: 450,
    maxHeight: '85%',
    position: 'relative',
    overflow: 'hidden',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  modalOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: '#000000',
    opacity: 0.7,
  },
  
  // Styles pour l'animation du modal
  modalView: {
    width: '90%',
    maxWidth: 450,
    maxHeight: '85%',
    backgroundColor: 'white',
    borderRadius: 20,
    overflow: 'hidden',
    position: 'relative',
  },
  modalHeader: {
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
    padding: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  modalScrollView: {
    paddingTop: 60,
    paddingBottom: 80,
  },
  modalFooter: {
    backgroundColor: '#FFFFFF',
    borderTopWidth: 1,
    borderTopColor: '#E0E0E0',
    padding: 15,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  modalBackdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalDescription: {
    fontSize: 16,
    color: colors.text,
    marginBottom: 20,
    textAlign: 'center',
  },
  modalButtons: {
    flexDirection: 'column',
    gap: 10,
    marginTop: 20,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.primary,
    marginBottom: 20,
    textAlign: 'center',
  },

  // Styles du séparateur
  divider: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 20,
  },
  dividerLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#E0E0E0',
  },
  dividerText: {
    marginHorizontal: 10,
    color: colors.text,
  },

  // Styles du profil
  profileSection: {
    padding: 15,
  },
  profileHeader: {
    backgroundColor: '#FFFFFF',
    padding: 20,
    borderRadius: 15,
    marginHorizontal: 15,
    marginVertical: 40,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  profileContent: {
    paddingHorizontal: 15,
  },
  avatarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarCircle: {
    width: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: '#F0F9FF',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    borderWidth: 2,
    borderColor: colors.primary,
  },
  welcomeTextContainer: {
    flex: 1,
  },
  welcomeText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.primary,
    marginBottom: 5,
  },
  accountTypeText: {
    fontSize: 14,
    color: colors.text,
    opacity: 0.8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  verifiedIcon: {
    marginLeft: 5,
  },

  // Styles de la carte d'info
  infoCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: 15,
    padding: 20,
    marginBottom: 15,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 2,
  },
  cardHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: colors.primary,
    marginLeft: 10,
  },
  infoGrid: {
    gap: 15,
  },
  infoItem: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#F8F9FA',
    borderRadius: 10,
  },
  infoTextContainer: {
    marginLeft: 15,
    flex: 1,
  },
  infoLabel: {
    fontSize: 12,
    color: colors.text,
    opacity: 0.7,
    marginBottom: 2,
  },
  infoValue: {
    fontSize: 16,
    color: colors.text,
    fontWeight: '500',
  },

  // Styles des boutons
  buttonGroup: {
    flexDirection: 'row',
    gap: 10,
  },
  actionButtonsContainer: {
    gap: 10,
    marginBottom: 100, // Augmenté de 85 à 100
    paddingHorizontal: 15, // Pour assurer une cohérence avec les autres éléments
    paddingBottom: 20, // Ajout d'un padding en bas
  },
  editButton: {
    backgroundColor: colors.primary,
  },
  signOutButton: {
    backgroundColor: '#DC3545',
    marginTop: 20,
  },
  cancelButton: {
    flex: 1,
    backgroundColor: colors.secondary,
  },
  saveButton: {
      flex: 1,
      backgroundColor: colors.primary,
  },
  deleteButton: {
    backgroundColor: '#E53E3E',
    marginTop: 10,
  },
  anonymousButton: {
    paddingVertical: 12,
    paddingHorizontal: 25,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabledButton: {
    opacity: 0.5,
  },

  // Styles des messages et erreurs
  successMessage: {
    backgroundColor: '#48BB78',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    borderRadius: 8,
    margin: 15,
    marginTop: 0,
  },
  successIcon: {
    marginRight: 8,
  },
  successText: {
    color: 'white',
    fontSize: 16,
    fontWeight: '500',
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
    marginLeft: 15,
  },
  errorText: {
    color: colors.secondary,
    fontSize: 12,
    marginTop: -10,
    marginBottom: 15,
    marginLeft: 15,
    fontWeight: '500',
  },
  warningText: {
    fontSize: 14,
    color: '#E53E3E',
    textAlign: 'center',
    marginBottom: 20,
    lineHeight: 20,
  },

  // Styles du FormField
  fieldContainer: {
    marginBottom: 15,
  },
  fieldLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 6,
  },
  fieldLabel: {
    fontSize: 14,
    color: colors.text,
    fontWeight: '500',
  },
  requiredStar: {
    color: colors.secondary,
    marginLeft: 4,
    fontSize: 14,
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,
    marginLeft: 4,
  },
  errorText: {
    color: colors.secondary,
    fontSize: 12,
    marginLeft: 6,
    fontWeight: '500',
  },

  // Styles de chargement
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    marginTop: 10,
    color: colors.text,
    fontSize: 14,
  },
  
// Styles du mot de passe oublié
forgotPasswordLink: {
  alignSelf: 'flex-end',
  marginBottom: 15,
  marginTop: -10,
  padding: 5,
},
forgotPasswordText: {
  color: colors.primary,
  fontSize: 14,
  textDecorationLine: 'underline',
  fontWeight: '500',
},

  // Style du modal de réinitialisation de mot de passe
  forgotPasswordModal: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  forgotPasswordContent: {
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    width: '85%',
    maxWidth: 350,
    padding: 20,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  forgotPasswordHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  forgotPasswordTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: colors.primary,
    flex: 1,
    textAlign: 'center',
    marginLeft: 30, // Pour compenser le bouton close
  },
  forgotPasswordDescription: {
    fontSize: 14,
    color: colors.text,
    textAlign: 'center',
    marginBottom: 20,
    lineHeight: 20,
  },
  forgotPasswordButtons: {
    marginTop: 20,
    gap: 10,
  },

// Styles EditProfile
modalOverlay: {
  ...StyleSheet.absoluteFillObject,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  justifyContent: 'center',
  alignItems: 'center',
},
modalContainer: {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'transparent',
},
modalContent: {
  backgroundColor: '#FFFFFF',
  borderRadius: 20,
  width: '85%',
  maxWidth: 350,
  maxHeight: '75%',
  position: 'relative',
  overflow: 'hidden',
  elevation: 5,
  shadowColor: '#000',
  shadowOffset: { width: 0, height: 2 },
  shadowOpacity: 0.25,
  shadowRadius: 4,
},
modalHeader: {
  backgroundColor: '#FFFFFF',
  borderBottomWidth: 1,
  borderBottomColor: '#E0E0E0',
  padding: 12,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
},
modalHeaderTitle: {
  fontSize: 18,
  fontWeight: '600',
  color: colors.primary,
  flex: 1,
  textAlign: 'center',
},
closeButton: {
  position: 'absolute',
  right: 8,
  top: 8,
  padding: 3,
  borderRadius: 24,
  backgroundColor: '#F3F4F6',
  zIndex: 10,
},
modalScrollView: {
  flexGrow: 1,
},
modalFooter: {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#FFFFFF',
  borderTopWidth: 1,
  borderTopColor: '#E0E0E0',
  padding: 12,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: -2,
  },
  shadowOpacity: 0.1,
  shadowRadius: 3,
  elevation: 5,
},

// Styles existants inchangés
profileModalContainer: {
  flex: 1,
  backgroundColor: '#F8F9FA',
},
sectionCard: {
  backgroundColor: '#FFFFFF',
  borderRadius: 12,
  padding: 15,
  marginHorizontal: 15,
  marginBottom: 15,
},
sectionHeader: {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 15,
},
sectionHeaderContent: {
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
},
sectionIcon: {
  marginRight: 10,
},
sectionTitle: {
  fontSize: 18,
  fontWeight: '600',
  color: colors.primary,
},
headerContent: {
  flexDirection: 'row',
  alignItems: 'center',
},
headerTitle: {
  fontSize: 18,
  fontWeight: '600',
  color: colors.primary,
  marginLeft: 10,
},

// Styles de la zone dangereuse
dangerZone: {
  backgroundColor: '#FFF5F5',
  borderWidth: 1,
  borderColor: '#FED7D7',
},
dangerTitle: {
  color: '#E53E3E',
},

// Styles de confirmation
confirmationModal: {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  flex: 1,
  justifyContent: 'center',
  padding: 20,
},
confirmationContent: {
  backgroundColor: '#FFFFFF',
  borderRadius: 10,
  padding: 20,
  maxWidth: 400,
  alignSelf: 'center',
  width: '100%',
},
confirmationTitle: {
  fontSize: 20,
  fontWeight: 'bold',
  color: '#E53E3E',
  marginBottom: 10,
  textAlign: 'center',
},
confirmationText: {
  fontSize: 16,
  color: colors.text,
  marginBottom: 20,
  textAlign: 'center',
  lineHeight: 24,
},
confirmationHeader: {
  marginBottom: 15,
},
confirmationBody: {
  marginBottom: 20,
},

// Autres styles
changesBadge: {
  backgroundColor: colors.primary,
  paddingHorizontal: 8,
  paddingVertical: 4,
  borderRadius: 12,
},
changesBadgeText: {
  color: '#FFFFFF',
  fontSize: 12,
  fontWeight: '500',
},
passwordSection: {
  backgroundColor: '#F8F9FA',
  padding: 15,
  borderRadius: 10,
  marginTop: 10,
},
passwordNote: {
  fontSize: 14,
  color: colors.text,
  marginBottom: 15,
  fontStyle: 'italic',
},
successContainer: {
  alignItems: 'center',
  padding: 20,
},
instructionText: {
  fontSize: 14,
  color: colors.text,
  textAlign: 'center',
},
sendButton: {
  backgroundColor: colors.primary,
  marginBottom: 10,
},

// Styles des rendez-vous
appointmentCard: {
  borderBottomWidth: 1,
  borderBottomColor: '#E0E0E0',
  paddingVertical: 10,
},
appointmentDate: {
  fontSize: 16,
  fontWeight: '500',
  color: colors.text,
  marginBottom: 5,
},
appointmentService: {
  fontSize: 14,
  color: colors.text,
},
appointmentPrice: {
  fontSize: 14,
  color: colors.secondary,
  marginTop: 5,
},
mediaContainer: {
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 10,
  gap: 8,
},
mediaImage: {
  width: 80,
  height: 80,
  borderRadius: 8,
},

// Style du reCAPTCHA
recaptchaContainer: {
  position: 'fixed',
  bottom: 0,
  right: 0,
  visibility: 'hidden',
  opacity: 0,
  zIndex: 1000,
},

// Nouveaux styles ajoutés
// Styles de base
container: {
  flex: 1,
  backgroundColor: '#F8F9FA',
},
// Ajouter dans AuthStyles.js dans l'objet StyleSheet.create
socialButtonsContainer: {
  marginTop: 10,
  marginBottom: 20,
  width: '100%',
},
socialButton: {
  backgroundColor: '#FFFFFF',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 12,
  borderRadius: 12,
  marginBottom: 10,
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.1,
  shadowRadius: 4,
  elevation: 3,
},
socialButtonText: {
  fontSize: 16,
  fontWeight: '500',
  marginLeft: 12,
  color: '#757575',
},
dividerContainer: {
  flexDirection: 'row',
  alignItems: 'center',
  marginVertical: 20,
},
dividerLine: {
  flex: 1,
  height: 1,
  backgroundColor: '#E0E0E0',
},
dividerText: {
  marginHorizontal: 10,
  color: '#757575',
  fontSize: 14,
},

// Styles VIP
vipInfoContainer: {
  marginTop: 12,
  padding: 12,
  backgroundColor: 'rgba(0, 0, 0, 0.02)',
  borderRadius: 8,
  borderLeftWidth: 3,
  borderLeftColor: colors.primary,
},
vipInfoTitle: {
  fontSize: 16,
  fontWeight: '600',
  color: colors.primary,
  marginBottom: 8,
},
vipInfoContent: {
  gap: 4,
},
vipInfoText: {
  fontSize: 14,
  color: colors.text,
  paddingVertical: 2,
},
vipInfoExpiryText: {
  fontSize: 14,
  color: colors.text,
  fontStyle: 'italic',
  marginTop: 4,
  paddingTop: 8,
  borderTopWidth: 1,
  borderTopColor: 'rgba(0, 0, 0, 0.1)',
},
});