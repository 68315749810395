import React, { useState, useRef, useEffect } from 'react';
import { View, Text, TouchableOpacity, Animated, Dimensions, ScrollView, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import AppointmentManager from './components/AppointmentManager';
import LoyaltyManager from './components/LoyaltyManager';
import AnalyticsManager from './components/AnalyticsManager';
import Notification from '../../components/Notification';
import styles from '../../screenStyles/admin/AdminStyles';
import globalStyles, { colors, isPWA } from '../../screenStyles/GlobalStyles'; // Ajouter isPWA ici

const AdminScreen = () => {
  const [activeTab, setActiveTab] = useState('appointments');
  const [notification, setNotification] = useState(null);
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));
  const slideAnim = useRef(new Animated.Value(0)).current;
  const [tabPositions, setTabPositions] = useState({
    appointments: { x: 0, width: 0 },
    loyalty: { x: 0, width: 0 },
    analytics: { x: 0, width: 0 }
  });

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions(window);
    });
    return () => subscription?.remove();
  }, []);

  const showNotification = (type, title, message) => {
    setNotification({ type, title, message });
    setTimeout(() => setNotification(null), 5000);
  };

  const measureTab = (event, tabName) => {
    const { x, width } = event.nativeEvent.layout;
    setTabPositions(prev => ({
      ...prev,
      [tabName]: { x, width }
    }));
  };

  const getSliderPosition = (tabIndex) => {
    const tabNames = ['appointments', 'loyalty', 'analytics'];
    const currentTab = tabNames[tabIndex];
    const position = tabPositions[currentTab];
    
    if (position.width) {
      const tabWidth = position.width;
      return position.x + (tabWidth * 0.12); // Comme dans FideliteScreen
    }
    
    return tabIndex * (dimensions.width / 3);
  };

  const getSliderWidth = (tabIndex) => {
    const tabNames = ['appointments', 'loyalty', 'analytics'];
    const currentTab = tabNames[tabIndex];
    const position = tabPositions[currentTab];
    
    if (position.width) {
      return position.width * 0.7; // Comme dans FideliteScreen
    }
    
    return dimensions.width / 3;
  };

  const animateSlide = (tab) => {
    const tabIndex = tab === 'appointments' ? 0 : tab === 'loyalty' ? 1 : 2;
    Animated.spring(slideAnim, {
      toValue: tabIndex,
      useNativeDriver: false,
      friction: 20,
      tension: 120
    }).start();
    setActiveTab(tab);
  };

  return (
    <View style={globalStyles.container}>
      <View style={styles.tabContainer}>
        <Animated.View 
          style={[
            styles.slider,
            {
              width: getSliderWidth(
                activeTab === 'appointments' ? 0 : activeTab === 'loyalty' ? 1 : 2
              ),
              transform: [{ 
                translateX: slideAnim.interpolate({
                  inputRange: [0, 1, 2],
                  outputRange: [
                    getSliderPosition(0),
                    getSliderPosition(1),
                    getSliderPosition(2)
                  ]
                })
              }],
            },
          ]}
        />
        
        <TouchableOpacity 
          style={styles.tab}
          onPress={() => animateSlide('appointments')}
          onLayout={(event) => measureTab(event, 'appointments')}
        >
          <View style={styles.tabContent}>
            <Ionicons 
              style={styles.tabIcon}
              name="calendar-outline" 
              size={18}
              color={activeTab === 'appointments' ? colors.secondary : colors.text}
            />
            <Text style={[
              styles.tabText,
              activeTab === 'appointments' && styles.activeTabText
            ]}>
              Rendez-vous
            </Text>
          </View>
        </TouchableOpacity>
  
        <TouchableOpacity 
          style={styles.tab}
          onPress={() => animateSlide('loyalty')}
          onLayout={(event) => measureTab(event, 'loyalty')}
        >
          <View style={styles.tabContent}>
            <Ionicons 
              style={styles.tabIcon}
              name="star" 
              size={18}
              color={activeTab === 'loyalty' ? colors.secondary : colors.text}
            />
            <Text style={[
              styles.tabText,
              activeTab === 'loyalty' && styles.activeTabText
            ]}>
              Fidélité
            </Text>
          </View>
        </TouchableOpacity>
  
        <TouchableOpacity 
          style={styles.tab}
          onPress={() => animateSlide('analytics')}
          onLayout={(event) => measureTab(event, 'analytics')}
        >
          <View style={styles.tabContent}>
            <Ionicons 
              style={styles.tabIcon}
              name="stats-chart" 
              size={18}
              color={activeTab === 'analytics' ? colors.secondary : colors.text}
            />
            <Text style={[
              styles.tabText,
              activeTab === 'analytics' && styles.activeTabText
            ]}>
              Analytics
            </Text>
          </View>
        </TouchableOpacity>
      </View>

      <ScrollView 
        style={[
          globalStyles.scrollView,
          Platform.OS === 'web' && {
            height: `calc(100vh - ${isPWA() ? '60px - env(safe-area-inset-top, 47px)' : '60px'} - 85px - env(safe-area-inset-bottom, 0px))`,
            paddingTop: isPWA() ? 'calc(60px + env(safe-area-inset-top, 47px))' : '60px',
          }
        ]}
      >
        <ScrollView style={styles.content}>
          {activeTab === 'appointments' && (
            <AppointmentManager
              onUpdate={() => {}}
              showNotification={showNotification}
            />
          )}
          
          {activeTab === 'loyalty' && (
            <LoyaltyManager
              onUpdate={() => {}}
              showNotification={showNotification}
            />
          )}
          
          {activeTab === 'analytics' && (
            <AnalyticsManager />
          )}
          
          {/* Ajout de l'espaceur en bas */}
          <View style={globalStyles.bottomSpacer} />
        </ScrollView>
  
        {notification && (
          <Notification
            type={notification.type}
            title={notification.title}
            message={notification.message}
          />
        )}
      </ScrollView>
    </View>
  );
};

export default AdminScreen;