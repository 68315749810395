import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Animated } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import styles, { colors } from '../screenStyles/GlobalStyles';

const TabBar = ({ state, descriptors, navigation, currentUser }) => {
  const [sliderPosition] = useState(new Animated.Value(0));  // Toujours commencer à 0 car Admin est maintenant au début

  useEffect(() => {
    const routeCount = state.routes.length;
    Animated.spring(sliderPosition, {
      toValue: (state.index * (100 / routeCount)),
      useNativeDriver: false,
    }).start();
  }, [state.index, state.routes.length]);

  const getTabIcon = (routeName, isFocused) => {
    const color = isFocused ? colors.secondary : 'rgba(255, 255, 255, 0.5)';
    const size = isFocused ? 26 : 24;

    switch (routeName) {
      case 'Accueil':
        return <Ionicons name={isFocused ? 'home' : "home-outline"} size={size} color={color} />;
      case 'Réservation':
        return <Ionicons name={isFocused ? 'calendar' : "calendar-outline"} size={size} color={color} />;
      case 'Fidélité':
        return <Ionicons name={isFocused ? 'gift' : "gift-outline"} size={size} color={color} />;
      case 'Compte':
        return <Ionicons name={isFocused ? 'person-circle' : "person-circle-outline"} size={size} color={color} />;
      case 'Admin':
        return <Ionicons name={isFocused ? 'settings' : 'settings-outline'} size={size} color={color} />;
      default:
        return null;
    }
  };

  return (
    <View style={styles.tabBarContainer}>
      <Animated.View
        style={[
          styles.slider,
          {
            width: `${100 / state.routes.length}%`,
            left: sliderPosition.interpolate({
              inputRange: [0, 100],
              outputRange: ['0%', '100%'],
            }),
          },
        ]}
      >
        <View style={styles.sliderContent} />
      </Animated.View>

      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label = options.tabBarLabel ?? route.name;
        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };

        return (
          <TouchableOpacity
            key={route.key}
            onPress={onPress}
            style={[
              styles.tab,
              isFocused && styles.activeTab,
            ]}
          >
            <View style={styles.icon}>
              {getTabIcon(route.name, isFocused)}
            </View>
            <Text
              style={[
                styles.tabLabel,
                isFocused && styles.activeTabLabel,
              ]}
            >
              {label}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default TabBar;