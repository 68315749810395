// appointmentService.js
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  addDoc, 
  updateDoc, 
  doc, 
  Timestamp, 
  writeBatch,
  onSnapshot
} from 'firebase/firestore';
import { db } from '../firebase';

class AppointmentService {
  constructor() {
    this.appointmentsRef = collection(db, 'appointments');
    this.listeners = new Map();
  }

  // Nouvelle méthode pour écouter les changements en temps réel
  subscribeToAppointments(userId, callback) {
    const listenerKey = userId || 'tous';
    
    // Si un listener existe déjà pour cet utilisateur, on le détache
    if (this.listeners.has(listenerKey)) {
      this.listeners.get(listenerKey)();
    }

    const baseQuery = userId === "tous" 
      ? this.appointmentsRef
      : query(this.appointmentsRef, where('userId', '==', userId));

    const unsubscribe = onSnapshot(baseQuery, (snapshot) => {
      const appointments = {
        upcoming: [],
        past: []
      };

      const now = new Date();

      snapshot.forEach((doc) => {
        const data = { ...doc.data(), id: doc.id };
        const appointmentDateTime = data.dateTime instanceof Timestamp ? 
          data.dateTime.toDate() : 
          new Date(data.dateTime);

        // La logique de distribution est maintenant basée uniquement sur le statut
        if (data.status === 'completed' || data.status === 'cancelled') {
          appointments.past.push({
            ...data,
            dateTime: appointmentDateTime
          });
        } else {
          appointments.upcoming.push({
            ...data,
            dateTime: appointmentDateTime
          });
        }
      });

      // Tri par date
      appointments.upcoming.sort((a, b) => a.dateTime - b.dateTime);
      appointments.past.sort((a, b) => b.dateTime - a.dateTime);

      callback(appointments);
    });

    this.listeners.set(listenerKey, unsubscribe);
    return unsubscribe;
  }

  // Méthode pour se désabonner
  unsubscribeFromAppointments(userId) {
    const listenerKey = userId || 'tous';
    if (this.listeners.has(listenerKey)) {
      this.listeners.get(listenerKey)();
      this.listeners.delete(listenerKey);
    }
  }

  // Modification de la méthode getAppointments (optionnelle, pour la rétrocompatibilité)
  async getAppointments(userId) {
    try {
      const now = new Date();
      const baseQuery = userId === "tous" 
        ? query(this.appointmentsRef)
        : query(this.appointmentsRef, where('userId', '==', userId));

      const querySnapshot = await getDocs(baseQuery);
      const appointments = {
        upcoming: [],
        past: []
      };

      querySnapshot.forEach((document) => {
        const data = document.data();
        const appointmentDateTime = data.dateTime instanceof Timestamp ? 
          data.dateTime.toDate() : 
          new Date(data.dateTime);

        // Construction de l'objet appointment avec tous les détails
        const appointment = {
          id: document.id,
          docId: document.id,
          dateTime: appointmentDateTime,
          time: data.time,
          service: {
            name: data.service.name,
            price: data.service.price,
            id: data.service.id,
            points: data.service.points,
            status: data.service?.status || data.status
          },
          finalPrice: data.bookingDetails?.finalPrice,
          bookingDetails: {
            supplements: data.bookingDetails?.supplements || [],
            finalPrice: data.bookingDetails?.finalPrice
          },
          status: data.status,
          userId: data.userId,
          userInfo: data.userInfo,
          reward: data.reward,
          pointsToEarn: data.pointsToEarn,
          pointsProcessed: data.pointsProcessed || false,
          payment: data.payment
        };

        // Distribution dans les tableaux
        if (appointmentDateTime > now && appointment.status === 'scheduled') {
          appointments.upcoming.push(appointment);
        } else {
          appointments.past.push(appointment);
        }
      });

      // Tri par date
      appointments.upcoming.sort((a, b) => a.dateTime - b.dateTime);
      appointments.past.sort((a, b) => b.dateTime - a.dateTime);

      return appointments;
    } catch (error) {
      console.error('Error loading appointments:', error);
      throw error;
    }
  }

  async addAppointment(appointmentData) {
    try {
      const docRef = await addDoc(this.appointmentsRef, {
        dateTime: Timestamp.fromDate(new Date(appointmentData.date)),
        time: appointmentData.time,
        service: {
          name: appointmentData.service.name,
          price: appointmentData.service.price,
          id: appointmentData.service.id,
          points: appointmentData.service.points,
          status: 'scheduled'
        },
        bookingDetails: {
          supplements: appointmentData.bookingDetails?.supplements || [],
          finalPrice: appointmentData.finalPrice
        },
        status: 'scheduled',
        createdAt: Timestamp.now(),
        userId: appointmentData.userId,
        userInfo: appointmentData.userInfo,
        reward: appointmentData.reward,
        pointsToEarn: appointmentData.pointsToEarn,
        pointsProcessed: false,
        payment: { status: null }
      });

      return docRef.id;
    } catch (error) {
      console.error('Error adding appointment:', error);
      throw error;
    }
  }

  async cancelAppointment(appointmentId) {
    try {
      const appointmentRef = doc(this.appointmentsRef, appointmentId);
      await updateDoc(appointmentRef, {
        status: 'cancelled',
        'service.status': 'cancelled',
        cancelledAt: Timestamp.now()
      });

      return { success: true };
    } catch (error) {
      console.error('Error cancelling appointment:', error);
      throw error;
    }
  }

  async checkAndUpdateExpiredAppointments(userId) {
    try {
      const q = query(this.appointmentsRef, 
        where('userId', '==', userId),
        where('status', '==', 'scheduled')
      );
      const querySnapshot = await getDocs(q);
      
      const now = new Date();
      const batch = writeBatch(db);
      let hasUpdates = false;

      querySnapshot.forEach((document) => {
        const data = document.data();
        const appointmentDateTime = data.dateTime instanceof Timestamp ? 
          data.dateTime.toDate() : 
          new Date(data.dateTime);

        if (appointmentDateTime < now) {
          hasUpdates = true;
          const docRef = doc(db, 'appointments', document.id);
          batch.update(docRef, {
            status: 'completed',
            'service.status': 'completed',
            completedAt: Timestamp.now()
          });
        }
      });

      if (hasUpdates) {
        await batch.commit();
      }
    } catch (error) {
      console.error('Error updating expired appointments:', error);
      throw error;
    }
  }

  async updatePaymentStatus(appointmentId, paymentStatus) {
    try {
      const appointmentRef = doc(this.appointmentsRef, appointmentId);
      const appointmentDoc = await getDoc(appointmentRef);
      
      if (!appointmentDoc.exists()) {
        throw new Error('Appointment not found');
      }

      const data = appointmentDoc.data();
      await updateDoc(appointmentRef, {
        payment: {
          status: paymentStatus,
          finalPrice: data.bookingDetails.finalPrice,
          updatedAt: Timestamp.now()
        }
      });

      return { success: true };
    } catch (error) {
      console.error('Error updating payment status:', error);
      throw error;
    }
  }
}

export const appointmentService = new AppointmentService();