import { StyleSheet, Dimensions, Platform } from 'react-native';
import { colors } from '../screenStyles/GlobalStyles';

export default StyleSheet.create({
    section: {
      flex: 1,
      paddingBottom: 80,
      marginTop: 30,
    },
    tabContainer: {
    },
    tabContainer: {
      flexDirection: 'row',
      marginBottom: 20,
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
      borderRadius: 12,
      padding: 8,
      position: 'relative',
      marginHorizontal: 20,
      marginTop: -16,
      width: '90%',
      alignSelf: 'center',
    },
    slider: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      margin: 8,
      backgroundColor: 'white',
      borderRadius: 8,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.1,
      shadowRadius: 3,
      elevation: 2,
      zIndex: 0,
    },
    tab: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingVertical: 12,
      borderRadius: 8,
      zIndex: 1,
    },
    activeTab: {
      backgroundColor: 'white',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.1,
      shadowRadius: 3,
      elevation: 2,
    },
    tabIcon: {
      marginRight: 8,
    },
    tabText: {
      fontSize: 15,
      color: colors.text,
      fontWeight: '500',
    },
    activeTabText: {
      color: colors.primary,
      fontWeight: '600',
    },
    appointmentList: {
      backgroundColor: 'white',
      borderRadius: 12,
      paddingHorizontal: 16,
      marginHorizontal: 16,
      paddingTop: 20,
      paddingBottom: 20,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.1,
      shadowRadius: 3,
      elevation: 2,
    },
    appointmentCard: {
      backgroundColor: 'white',
      borderRadius: 12,
      padding: 20,
      marginBottom: 16,
      marginHorizontal: 4,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.1,
      shadowRadius: 3,
      elevation: 2,
    },
    cardContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    appointmentInfo: {
      flex: 1,
      marginRight: 12,
    },
    appointmentDate: {
      fontSize: 16,
      fontWeight: '600',
      color: colors.text,
      marginBottom: 4,
    },
    appointmentTime: {
      fontSize: 14,
      color: colors.text,
      marginBottom: 4,
    },
    statusBadge: {
      alignSelf: 'flex-start',
      paddingHorizontal: 10,
      paddingVertical: 4,
      borderRadius: 12,
    },
    statusText: {
      fontSize: 12,
      fontWeight: '500',
      color: 'white',
    },
    buttonContainer: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: 8,
    },
    topButtons: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    bottomButton: {
      alignSelf: 'flex-start',
      minWidth: 120,
    },
    actionButton: {
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
    },
    cancelButton: {
      backgroundColor: '#ff3b30',
      padding: 10,
      borderRadius: 8,
    },
    cancelButtonText: {
      color: 'white',
      fontSize: 14,
      fontWeight: '600',
    },
    payButton: {
      backgroundColor: '#4CAF50',
      paddingVertical: 8,
      paddingHorizontal: 12,
      borderRadius: 8,
    },
    payButtonText: {
      color: 'white',
      fontSize: 14,
      fontWeight: '600',
    },
    emptyState: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      marginBottom: 60,
      marginTop: 20,  // Ajout d'une marge en haut
      alignSelf: 'center',  // Centre horizontalement
      width: '100%',  // Assure que le conteneur prend toute la largeur
    },
    emptyIcon: {
      marginBottom: 16,
      opacity: 0.5,
      alignSelf: 'center',  // Centre l'icône
    },
    emptyText: {
      fontSize: 16,
      color: colors.text,
      opacity: 0.7,
      textAlign: 'center',
      width: '100%',  // Assure que le texte prend toute la largeur
    },
    modalOverlay: {
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContent: {
      backgroundColor: 'white',
      borderRadius: 16,
      width: '90%',
      maxWidth: 360,
      alignSelf: 'center',
      overflow: 'hidden',
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.15,
      shadowRadius: 12,
      elevation: 8,
    },
    modalHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingTop: 20,
      paddingBottom: 16,
      position: 'relative',
    },
    modalTitleContainer: {
      flex: 1,
      alignItems: 'center',
    },
    modalTitle: {
      fontSize: 22,
      fontWeight: '700',
      color: colors.text,
      letterSpacing: 0.3,
    },
    closeButton: {
      padding: 4,
      position: 'absolute',
      right: 20,
      top: 20,
    },
    separator: {
      height: 1,
      backgroundColor: '#E8E8E8',
      marginBottom: 20,
    },
    cancelModalBody: {
      paddingHorizontal: 20,
      paddingVertical: 20,
      alignItems: 'center',
    },
    warningIconContainer: {
      marginBottom: 16,
    },
    cancelTitle: {
      fontSize: 20,
      fontWeight: '600',
      color: colors.text,
      marginBottom: 12,
      textAlign: 'center',
    },
    cancelMessage: {
      fontSize: 16,
      color: colors.text,
      textAlign: 'center',
      marginBottom: 8,
    },
    cancelDateTime: {
      fontSize: 18,
      fontWeight: '600',
      color: colors.primary,
      textAlign: 'center',
      marginBottom: 16,
      paddingHorizontal: 16,
      paddingVertical: 8,
      backgroundColor: colors.lightGrey,
      borderRadius: 8,
    },
    cancelWarning: {
      fontSize: 14,
      color: colors.warning,
      textAlign: 'center',
      fontStyle: 'italic',
    },
    modalFooterContainer: {
      padding: 20,
    },
    modalFooter: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 12,
    },
    modalButton: {
      flex: 1,
      paddingVertical: 14,
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
    },
    keepButton: {
      backgroundColor: colors.primary,
    },
    confirmCancelButton: {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: colors.secondary,
    },
    buttonText: {
      fontSize: 15,
      fontWeight: '600',
      color: 'white',
    },
    cancelButtonText: {
      color: colors.secondary,
    },
    disabledButton: {
      opacity: 0.6,
    },
    modalBody: {
      paddingHorizontal: 20,
    },
    serviceCard: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: colors.lightGrey,
      borderRadius: 12,
      padding: 16,
      marginBottom: 24,
    },
    serviceIconContainer: {
      width: 44,
      height: 44,
      borderRadius: 22,
      backgroundColor: colors.primary,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 16,
    },
    serviceInfo: {
      flex: 1,
    },
    serviceName: {
      fontSize: 18,
      fontWeight: '600',
      color: colors.text,
      marginBottom: 4,
    },
    servicePrice: {
      fontSize: 20,
      fontWeight: '700',
      color: colors.primary,
    },
    detailsContainer: {
      backgroundColor: '#F8F9FA',
      borderRadius: 12,
      padding: 16,
      marginBottom: 24,
    },
    detailRow: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 12,
      backgroundColor: '#FFF',
      padding: 12,
      borderRadius: 8,
    },
    detailText: {
      fontSize: 16,
      color: colors.text,
      marginLeft: 12,
      flex: 1,
    },
    paymentButton: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.primary,
      marginHorizontal: 20,
      marginBottom: 20,
      height: 56,
      borderRadius: 28,
      shadowColor: colors.primary,
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 0.3,
      shadowRadius: 8,
      elevation: 5,
    },
    paymentButtonText: {
      color: '#FFF',
      fontSize: 18,
      fontWeight: '600',
      marginRight: 8,
    },
    paymentButtonIcon: {
      marginLeft: 4,
    },
    paymentButtonDisabled: {
      opacity: 0.7,
    },
    paidBadge: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
      paddingVertical: 4,
      paddingHorizontal: 8,
    },
    paidText: {
      marginLeft: 4,
      color: colors.success,
      fontSize: 14,
      fontWeight: '600',
    },
    amountCard: {
      backgroundColor: colors.primary,
      padding: 20,
      borderRadius: 12,
      marginBottom: 24,
      alignItems: 'center',
    },
    amountLabel: {
      color: 'white',
      fontSize: 16,
      marginBottom: 8,
    },
    amountValue: {
      color: 'white',
      fontSize: 32,
      fontWeight: 'bold',
    },
    instructionsContainer: {
      backgroundColor: '#F8F9FA',
      borderRadius: 12,
      padding: 16,
      marginBottom: 24,
      gap: 16,
    },
    instructionStep: {
      backgroundColor: 'white',
      padding: 16,
      borderRadius: 12,
      flexDirection: 'row',
      alignItems: 'flex-start',
      borderWidth: 1,
      borderColor: colors.primary,
    },
    instructionIcon: {
      marginRight: 12,
      marginTop: 2,
    },
    instructionText: {
      flex: 1,
      fontSize: 15,
      color: colors.text,
      lineHeight: 22,
    },
    instructionHighlight: {
      color: colors.primary,
      fontWeight: 'bold',
      fontSize: 16,
    },
    paymentIframeContainer: {
      width: '100%',
      height: 400,
      backgroundColor: 'white',
      borderRadius: 12,
      overflow: 'hidden',
      marginTop: 16,
    },
    pendingBadge: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: colors.background,
      padding: 8,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: colors.primary,
      gap: 6,
    },
    pendingText: {
      color: colors.primary,
      fontSize: 12,
      fontWeight: '500',
      marginLeft: 4,
    },
  });