import { StyleSheet } from 'react-native';
import { colors } from '../screenStyles/GlobalStyles';

export default StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  modalContent: {
    backgroundColor: 'white', 
    borderRadius: 10,
    width: '85%',
    maxWidth: 450,
    maxHeight: '85%',
    paddingTop: 16,
    paddingHorizontal: 0,
    paddingBottom: 0,
    overflow: 'hidden'
  },
  modalHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 16,
    position: 'relative',
    marginBottom: 12,
    paddingHorizontal: 16,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: '600',
    color: colors.text,
    textAlign: 'center',
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: '50%',
    transform: [{translateY: -16}],
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  modalBody: {
    flex: 1,
    marginTop: 140,
  },
  modalBodyContent: {
    paddingHorizontal: 16,
    paddingBottom: 80,
  },
  dateTimeContainer: {
    position: 'absolute',
    top: 60,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: '#fff',
    paddingHorizontal: 16,
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
  },
  dateTimeRow: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.primary,
    padding: 12,
    borderRadius: 4,
    marginBottom: 8,
  },
  dateTimeIcon: {
    color: '#FFF',
    marginRight: 10,
  },
  dateTimeLabel: {
    fontSize: 14,
    fontWeight: '500',
    color: 'rgba(255, 255, 255, 0.9)',
    width: 60,
  },
  dateTimeValue: {
    fontSize: 14,
    fontWeight: '600',
    color: '#FFF',
    flex: 1,
  },
  dropdownContainer: {
    marginBottom: 12,
    borderRadius: 4,
    backgroundColor: '#fff',
    overflow: 'hidden',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
    elevation: 5,
    marginHorizontal: 16,
  },
  dropdownHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    height: 56,
    paddingHorizontal: 16,
    borderRadius: 4,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  dropdownHeaderActive: {
    backgroundColor: colors.primary,
    borderRadius: 4,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 3,
  },
  dropdownContent: {
    backgroundColor: '#fff',
    paddingVertical: 8,
    marginTop: 8,
    paddingHorizontal: 16,
    width: '100%',
  },
  dropdownTitleContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  dropdownIcon: {
    marginRight: 0,
  },
  dropdownTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
    textAlign: 'center',
    marginHorizontal: 8,
  },
  dropdownTitleActive: {
    color: '#fff',
    textShadowColor: 'rgba(0, 0, 0, 0.2)',
    textShadowOffset: {width: 0, height: 1},
    textShadowRadius: 3,
  },
  serviceOptionContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  serviceOption: {
    minHeight: 80,
    padding: 16,
    marginVertical: 6,
    marginHorizontal: 16,
    backgroundColor: '#fff',
    borderRadius: 4,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
    borderWidth: 1,
    borderColor: 'transparent',
  },
  serviceOptionSelected: {
    backgroundColor: 'rgba(0, 122, 255, 0.05)',
    borderColor: colors.primary,
  },
  serviceInfo: {
    flex: 1,
  },
  serviceNameContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  serviceName: {
    fontSize: 15,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 4,
    letterSpacing: 0.3,
    textAlign: 'center',
  },
  servicePoints: {
    fontSize: 13,
    fontWeight: '600',
    color: '#2ECC71',
    marginTop: 3,
  },
  servicePrice: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
    marginLeft: 12,
  },
  checkbox: {
    width: 20,
    height: 20,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: colors.primary,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  checkboxChecked: {
    backgroundColor: colors.primary,
  },
  supplementOptionContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  supplementOption: {
    minHeight: 80,
    padding: 16,
    marginVertical: 6,
    marginHorizontal: 16,
    backgroundColor: '#fff',
    borderRadius: 4,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
    borderWidth: 1,
    borderColor: 'transparent',
  },
  supplementOptionSelected: {
    backgroundColor: 'rgba(0, 122, 255, 0.05)',
    borderColor: colors.primary,
  },
  supplementInfo: {
    flex: 1,
  },
  supplementNameContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  supplementName: {
    fontSize: 15,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 4,
    letterSpacing: 0.3,
    textAlign: 'center',
  },
  supplementPoints: {
    fontSize: 13,
    fontWeight: '600',
    color: '#2ECC71',
    marginTop: 3,
  },
  supplementPrice: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
    marginLeft: 12,
  },
  supplementOptionDisabled: {
    opacity: 0.5,
  },
  supplementNameDisabled: {
    color: '#999',
  },
  supplementPointsDisabled: {
    color: '#999',
  },
  supplementPriceDisabled: {
    color: '#999',
  },
  noSupplementContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noSupplementText: {
    fontSize: 15,
    fontWeight: '500',
    color: colors.text,
    textAlign: 'center',
  },
  noSupplementTextSelected: {
    fontWeight: '600',
    color: '#FF4757',
  },
  noSupplementSelected: {
    borderWidth: 2,
    borderColor: '#FF4757',
    backgroundColor: 'rgba(255, 71, 87, 0.05)',
  },
  rewardOption: {
    minHeight: 80,
    padding: 16,
    marginVertical: 6,
    marginHorizontal: 16,
    backgroundColor: '#fff',
    borderRadius: 4,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
    borderWidth: 1,
    borderColor: 'transparent',
  },
  rewardOptionSelected: {
    backgroundColor: 'rgba(0, 122, 255, 0.05)',
    borderColor: colors.primary,
  },
  rewardInfo: {
    flex: 1,
  },
  rewardPriceContainer: {
    marginLeft: 12,
  },
  rewardName: {
    fontSize: 15,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 3,
    letterSpacing: 0.3,
  },
  rewardNameSelected: {
    color: '#1a73e8',
    fontWeight: '600',
    fontSize: 15,
    letterSpacing: 0.3,
  },
  rewardPoints: {
    fontSize: 12,
    fontWeight: '500',
    color: '#FF4757',
    marginTop: 2,
  },
  rewardPointsSelected: {
    color: '#FF4757',
    fontWeight: '600',
  },
  noRewardContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noRewardText: {
    fontSize: 15,
    fontWeight: '600',
    color: colors.text,
    textAlign: 'center',
    letterSpacing: 0.3,
  },
  noRewardTextSelected: {
    color: '#1a73e8',
    fontWeight: '600',
  },
  savePointsText: {
    fontSize: 12,
    fontWeight: '500',
    color: '#2ECC71',
    textAlign: 'center',
    marginTop: 3,
  },
  summaryContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 16,
    marginBottom: 16,
  },
  summaryTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 12,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    paddingBottom: 8,
  },
  summaryTitle: {
    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
  },
  summaryCard: {
    backgroundColor: '#f5f5f5',
    borderRadius: 8,
    padding: 16,
    marginTop: 4,
  },
  summaryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  summaryLabel: {
    flex: 1,
    fontSize: 16,
  },
  summaryValue: {
    fontSize: 16,
    fontWeight: '500',
  },
  discountLabel: {
    color: colors.primary,
  },
  discountValue: {
    color: colors.error,
  },
  totalRow: {
    marginTop: 16,
    paddingTop: 16,
    borderTopWidth: 1,
    borderTopColor: '#dee2e6',
  },
  totalLabel: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  totalValue: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.primary,
  },
  pointsFinal: {
    marginTop: 20,
    backgroundColor: '#F8F9FA',
    borderRadius: 8,
    padding: 15,
  },
  pointsDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  pointsLabel: {
    fontSize: 14,
    color: '#666',
  },
  pointsValue: {
    fontSize: 14,
    fontWeight: '600',
    color: colors.text,
  },
  pointsPositive: {
    color: '#2ECC71',
  },
  pointsNegative: {
    color: '#E74C3C',
  },
  pointsTotal: {
    marginTop: 12,
    paddingTop: 12,
    borderTopWidth: 1,
    borderTopColor: '#E0E0E0',
  },
  pointsTotalLabel: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
  },
  pointsTotalValue: {
    fontSize: 16,
    fontWeight: '700',
    color: colors.primary,
  },
  pointsEarned: {
    fontSize: 13,
    color: '#2ECC71',
    fontWeight: '600',
    marginBottom: 2,
    textAlign: 'right',
  },
  pointsBalance: {
    fontSize: 12,
    color: colors.text,
    opacity: 0.8,
    textAlign: 'right',
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: '#E9ECEF',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    padding: 10,
    borderRadius: 4,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cancelButton: {
    backgroundColor: '#ff6347',
    marginRight: 8,
  },
  confirmButton: {
    backgroundColor: colors.primary,
  },
  cancelButtonText: {
    fontSize: 14,
    fontWeight: '600',
    color: '#FFFFFF',
  },
  confirmButtonText: {
    fontSize: 14,
    fontWeight: '600',
    color: '#FFFFFF',
  },
  dropdownItemSelected: {
    backgroundColor: '#E8F0FE',
  },
  dropdownItemTextSelected: {
    color: '#1a73e8',
    fontWeight: '800',
    fontSize: 15,
  },
  decorativeIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f0f0f0',
  },
  decorativeIconSelected: {
    backgroundColor: 'rgba(26, 115, 232, 0.1)',
  },
  greenTextSelected: {
    fontSize: 16,
    fontWeight: '600',
    color: '#2ECC71',
  },
  summaryContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 16,
    marginBottom: 16,
  },
  summaryTitle: {
    fontSize: 18,
    fontWeight: '500',
    marginBottom: 8,
  },
  summaryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  pricePointsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 100,
    justifyContent: 'flex-end',
  },
  summaryText: {
    fontSize: 16,
    flex: 1,
  },
  summaryPrice: {
    fontSize: 16,
    fontWeight: '500',
    marginRight: 8,
  },
  pointsText: {
    fontSize: 14,
    minWidth: 70,
    textAlign: 'right',
  },
  pointsPositive: {
    color: '#2ECC71',
  },
  pointsNegative: {
    color: '#e74c3c',
  },
  reductionText: {
    color: '#e74c3c',
  },
  reductionPoints: {
    color: '#e74c3c',
  },
  totalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 16,
    paddingTop: 8,
    borderTopWidth: 1,
    borderTopColor: '#eee',
  },
  totalText: {
    fontSize: 18,
    fontWeight: '500',
  },
  totalPrice: {
    fontSize: 18,
    fontWeight: '500',
    color: '#000',
  },
  vipTag: {
    backgroundColor: colors.primary,
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 12,
    color: '#fff',
    fontSize: 12,
    fontWeight: '600',
  },
  vipBadge: {
    backgroundColor: colors.primary,
    paddingHorizontal: 10,
    paddingVertical: 4,
    borderRadius: 12,
    marginLeft: 8,
  },
  vipBadgeText: {
    color: '#fff',
    fontSize: 12,
    fontWeight: '600',
  },
  vipBonus: {
    color: colors.primary,
    fontStyle: 'italic',
    fontSize: 13,
  },
  vipInfoContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    padding: 12,
    borderRadius: 8,
    marginTop: 12,
    alignItems: 'center',
  },
  vipInfoText: {
    color: colors.primary,
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
  },
  pointsInfoContainer: {
    marginTop: 16,
    padding: 12,
    backgroundColor: '#f5f5f5',
    borderRadius: 8,
  },
  pointsInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  pointsInfoLabel: {
    fontSize: 14,
    color: '#666',
  },
  pointsInfoValue: {
    fontSize: 14,
    fontWeight: '600',
    color: '#333',
  },
  pointsInfoDivider: {
    height: 1,
    backgroundColor: '#ddd',
    marginVertical: 8,
  },
  pointsNote: {
    fontSize: 12,
    color: '#666',
    fontStyle: 'italic',
    textAlign: 'center',
  },
  pendingPoints: {
    color: '#666',
    fontStyle: 'italic',
  },pointsInfoContainer: {
    marginTop: 16,
    padding: 12,
    backgroundColor: '#f8f9fa',
    borderRadius: 8,
},
currentPoints: {
    fontSize: 15,
    fontWeight: '600',
    marginBottom: 8,
},
pointsDeduction: {
    fontSize: 14,
    color: colors.error,
    marginBottom: 4,
},
projectedPoints: {
    fontSize: 14,
    fontWeight: '500',
    marginBottom: 12,
},
futurePointsContainer: {
    marginTop: 8,
    padding: 8,
    backgroundColor: '#e9ecef',
    borderRadius: 6,
},
futurePointsText: {
    fontSize: 13,
    color: '#6c757d',
    fontStyle: 'italic',
    textAlign: 'center',
},
// Styles de la barre de progression
progressBarContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    overflow: 'hidden',
},
progressBar: {
    height: '100%',
    backgroundColor: colors.primary,
    borderRadius: 2,
},
progressBarWrapper: {
    position: 'absolute',
    bottom: 70, // Au-dessus des boutons
    left: 0,
    right: 0,
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingBottom: 8,
},
progressText: {
    fontSize: 12,
    color: colors.text,
    marginBottom: 4,
    textAlign: 'center',
    fontStyle: 'italic'
}
});