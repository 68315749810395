import { StyleSheet, Dimensions, Platform } from 'react-native';

const { height, width } = Dimensions.get('window');
const aspectRatio = 9 / 16;

export const isPWA = Platform.OS === 'web' ? 
  () => window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches 
  : () => false;

export const colors = {
  primary: '#1C2233',
  secondary: '#ff6347',
  accent: '#4CAF50',
  background: '#f5f5f5',
  text: '#333333',
  success: '#34c759',
  error: '#ff3b30',
  warning: '#ff0000',
  lightGray: '#e9e9ea',
  headerText: '#DAE1E7',  // Gris clair légèrement bleuté
};

export default StyleSheet.create({
  // Styles de base
  container: {
    flex: 1,
    backgroundColor: colors.background,
    ...(Platform.OS === 'web' && {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflow: 'hidden',
    }),
  },
  header: {
    position: 'fixed', // Modification ici
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.primary,
    paddingVertical: 14,
    paddingHorizontal: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.27,
    shadowRadius: 4.65,
    elevation: 6,
    zIndex: 1000,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255,255,255,0.1)',
    paddingTop: Platform.OS === 'web' && isPWA() ? 
      'env(safe-area-inset-top, 47px)' : 14,
    minHeight: Platform.OS === 'web' && isPWA() ? 
      'calc(60px + env(safe-area-inset-top, 47px))' : 60,
  },
  headerTitle: {
    fontSize: 20,
    fontFamily: 'AvenirNext-DemiBold',
    color: colors.headerText,
    textAlign: 'center',
    letterSpacing: 0.8,
    textShadowColor: 'rgba(0, 0, 0, 0.3)',
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 2,
  },
  headerWithButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerButton: {
    padding: 8,
  },
  headerButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: '500',
  },

  // Styles de scroll
  scrollContainer: {
    flexGrow: 1,
    backgroundColor: colors.background,
    ...(Platform.OS === 'web' && {
      height: '100%',
    }),
  },
  scrollView: {
    flex: 1,
    width: '100%',
    ...(Platform.OS === 'web' && {
      height: `calc(100vh - ${isPWA() ? '60px - env(safe-area-inset-top, 47px)' : '60px'} - env(safe-area-inset-bottom, 34px) - 85px)`,
      overflowY: 'auto',
      overflowX: 'hidden',
      WebkitOverflowScrolling: 'touch',
      paddingTop: Platform.OS === 'web' && isPWA() ? 
        'calc(env(safe-area-inset-top, 47px))' : '30px', // Suppression du 15px additionnel pour la PWA
    }),
    ...(Platform.OS !== 'web' && {
      paddingTop: 30,
    }),
  },

  // Nouveau style pour l'espaceur
  bottomSpacer: {
    height: Platform.OS === 'web' ? 
      'calc((85px + env(safe-area-inset-bottom, 34px)) * 0.1)' : 10, // Réduit de 0.2 à 0.1 et de 20 à 10
  },

  // Styles de formulaire
  input: {
    height: 50,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 15,
    marginBottom: 15,
    backgroundColor: '#f2f2f2',
    fontSize: 16,
    color: colors.text,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
    marginVertical: 10,
    fontSize: 14,
    fontWeight: 'bold',
  },

  // Styles de bouton
  button: {
    backgroundColor: colors.secondary,
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 15,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
  },
  buttonText: {
    color: '#FFFFFF',
    fontSize: 16,
    textAlign: 'center', 
    fontWeight: '600',
  },
  installButton: {
    backgroundColor: colors.accent,
    padding: 10,
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
    alignSelf: 'center',
  },
  installButtonText: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
  },

  // Styles de la TabBar
  tabBarContainer: {
    flexDirection: 'row',
    backgroundColor: colors.primary,
    height: Platform.OS === 'web' ? 
      'calc(85px + env(safe-area-inset-bottom, 34px))' : 85,
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    paddingTop: 10,
    paddingBottom: Platform.OS === 'web' ? 
      'calc(10px + env(safe-area-inset-bottom, 34px))' : 10,
    zIndex: 1000,
  },
  tab: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    height: '100%',
    minWidth: 80,
  },
  activeTab: {
    transform: [{ translateY: -5 }],
  },
  slider: {
    position: 'absolute',
    top: Platform.OS === 'web' && isPWA() ? 4 : 3,
    height: Platform.OS === 'web' && isPWA() ? 
      'calc(90% - env(safe-area-inset-bottom, 34px))' : '90%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: Platform.OS === 'web' && isPWA() ? 2 : 0,
  },
  
  sliderContent: {
    width: '75%',
    height: '85%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 20,
    boxShadow: `
      inset 0px 2px 4px rgba(255, 255, 255, 0.2),
      inset 0px -2px 4px rgba(0, 0, 0, 0.2),
      0px 4px 8px rgba(0, 0, 0, 0.25)
    `,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.1)',
  },
  icon: {
    marginBottom: 6,
  },
  tabLabel: {
    fontSize: 12,
    color: 'rgba(255, 255, 255, 0.5)',
    opacity: 1,
    fontFamily: 'AvenirNext-DemiBold',
    letterSpacing: 0.2,
  },
  activeTabLabel: {
    color: colors.secondary,
    opacity: 1,
  },
  modalBody: {
    paddingVertical: 15,
    gap: 10, // Ajoute un espacement entre les sections
  },
  appointmentDetails: {
    marginBottom: 15, // Sépare les informations du rendez-vous
  },
  userInfoSection: {
    marginBottom: 15, // Ajoute de l'espace avant la section utilisateur
  },
  locationInfo: {
    marginTop: 10, // Ajoute de l'espace après la section utilisateur
  },
  vipBadge: {
    backgroundColor: colors.accent,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 12,
    position: 'absolute',
    right: 16,
    top: Platform.OS === 'web' && isPWA() ? 'calc(50% + env(safe-area-inset-top, 47px) / 2)' : '50%',
    transform: [{ translateY: -12 }],
  },
  vipBadgeText: {
    color: '#FFFFFF',
    fontSize: 12,
    fontWeight: '600',
  },

  // Ajout d'un style pour le contenu des écrans
  screenContent: {
    flex: 1,
    paddingHorizontal: 16,
  },

  screenTitle: {
    fontSize: 24,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 12,
    marginTop: -5,
    textAlign: 'center',
  },
});