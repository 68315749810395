import React, { useState, useEffect, useRef } from 'react';
import { 
  View, 
  Text, 
  TextInput, 
  Pressable, 
  ActivityIndicator,
  ScrollView,
  Animated,
  Dimensions,
  Platform,
  Modal,
  SafeAreaView,
  Alert,
  Image,
  KeyboardAvoidingView,
  Easing, // Ajout de l'import Easing
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { auth, authService, db } from '../firebase';
import { RecaptchaVerifier } from 'firebase/auth';
import CustomButton from '../components/CustomButton';
import EditProfileModal from '../components/EditProfileModal';
import globalStyles, { colors } from '../screenStyles/GlobalStyles';
import styles from '../screenStyles/AuthStyles';
import { useNavigation, useRoute } from '@react-navigation/native';
import { collection, getDocs, doc, onSnapshot } from 'firebase/firestore';
import GoogleSignInButton from '../components/GoogleSignInButton';
import PhoneInputModal from '../components/PhoneInputModal';

const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
};

const AuthScreen = () => {
  // Navigation hooks
  const navigation = useNavigation();
  const route = useRoute();

  // États
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [authMode, setAuthMode] = useState('login');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [userData, setUserData] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  // Update authMode when route params change
  useEffect(() => {
    if (route?.params?.mode) {
      setAuthMode(route.params.mode);
    }
  }, [route?.params?.mode]);
  
  // États des formulaires
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [resetEmail, setResetEmail] = useState('');

  // États des erreurs
  const [lastNameError, setLastNameError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [resetEmailError, setResetEmailError] = useState('');

  // Refs
  const recaptchaContainerRef = useRef(null);
  const recaptchaVerifierRef = useRef(null);
  const initRef = useRef(false);

  // Animation
  const slideAnim = useRef(new Animated.Value(0)).current;
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const { width } = Dimensions.get('window');
  const numberOfTabs = Platform.OS === 'web' ? 3 : 2;
  const containerWidth = Math.min(400, width - 40);
  const tabWidth = containerWidth / numberOfTabs;
  const sliderWidth = tabWidth * 0.95; // Augmenter la largeur du slider à 95% de la largeur du tab

  const fadeAnimation = useRef(new Animated.Value(0)).current;
  const loadingAnimation = useRef(new Animated.Value(1)).current;

  const infoAnimations = {
    email: useRef(new Animated.Value(0)).current,
    phone: useRef(new Animated.Value(0)).current,
    name: useRef(new Animated.Value(0)).current,
    memberSince: useRef(new Animated.Value(0)).current,
    vip: useRef(new Animated.Value(0)).current
  };

  const buttonsAnimation = useRef(new Animated.Value(0)).current;

  // Ajout d'une nouvelle animation pour le header
  const headerAnimation = useRef(new Animated.Value(0)).current;

  // Modifier l'animation existante pour inclure le header
  const animateInfoItems = () => {
    // Animation du header d'abord
    Animated.sequence([
      Animated.timing(headerAnimation, {
        toValue: 1,
        duration: 600,
        delay: 100,
        useNativeDriver: false,
        easing: Easing.out(Easing.quad)
      }),
      Animated.sequence([
        Animated.timing(loadingAnimation, {
          toValue: 1,
          duration: 500,
          useNativeDriver: false
        }),
        Animated.timing(loadingAnimation, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false
        }),
        Animated.parallel([
          Animated.stagger(100, Object.values(infoAnimations).map(anim =>
            Animated.timing(anim, {
              toValue: 1,
              duration: 400,
              useNativeDriver: false,
              easing: Easing.out(Easing.quad)
            })
          )),
          Animated.timing(buttonsAnimation, {
            toValue: 1,
            duration: 300,
            delay: 100,
            useNativeDriver: false,
            easing: Easing.out(Easing.quad)
          })
        ])
      ])
    ]).start();
  };

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = false;
      checkCurrentUser();
      if (Platform.OS === 'web') {
        initializeRecaptcha();
      }
    }
    return () => {
      cleanupRecaptcha();
    };
  }, []);

  useEffect(() => {
    if (currentUser && !userData) {
      loadUserData();
    }

    // Ajouter l'écoute en temps réel
    let unsubscribe;
    if (currentUser) {
      const userRef = doc(db, 'users', currentUser.uid);
      unsubscribe = onSnapshot(userRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setUserData(docSnapshot.data());
        }
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser]);

  useEffect(() => {
    checkCurrentUser();
    if (Platform.OS === 'web') {
      initializeRecaptcha();
    }
    return () => {
      cleanupRecaptcha();
    };
  }, []);

  useEffect(() => {
    animateSlide();
    clearErrors();
  }, [authMode]);

  useEffect(() => {
    if (route?.params?.mode === 'register') {
      setAuthMode('register');
      // Forcer l'animation immédiatement
      requestAnimationFrame(() => {
        slideAnim.setValue(1);
      });
    }
  }, [route?.params?.mode]);

  useEffect(() => {
    if (showForgotPassword) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false,
      }).start();
    } else {
      fadeAnim.setValue(0);
    }
  }, [showForgotPassword]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setIsInitializing(false);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userData) {
      animateInfoItems();
    }
  }, [userData]);

  const loadUserData = async () => {
    if (!currentUser) return;
    
    const result = await authService.getUserData(currentUser.uid);
    if (result.success) {
      setUserData(result.data);
    }
  };

  const animateSlide = () => {
    Animated.spring(slideAnim, {
      toValue: authMode === 'login' ? 0 : authMode === 'register' ? 1 : 2,
      friction: 8,
      tension: 50,
      useNativeDriver: false,
    }).start();
  };

  const animateContentTransition = () => {
    // Fade out loading
    Animated.sequence([
      Animated.timing(loadingAnimation, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }),
      // Fade in content
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false,
      })
    ]).start();
  };

  const translateX = slideAnim.interpolate({
    inputRange: [0, 1, 2],
    outputRange: [0, tabWidth, tabWidth * 2],
  });

  const initializeRecaptcha = () => {
    try {
      // Nettoyage de l'ancien conteneur si existe
      cleanupRecaptcha();

      // Création du nouveau conteneur
      const container = document.createElement('div');
      container.id = 'recaptcha-container';
      container.style.position = 'fixed';
      container.style.bottom = '10px';
      container.style.right = '10px';
      container.style.zIndex = '1000';
      container.style.opacity = '0';
      document.body.appendChild(container);
      recaptchaContainerRef.current = container;

      // Initialisation du reCAPTCHA
      const verifier = new RecaptchaVerifier(
        auth,
        container,
        {
          size: 'invisible',
          callback: () => {
            console.log('reCAPTCHA vérifié');
          },
          'expired-callback': () => {
            console.log('reCAPTCHA expiré');
            cleanupRecaptcha();
            initializeRecaptcha();
          }
        }
      );

      recaptchaVerifierRef.current = verifier;
      console.log('reCAPTCHA initialisé avec succès');
    } catch (error) {
      console.error('Erreur d\'initialisation reCAPTCHA:', error);
    }
  };

  const cleanupRecaptcha = () => {
    try {
      if (recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current.clear();
        recaptchaVerifierRef.current = null;
      }

      if (recaptchaContainerRef.current) {
        document.body.removeChild(recaptchaContainerRef.current);
        recaptchaContainerRef.current = null;
      }

      const oldContainer = document.getElementById('recaptcha-container');
      if (oldContainer) {
        oldContainer.remove();
      }
    } catch (error) {
      console.error('Erreur de nettoyage reCAPTCHA:', error);
    }
  };

  const clearErrors = () => {
    setEmailError('');
    setPasswordError('');
    setPhoneError('');
    setCodeError('');
    setFirstNameError('');
    setLastNameError('');
    setResetEmailError('');
  };

  const checkCurrentUser = () => {
    const user = authService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      console.log('Current user:', user);
    }
  };

  // Validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return false;
    }
    if (!emailRegex.test(email)) {
      return false;
    }
    return true;
  };

  const handleEmailChange = (text) => {
    setEmail(text);
    setEmailError('');
  };

  const validatePassword = (password) => {
    if (!password) {
      setPasswordError('Le mot de passe est requis');
      return false;
    }
    if (password.length < 6) {
      setPasswordError('Le mot de passe doit contenir au moins 6 caractères');
      return false;
    }
    setPasswordError('');
    return true;
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    if (!phone) {
      setPhoneError('Le numéro de téléphone est requis');
      return false;
    }
    if (!phoneRegex.test(phone)) {
      setPhoneError('Format de numéro invalide');
      return false;
    }
    setPhoneError('');
    return true;
  };

  const validateCode = (code) => {
    if (!code) {
      setCodeError('Le code est requis');
      return false;
    }
    if (code.length !== 6 || !/^\d+$/.test(code)) {
      setCodeError('Le code doit contenir 6 chiffres');
      return false;
    }
    setCodeError('');
    return true;
  };

  const validateName = (name, setError) => {
    if (!name) {
      setError('Ce champ est requis');
      return false;
    }
    if (name.length < 2) {
      setError('Minimum 2 caractères');
      return false;
    }
    setError('');
    return true;
  };

  // Handlers

  const handleGoogleSignIn = async () => {
    if (Platform.OS !== 'web') {
      Alert.alert('Info', 'La connexion Google n\'est disponible que sur le web');
      return;
    }
  
    setIsLoading(true);
    try {
      const result = await authService.signInWithGoogle();
      if (result.success) {
        setCurrentUser(result.user);
        
        const userData = await authService.getUserData(result.user.uid);
        if (userData.success) {
          setUserData(userData.data);
          
          // Vérifier si c'est un provider
          if (!userData.data?.userType || userData.data.userType !== 'provider') {
            navigation?.reset({
              index: 0,
              routes: [{ name: 'Accueil' }],
            });
          }
          
          if (!userData.data?.phoneNumber) {
            setShowPhoneModal(true);
          }
        }
      } else {
        if (result.errorCode === 'auth/popup-closed-by-user') return;
        
        Alert.alert(
          'Erreur', 
          result.error || 'Erreur de connexion Google'
        );
      }
    } catch (error) {
      console.error('Erreur Google Sign-In:', error);
      Alert.alert('Erreur', 'Erreur lors de la connexion Google');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneModalClose = async (success) => {
    setShowPhoneModal(false);
    if (success) {
      // Recharger les données utilisateur
      const userData = await authService.getUserData(currentUser.uid);
      if (userData.success) {
        setUserData(userData.data);
      }
    }
  };

  const handleEmailAuth = async () => {
    clearErrors();
    
    if (authMode === 'register') {
      // Vérifier si le numéro existe déjà
      const phoneCheck = await authService.checkPhoneExists(phoneNumber);
      if (phoneCheck.exists) {
        setPhoneError('Ce numéro est déjà utilisé');
        return;
      }

      const isFormValid = 
        validateName(lastName, setLastNameError) &&
        validateName(firstName, setFirstNameError) &&
        validatePhone(phoneNumber) &&
        validateEmail(email) &&
        validatePassword(password);

      if (!isFormValid) return;
    } else {
      if (!validateEmail(email) || !validatePassword(password)) return;
    }

    setIsLoading(true);
    try {
      const result = authMode === 'login'
        ? await authService.loginWithEmail(email, password)
        : await authService.registerWithEmail(email, password, {
            firstName,
            lastName,
            phoneNumber,
          });

      if (result.success) {
        setCurrentUser(result.user);
        const userData = await authService.getUserData(result.user.uid);
        if (userData.success) {
          setUserData(userData.data);
          // Redirection vers l'accueil si ce n'est pas un provider
          if (!userData.data?.userType || userData.data.userType !== 'provider') {
            navigation?.reset({
              index: 0,
              routes: [{ name: 'Accueil' }],
            });
          }
        }
      } else {
        // Gérer les différents types d'erreurs
        switch (result.errorCode) {
          case 'auth/invalid-email':
            setEmailError('Format d\'email invalide');
            break;
          case 'auth/user-not-found':
            setEmailError('Aucun compte associé à cet email');
            break;
          case 'auth/wrong-password':
            setPasswordError('Mot de passe incorrect');
            break;
          case 'auth/invalid-credential':
            // Vérifier d'abord si l'utilisateur existe
            const emailCheck = await authService.checkEmailExists(email);
            if (emailCheck.exists) {
              setPasswordError('Mot de passe incorrect');
            } else {
              setEmailError('Aucun compte associé à cet email');
            }
            break;
          case 'auth/email-already-in-use':
            setEmailError('Cette adresse email est déjà utilisée');
            break;
          case 'auth/weak-password':
            setPasswordError('Le mot de passe doit contenir au moins 6 caractères');
            break;
          case 'auth/too-many-requests':
            Alert.alert(
              'Trop de tentatives',
              'Votre compte a été temporairement bloqué suite à de trop nombreuses tentatives de connexion. Veuillez réessayer plus tard ou réinitialiser votre mot de passe.'
            );
            break;
          default:
            Alert.alert('Erreur', result.error || 'Une erreur est survenue lors de l\'authentification');
        }
      }
    } catch (error) {
      console.error('Erreur auth:', error);
      Alert.alert('Erreur', 'Une erreur est survenue lors de l\'authentification');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneAuth = async () => {
    if (!validatePhone(phoneNumber)) return;
  
    setIsLoading(true);
    try {
      if (Platform.OS === 'web') {
        // Réinitialiser le reCAPTCHA pour chaque tentative
        await initializeRecaptcha();
  
        if (!recaptchaVerifierRef.current) {
          throw new Error('reCAPTCHA non initialisé');
        }
  
        let formattedPhone = phoneNumber;
        if (!phoneNumber.startsWith('+')) {
          formattedPhone = phoneNumber.startsWith('0') 
            ? `+33${phoneNumber.slice(1)}` 
            : `+33${phoneNumber}`;
        }
  
        const result = await authService.sendPhoneVerification(
          formattedPhone, 
          recaptchaVerifierRef.current
        );
  
        if (result.success) {
          setVerificationId(result.verificationId);
        } else {
          setPhoneError(result.error);
          if (result.needsRecaptchaReset) {
            await initializeRecaptcha();
          }
        }
      } else {
        Alert.alert('Info', 'L\'authentification par téléphone n\'est disponible que sur le web');
      }
    } catch (error) {
      console.error('Erreur auth téléphone:', error);
      Alert.alert('Erreur', error.message || 'Erreur lors de l\'envoi du code');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    if (!validateCode(verificationCode)) return;
  
    setIsLoading(true);
    try {
      const result = await authService.verifyPhoneCode(verificationId, verificationCode);
      
      if (result.success) {
        setCurrentUser(result.user);
        const userDataResult = await authService.getUserDataByPhone(result.user.phoneNumber);
  
        if (userDataResult.success) {
          setUserData(userDataResult.data);
          // Redirection vers l'accueil si ce n'est pas un provider
          if (!userDataResult.data?.userType || userDataResult.data.userType !== 'provider') {
            navigation?.reset({
              index: 0,
              routes: [{ name: 'Accueil' }],
            });
          }
        }
      } else {
        setCodeError(result.error);
      }
    } catch (error) {
      console.error('Erreur vérification:', error);
      setCodeError('Code invalide');
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!validateEmail(resetEmail)) return;

    setResetPasswordLoading(true);
    try {
      // Get all users and check if email exists
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      let emailExists = false;
      
      usersSnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.email === resetEmail) {
          emailExists = true;
        }
      });

      if (!emailExists) {
        setResetEmailError('Aucun compte associé à cet email');
        setResetPasswordLoading(false);
        return;
      }

      const result = await authService.resetPassword(resetEmail);
      if (result.success) {
        setResetPasswordSuccess(true);
        setResetEmailError('');
        // Attendre 2 secondes pour montrer le message de succès avant de fermer
        setTimeout(() => {
          setResetPasswordSuccess(false);
          setShowForgotPassword(false);
          setResetEmail('');
        }, 3000);
      } else {
        if (result.errorCode === 'auth/user-not-found') {
          setResetEmailError('Aucun compte associé à cet email');
        } else {
          setResetEmailError(result.error);
        }
      }
    } catch (error) {
      console.error('Erreur reset password:', error);
      setResetEmailError('Une erreur est survenue');
    } finally {
      setResetPasswordLoading(false);
    }
  };

  const handleAnonymousAuth = async () => {
    setIsLoading(true);
    try {
      const result = await authService.signInAnonymously();
      if (result.success) {
        setCurrentUser(result.user);
      } else {
        Alert.alert('Erreur', result.error);
      }
    } catch (error) {
      console.error('Erreur anonyme:', error);
      Alert.alert('Erreur', 'Une erreur est survenue');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    setIsLoading(true);
    try {
      const result = await authService.signOut();
      if (result.success) {
        setCurrentUser(null);
        setUserData(null);
        resetForm();
        cleanupRecaptcha();
      } else {
        Alert.alert('Erreur', result.error);
      }
    } catch (error) {
      console.error('Erreur déconnexion:', error);
      Alert.alert('Erreur', 'Une erreur est survenue');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditProfileClose = async (messageOrEvent) => {
    setShowEditProfile(false);
    
    // Si le message indique une suppression de compte, déclencher la déconnexion
    if (messageOrEvent === 'Compte supprimé avec succès') {
      await authService.signOut();
      setCurrentUser(null);
      setUserData(null);
      return;
    }
    
    // Sinon, comportement normal
    checkCurrentUser();
    loadUserData();
    
    // Vérifier si c'est un message string et non un événement
    if (typeof messageOrEvent === 'string') {
      setSuccessMessage(messageOrEvent);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setSuccessMessage('');
      }, 3000);
    }
  };

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setPhoneNumber('');
    setVerificationCode('');
    setVerificationId(null);
    setFirstName('');
    setLastName('');
    setResetEmail('');
    setAuthMode('login');
    clearErrors();
  };

  // Modal de réinitialisation du mot de passe
  const renderForgotPasswordModal = () => (
    <Modal
      visible={showForgotPassword}
      transparent={true}
      animationType="fade"
      onRequestClose={() => {
        setShowForgotPassword(false);
        setResetEmail('');
        setResetEmailError('');
        setResetPasswordSuccess(false);
      }}
    >
      <View style={styles.forgotPasswordModal}>
        <View style={styles.forgotPasswordContent}>
          <View style={styles.forgotPasswordHeader}>
            <Text style={styles.forgotPasswordTitle}>
              {resetPasswordSuccess ? 'Email envoyé' : 'Mot de passe oublié'}
            </Text>
            <Pressable 
              onPress={() => {
                setShowForgotPassword(false);
                setResetEmail('');
                setResetEmailError('');
                setResetPasswordSuccess(false);
              }}
              style={({ pressed }) => [
                styles.closeButton,
                pressed && { opacity: 0.7 }
              ]}
            >
              <Ionicons name="close" size={18} color={colors.text} />
            </Pressable>
          </View>

          {resetPasswordSuccess ? (
            <View style={styles.successContainer}>
              <Text style={[styles.forgotPasswordDescription, {color: colors.success}]}>
                Un email de réinitialisation a été envoyé à {resetEmail}
              </Text>
              <Text style={styles.instructionText}>
                Suis les instructions dans l'email pour réinitialiser ton mot de passe
              </Text>
            </View>
          ) : (
            <>
              <Text style={styles.forgotPasswordDescription}>
                Entre ton adresse email pour recevoir un lien de réinitialisation de ton mot de passe.
              </Text>

              <View style={[
                styles.inputContainer,
                resetEmailError && styles.inputContainerError
              ]}>
                <Ionicons 
                  name="mail-outline" 
                  size={24} 
                  color={resetEmailError ? colors.secondary : colors.text} 
                />
                <TextInput
                  style={styles.input}
                  placeholder="Email"
                  value={resetEmail}
                  onChangeText={(text) => {
                    setResetEmail(text);
                    setResetEmailError('');
                  }}
                  keyboardType="email-address"
                  autoCapitalize="none"
                  autoComplete="email"
                  placeholderTextColor={colors.placeholder}
                />
              </View>
              {resetEmailError ? (
                <Text style={styles.errorText}>{resetEmailError}</Text>
              ) : null}

              <View style={styles.forgotPasswordButtons}>
                <CustomButton 
                  title="Envoyer le lien"
                  onPress={handleForgotPassword}
                  disabled={resetPasswordLoading}
                  style={styles.saveButton}
                />
                <CustomButton 
                  title="Annuler"
                  onPress={() => {
                    setShowForgotPassword(false);
                    setResetEmail('');
                    setResetEmailError('');
                  }}
                  style={styles.cancelButton}
                />
              </View>
            </>
          )}

          {resetPasswordLoading && (
            <View style={styles.loadingOverlay}>
              <ActivityIndicator size="large" color={colors.secondary} />
            </View>
          )}
        </View>
      </View>
    </Modal>
  );

  const renderActionButtons = () => (
    <Animated.View style={[
      styles.actionButtonsContainer,
      {
        opacity: buttonsAnimation,
        transform: [{
          translateY: buttonsAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [50, 0]
          })
        }]
      }
    ]}>
      {!currentUser?.isAnonymous && (
        <CustomButton 
          title="Modifier le profil"
          onPress={() => setShowEditProfile(true)}
          style={styles.editButton}
          icon="pencil"
        />
      )}

      <CustomButton 
        title="Se déconnecter"
        onPress={handleSignOut}
        style={[styles.signOutButton, { marginTop: 0 }]}
        icon="log-out"
        disabled={isLoading}
      />
    </Animated.View>
  );

  // Modifier le rendu du profileHeader
  const renderProfileHeader = () => (
    <Animated.View 
      style={[
        styles.profileHeader,
        {
          opacity: headerAnimation,
          transform: [{
            translateY: headerAnimation.interpolate({
              inputRange: [0, 1],
              outputRange: [20, 0]
            })
          }]
        }
      ]}
    >
      <View style={styles.avatarContainer}>
        <Animated.View style={{
          opacity: headerAnimation,
          transform: [{
            scale: headerAnimation.interpolate({
              inputRange: [0, 1],
              outputRange: [0.8, 1]
            })
          }]
        }}>
          {userData?.photoURL ? (
            <Image 
              source={{ uri: userData.photoURL }}
              style={[styles.avatarCircle, { width: 70, height: 70 }]}
              resizeMode="cover"
            />
          ) : (
            <View style={styles.avatarCircle}>
              <Ionicons name="person" size={40} color={colors.primary} />
            </View>
          )}
        </Animated.View>
        <Animated.View 
          style={[
            styles.welcomeTextContainer,
            {
              opacity: headerAnimation,
              transform: [{
                translateX: headerAnimation.interpolate({
                  inputRange: [0, 1],
                  outputRange: [20, 0]
                })
              }]
            }
          ]}
        >
          <Text style={styles.welcomeText}>
            {userData?.firstName || 'Utilisateur'}
          </Text>
          <Text style={styles.accountTypeText}>
            Compte Vérifié
            <Ionicons 
              name="checkmark-circle" 
              size={16} 
              color={colors.primary} 
              style={styles.verifiedIcon} 
            />
          </Text>
        </Animated.View>
      </View>
    </Animated.View>
  );

  // Rendu du profil utilisateur connecté
  if (isInitializing) {
    return (
      <View style={[
        globalStyles.container, 
        { 
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          backgroundColor: '#fff' // Fond blanc pour un meilleur contraste
        }
      ]}>
        <View style={{
          padding: 20,
          borderRadius: 10,
          alignItems: 'center'
        }}>
          <ActivityIndicator 
            size="large" 
            color={colors.primary}
          />
          <Text style={{
            marginTop: 10,
            color: colors.text,
            fontSize: 14,
            fontWeight: '500'
          }}>
            Chargement...
          </Text>
        </View>
      </View>
    );
  }

  if (currentUser) {
    return (
      <SafeAreaView style={globalStyles.container}>
        <ScrollView 
          style={globalStyles.scrollView}
          contentContainerStyle={[globalStyles.scrollContainer, { paddingBottom: 200 }]} // Augmenté de 180 à 200
        >
          {showSuccess && (
            <View style={styles.successMessage}>
              <Ionicons 
                name="checkmark-circle-outline" 
                size={24} 
                color="white"
                style={styles.successIcon}
              />
              <Text style={styles.successText}>{successMessage}</Text>
            </View>
          )}

          {renderProfileHeader()}

          {/* Main Content Cards */}
          <View style={styles.profileContent}>
            {/* Personal Information Card */}
            <View style={styles.infoCard}>
              <View style={styles.cardHeader}>
                <Ionicons name="person-circle" size={24} color={colors.primary} />
                <Text style={styles.cardTitle}>Informations Personnelles</Text>
              </View>
              
              <View style={{ position: 'relative' }}>
                {/* Loading overlay */}
                <Animated.View 
                  style={[
                    styles.loadingContainer,
                    {
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      opacity: loadingAnimation,
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#fff'
                    }
                  ]}
                >
                  <ActivityIndicator size="small" color={colors.primary} />
                  <Text style={[styles.loadingText, { marginTop: 10 }]}>
                    Chargement des informations...
                  </Text>
                </Animated.View>

                {/* Content */}
                <View style={styles.infoGrid}>
                  {userData && (
                    <>
                      {!currentUser.isAnonymous && (
                        <>
                          <Animated.View style={[
                            styles.infoItem,
                            {
                              opacity: infoAnimations.email,
                              transform: [{
                                translateY: infoAnimations.email.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [20, 0]
                                })
                              }]
                            }
                          ]}>
                            <Ionicons name="mail" size={20} color={colors.text} />
                            <View style={styles.infoTextContainer}>
                              <Text style={styles.infoLabel}>Email</Text>
                              <Text style={styles.infoValue}>{userData.email || currentUser.email || 'Non renseigné'}</Text>
                            </View>
                          </Animated.View>

                          <Animated.View style={[
                            styles.infoItem,
                            {
                              opacity: infoAnimations.phone,
                              transform: [{
                                translateY: infoAnimations.phone.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [20, 0]
                                })
                              }]
                            }
                          ]}>
                            <Ionicons name="call" size={20} color={colors.text} />
                            <View style={styles.infoTextContainer}>
                              <Text style={styles.infoLabel}>Téléphone</Text>
                              <Text style={styles.infoValue}>{userData.phoneNumber || currentUser.phoneNumber || 'Non renseigné'}</Text>
                            </View>
                          </Animated.View>

                          <Animated.View style={[
                            styles.infoItem,
                            {
                              opacity: infoAnimations.name,
                              transform: [{
                                translateY: infoAnimations.name.interpolate({
                                  inputRange: [0, 1],
                                  outputRange: [20, 0]
                                })
                              }]
                            }
                          ]}>
                            <Ionicons name="person" size={20} color={colors.text} />
                            <View style={styles.infoTextContainer}>
                              <Text style={styles.infoLabel}>Nom complet</Text>
                              <Text style={styles.infoValue}>
                                {userData.firstName && userData.lastName 
                                  ? `${userData.firstName} ${userData.lastName}`
                                  : 'Non renseigné'}
                              </Text>
                            </View>
                          </Animated.View>
                        </>
                      )}

                      <Animated.View style={[
                        styles.infoItem,
                        {
                          opacity: infoAnimations.memberSince,
                          transform: [{
                            translateY: infoAnimations.memberSince.interpolate({
                              inputRange: [0, 1],
                              outputRange: [20, 0]
                            })
                          }]
                        }
                      ]}>
                        <Ionicons name="time" size={20} color={colors.text} />
                        <View style={styles.infoTextContainer}>
                          <Text style={styles.infoLabel}>Membre depuis</Text>
                          <Text style={styles.infoValue}>
                            {new Date(currentUser.metadata.creationTime).toLocaleDateString()}
                          </Text>
                        </View>
                      </Animated.View>

                      {userData.isVIP && (
                        <Animated.View style={[
                          styles.vipInfoContainer,
                          {
                            opacity: infoAnimations.vip,
                            transform: [{
                              translateY: infoAnimations.vip.interpolate({
                                inputRange: [0, 1],
                                outputRange: [20, 0]
                              })
                            }]
                          }
                        ]}>
                          <Text style={styles.vipInfoTitle}>
                            <Ionicons name="star" size={18} color={colors.primary} /> Statut VIP
                          </Text>
                          <View style={styles.vipInfoContent}>
                            <Text style={styles.vipInfoText}>
                              Début : {formatDate(userData.vipStartDate)}
                            </Text>
                            <Text style={styles.vipInfoExpiryText}>
                              Prend fin le : {formatDate(userData.vipEndDate)}
                            </Text>
                          </View>
                        </Animated.View>
                      )}
                    </>
                  )}
                </View>
              </View>
            </View>

            {/* Action Buttons */}
            {userData && renderActionButtons()}
          </View>
          {/* Ajout de l'espaceur en bas */}
          <View style={globalStyles.bottomSpacer} />
        </ScrollView>

        <Modal
        visible={showEditProfile}
        transparent={true}
        animationType="fade"
        onRequestClose={() => handleEditProfileClose()}
      >
        <EditProfileModal 
          onClose={handleEditProfileClose}
          userData={userData}
          currentUser={{
            ...currentUser, 
            authMethod: userData?.authMethod || 'email',
            requiresCompletion: currentUser?.requiresCompletion
          }}
        />
      </Modal>

        <PhoneInputModal
          visible={showPhoneModal}
          onClose={handlePhoneModalClose}
          currentUser={currentUser}
        />

        {isLoading && (
          <View style={styles.loadingOverlay}>
            <ActivityIndicator size="large" color={colors.secondary} />
          </View>
        )}
      </SafeAreaView>
    );
  }

  // Rendu du formulaire d'authentification
  return (
    <SafeAreaView style={globalStyles.container}>
      <ScrollView 
        style={styles.scrollView} 
        contentContainerStyle={{ flexGrow: 1 }} // Ajout pour permettre le scroll
      >
        <View style={styles.authContainer}>
          <View style={[styles.formContainer, { maxWidth: containerWidth }]}>
          <View style={styles.tabContainer}>
          <Animated.View 
            style={[
              styles.slider, 
              { 
                transform: [{ translateX }],
                width: sliderWidth,
              }
            ]} 
          />
          
          <Pressable 
            style={[
              styles.tab,
              {
                width: tabWidth,
                alignItems: 'center',
                justifyContent: 'center'
              }
            ]}
            onPress={() => setAuthMode('login')}
          >
            <Ionicons 
              name="log-in-outline" 
              size={20} 
              color={authMode === 'login' ? colors.secondary : colors.text} 
            />
            <Text style={[
              styles.tabText,
              authMode === 'login' && styles.activeTabText,
              { textAlign: 'center' }
            ]}>
              Connexion
            </Text>
          </Pressable>

              <Pressable 
                style={[
                  styles.tab,
                  {
                    width: tabWidth,
                    alignItems: 'center',
                    justifyContent: 'center'
                  }
                ]}
                onPress={() => setAuthMode('register')}
              >
                <Ionicons 
                  name="person-add-outline" 
                  size={20} 
                  color={authMode === 'register' ? colors.secondary : colors.text} 
                />
                <Text style={[
                  styles.tabText,
                  authMode === 'register' && styles.activeTabText,
                  { textAlign: 'center' }
                ]}>
                  Inscription
                </Text>
              </Pressable>

              {Platform.OS === 'web' && (
                <Pressable 
                  style={[
                    styles.tab,
                    {
                      width: tabWidth,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }
                  ]}
                  onPress={() => setAuthMode('phone')}
                >
                  <Ionicons 
                    name="phone-portrait-outline" 
                    size={20} 
                    color={authMode === 'phone' ? colors.secondary : colors.text} 
                  />
                  <Text style={[
                    styles.tabText,
                    authMode === 'phone' && styles.activeTabText,
                    { textAlign: 'center' }
                  ]}>
                    Téléphone
                  </Text>
                </Pressable>
              )}
            </View>

            {authMode === 'phone' ? (
              !verificationId ? (
                <>
                  <View style={[styles.inputContainer, phoneError && styles.inputContainerError]}>
                    <Ionicons 
                      name="phone-portrait-outline" 
                      size={24} 
                      color={phoneError ? colors.secondary : colors.text} 
                    />
                    <TextInput
                      style={styles.input}
                      placeholder="Numéro de téléphone"
                      value={phoneNumber}
                      onChangeText={(text) => {
                        setPhoneNumber(text);
                        setPhoneError('');
                      }}
                      keyboardType="phone-pad"
                      placeholderTextColor={colors.placeholder}
                    />
                  </View>
                  {phoneError && <Text style={styles.errorText}>{phoneError}</Text>}
                  <CustomButton 
                    title="Envoyer le code"
                    onPress={handlePhoneAuth}
                    disabled={isLoading}
                  />
                </>
              ) : (
                <>
                  <View style={[styles.inputContainer, codeError && styles.inputContainerError]}>
                    <Ionicons 
                      name="key-outline" 
                      size={24} 
                      color={codeError ? colors.secondary : colors.text} 
                    />
                    <TextInput
                      style={styles.input}
                      placeholder="Code de vérification"
                      value={verificationCode}
                      onChangeText={(text) => {
                        setVerificationCode(text);
                        setCodeError('');
                      }}
                      keyboardType="number-pad"
                      placeholderTextColor={colors.placeholder}
                      maxLength={6}
                    />
                  </View>
                  {codeError && <Text style={styles.errorText}>{codeError}</Text>}
                  <CustomButton 
                    title="Vérifier le code"
                    onPress={handleVerifyCode}
                    disabled={isLoading}
                  />
                </>
              )
            ) : (
              <>
                {authMode === 'register' && (
                  <>
                    <View style={[styles.inputContainer, lastNameError && styles.inputContainerError]}>
                      <Ionicons 
                        name="person-outline" 
                        size={24} 
                        color={lastNameError ? colors.secondary : colors.text} 
                      />
                      <TextInput
                        style={styles.input}
                        placeholder="Nom"
                        value={lastName}
                        onChangeText={(text) => {
                          setLastName(text);
                          setLastNameError('');
                        }}
                        placeholderTextColor={colors.placeholder}
                      />
                      {lastName && !lastNameError && (
                        <Ionicons 
                          name="checkmark-circle" 
                          size={20} 
                          color={colors.success}
                          style={styles.validationIcon} 
                        />
                      )}
                    </View>
                    {lastNameError && (
                      <View style={styles.errorContainer}>
                        <Ionicons name="alert-circle" size={16} color={colors.secondary} />
                        <Text style={styles.errorText}>{lastNameError}</Text>
                      </View>
                    )}

                    <View style={[styles.inputContainer, firstNameError && styles.inputContainerError]}>
                      <Ionicons 
                        name="person-outline" 
                        size={24} 
                        color={firstNameError ? colors.secondary : colors.text} 
                      />
                      <TextInput
                        style={styles.input}
                        placeholder="Prénom"
                        value={firstName}
                        onChangeText={(text) => {
                          setFirstName(text);
                          setFirstNameError('');
                        }}
                        placeholderTextColor={colors.placeholder}
                      />
                      {firstName && !firstNameError && (
                        <Ionicons 
                          name="checkmark-circle" 
                          size={20} 
                          color={colors.success}
                          style={styles.validationIcon} 
                        />
                      )}
                    </View>
                    {firstNameError && (
                      <View style={styles.errorContainer}>
                        <Ionicons name="alert-circle" size={16} color={colors.secondary} />
                        <Text style={styles.errorText}>{firstNameError}</Text>
                      </View>
                    )}

                    <View style={[styles.inputContainer, phoneError && styles.inputContainerError]}>
                      <Ionicons 
                        name="call-outline" 
                        size={24} 
                        color={phoneError ? colors.secondary : colors.text} 
                      />
                      <TextInput
                        style={styles.input}
                        placeholder="Numéro de téléphone"
                        value={phoneNumber}
                        onChangeText={(text) => {
                          setPhoneNumber(text);
                          setPhoneError('');
                        }}
                        keyboardType="phone-pad"
                        placeholderTextColor={colors.placeholder}
                      />
                      {phoneNumber && !phoneError && (
                        <Ionicons 
                          name="checkmark-circle" 
                          size={20} 
                          color={colors.success}
                          style={styles.validationIcon} 
                        />
                      )}
                    </View>
                    {phoneError && (
                      <View style={styles.errorContainer}>
                        <Ionicons name="alert-circle" size={16} color={colors.secondary} />
                        <Text style={styles.errorText}>{phoneError}</Text>
                      </View>
                    )}
                  </>
                )}

                <View style={[styles.inputContainer, emailError && styles.inputContainerError]}>
                  <Ionicons 
                    name="mail-outline" 
                    size={24} 
                    color={emailError ? colors.secondary : colors.text} 
                  />
                  <TextInput
                    style={styles.input}
                    placeholder="Email"
                    value={email}
                    onChangeText={handleEmailChange}
                    inputMode="email"
                    autoCapitalize="none"
                    autoComplete="email"
                    placeholderTextColor={colors.placeholder}
                  />
                  {email && !emailError && validateEmail(email) && (
                    <Ionicons 
                      name="checkmark-circle" 
                      size={20} 
                      color={colors.success}
                      style={styles.validationIcon} 
                    />
                  )}
                </View>
                {emailError && (
                  <View style={styles.errorContainer}>
                    <Ionicons name="alert-circle" size={16} color={colors.secondary} />
                    <Text style={styles.errorText}>{emailError}</Text>
                  </View>
                )}
                
                <View style={[styles.inputContainer, passwordError && styles.inputContainerError]}>
                  <Ionicons 
                    name="lock-closed-outline" 
                    size={24} 
                    color={passwordError ? colors.secondary : colors.text} 
                  />
                  <TextInput
                    style={styles.input}
                    placeholder="Mot de passe"
                    value={password}
                    onChangeText={(text) => {
                      setPassword(text);
                      setPasswordError('');
                    }}
                    secureTextEntry
                    placeholderTextColor={colors.placeholder}
                  />
                </View>
                {passwordError && (
                  <View style={styles.errorContainer}>
                    <Ionicons name="alert-circle" size={16} color={colors.secondary} />
                    <Text style={styles.errorText}>{passwordError}</Text>
                  </View>
                )}

                {authMode === 'login' && (
                  <Pressable 
                    onPress={() => setShowForgotPassword(true)}
                    style={({ pressed }) => [
                      styles.forgotPasswordLink,
                      pressed && { opacity: 0.7 }
                    ]}
                  >
                    <Text style={styles.forgotPasswordText}>
                      Mot de passe oublié ?
                    </Text>
                  </Pressable>
                )}

                <CustomButton 
                  title={authMode === 'login' ? 'Se connecter' : "S'inscrire"}
                  onPress={handleEmailAuth}
                  disabled={isLoading}
                />
              </>
            )}

            <View style={styles.divider}>
              <View style={styles.dividerLine} />
              <Text style={styles.dividerText}>OU</Text>
              <View style={styles.dividerLine} />
            </View>

            {authMode === 'login' ? (
              <CustomButton 
                title="S'inscrire"
                onPress={() => setAuthMode('register')}
                disabled={isLoading}
                style={styles.anonymousButton}
                color={colors.secondary}
              />
            ) : (
              <CustomButton 
                title="Se connecter"
                onPress={() => setAuthMode('login')}
                disabled={isLoading}
                style={styles.anonymousButton}
                color={colors.secondary}
              />
            )}
                <View style={styles.socialButtonsContainer}>
                  <GoogleSignInButton
                    onPress={handleGoogleSignIn}
                    disabled={isLoading}
                  />
                </View>

          </View>
        </View>
      </ScrollView>
      {/* Ajout de l'espaceur en bas */}
      <View style={globalStyles.bottomSpacer} />

      {renderForgotPasswordModal()}

      <Modal
        visible={showEditProfile}
        transparent={true}
        animationType="fade"
        onRequestClose={() => handleEditProfileClose()}
      >
        <EditProfileModal 
          onClose={handleEditProfileClose}
          userData={userData}
          currentUser={{
            ...currentUser, 
            authMethod: userData?.authMethod || 'email',
            requiresCompletion: currentUser?.requiresCompletion
          }}
        />
      </Modal>

      <PhoneInputModal
        visible={showPhoneModal}
        onClose={handlePhoneModalClose}
        currentUser={currentUser}
      />

      {isLoading && (
        <View style={styles.loadingOverlay}>
          <ActivityIndicator size="large" color={colors.secondary} />
        </View>
      )}
    </SafeAreaView>
  );
};

export default AuthScreen;
