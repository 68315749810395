import { StyleSheet, Dimensions, Platform } from 'react-native';
import { colors } from './GlobalStyles';

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f5f5f5',
    marginHorizontal: 12,
    marginBottom: 20,
  },
  pointsCard: {
    backgroundColor: '#2E3B55',
    padding: 25,
    borderRadius: 20,
    marginVertical: 25,
    marginHorizontal: 15,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  pointsLabel: {
    color: '#fff',
    fontSize: 18,
    marginBottom: 15,
    fontWeight: '500',
    opacity: 0.9,
  },
  pointsValue: {
    color: '#fff',
    fontSize: 32,
    fontWeight: 'bold',
    letterSpacing: 1,
  },
  tabContainer: {
    flexDirection: 'row',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: 12,
    position: 'relative',
    marginHorizontal: 12,
    marginBottom: 20,
    minHeight: 56,
    padding: 4,
    overflow: 'hidden',
  },
  tab: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
    zIndex: 1,
  },
  tabContent: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
  },
  tabIcon: {
    marginBottom: 4,
    width: 18,
    height: 18,
  },
  tabText: {
    fontSize: 11,
    fontWeight: '600',
    color: colors.text,
    textAlign: 'center',
    marginTop: 4,
  },
  activeTabText: {
    color: colors.secondary,
    fontWeight: '700',
  },
  slider: {
    position: 'absolute',
    height: '85%',
    backgroundColor: 'white',
    borderRadius: 8,
    zIndex: 0,
    top: '7.5%',
    ...(Platform.OS === 'web' ? {
      transform: 'translateZ(0)',
      backfaceVisibility: 'hidden'
    } : {}),
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
  },
  section: {
    paddingHorizontal: 32,
    paddingBottom: 20,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.primary,
    marginBottom: 8,
    marginTop: 5,
  },
  sectionDescription: {
    fontSize: 14,
    color: '#666',
    marginBottom: 16,
    textAlign: 'center',
    fontStyle: 'italic',
    lineHeight: 20,
  },
  // Styles pour les cartes de service
  serviceCard: {
    backgroundColor: 'white',
    borderRadius: 12,
    padding: 15,
    marginBottom: 12,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
  },
  serviceInfo: {
    flex: 1,
  },
  serviceName: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
    marginBottom: 4,
  },
  servicePrice: {
    fontSize: 14,
    color: colors.text,
    marginBottom: 8,
  },
  pointsBadge: {
    alignSelf: 'flex-start',
    backgroundColor: '#E6F4EA',
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 12,
  },
  pointsText: {
    color: colors.accent,
    fontSize: 12,
    fontWeight: '600',
  },
  // Styles pour les cartes de récompense
  rewardCard: {
    backgroundColor: 'white',
    borderRadius: 12,
    padding: 15,
    marginBottom: 12,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
  },
  availableBadge: {
    backgroundColor: colors.accent,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
    marginLeft: 10,
  },
  availableText: {
    color: 'white',
    fontWeight: 'bold',
  },
  pointsNeeded: {
    backgroundColor: '#FFE5E5',
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 12,
    marginTop: 8,
  },
  pointsNeededText: {
    color: colors.secondary,
    fontSize: 12,
    fontWeight: '600',
  },
  // Styles pour l'historique
  historyCard: {
    backgroundColor: 'white',
    borderRadius: 12,
    padding: 15,
    marginBottom: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  transactionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  transactionDescription: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.text,
  },
  pointsChange: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  pointsPositive: {
    color: colors.accent,
  },
  pointsNegative: {
    color: colors.secondary,
  },
  detailRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 4,
  },
  detailText: {
    fontSize: 14,
    color: '#666',
  },
  finalPoints: {
    marginTop: 8,
    paddingTop: 8,
    borderTopWidth: 1,
    borderTopColor: '#eee',
  },
  finalPointsText: {
    fontSize: 14,
    color: colors.text,
    fontWeight: '500',
  },
  // États vides et chargement
  emptyState: {
    alignItems: 'center',
    padding: 32,
    backgroundColor: 'white',
    margin: 16,
    borderRadius: 12,
  },
  emptyIcon: {
    marginBottom: 16,
    opacity: 0.5,
  },
  emptyText: {
    fontSize: 18,
    fontWeight: '600',
    color: '#666',
    marginBottom: 8,
  },
  emptySubtext: {
    fontSize: 14,
    color: '#999',
    textAlign: 'center',
  },
  loadingOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  loadingText: {
    marginTop: 12,
    fontSize: 16,
    color: colors.primary,
  },
  // Bonus Card
  bonusCard: {
    marginTop: 20,
    backgroundColor: '#FFF9E5',
    borderRadius: 12,
    padding: 15,
  },
  bonusTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.secondary,
    marginBottom: 10,
  },
  bonusItem: {
    fontSize: 14,
    color: colors.text,
    marginBottom: 6,
  },
  // Subscription Card
  subscriptionCard: {
    backgroundColor: colors.primary,
    borderRadius: 12,
    padding: 20,
    marginTop: 20,
  },
  subscriptionTitle: {
    color: '#fff',
    fontSize: 22,
    fontWeight: '600',
    textAlign: 'center',
  },
  subscriptionPrice: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginVertical: 10,
  },
  subscriptionFeatures: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: 15,
    borderRadius: 10,
    marginTop: 10,
  },
  featureText: {
    color: '#fff',
    fontSize: 14,
    marginBottom: 8,
  },
  subscriptionButton: {
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 10,
    marginTop: 15,
    alignItems: 'center',
  },
  subscriptionButtonText: {
    color: colors.primary,
    fontWeight: 'bold',
    fontSize: 16,
  },
  // Styles pour le modal Premium
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  modalContent: {
    backgroundColor: '#fff',
    borderRadius: 16,
    width: '90%',
    maxHeight: '85%',
    alignSelf: 'center',
    overflow: 'hidden', // Important pour le bouton fixe
  },
  modalHeader: {
    backgroundColor: colors.primary,
    paddingVertical: 15,
    paddingHorizontal: 20,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255,255,255,0.1)',
  },
  modalHeaderTitle: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalHeaderPrice: {
    color: '#fff',
    fontSize: 24,
    fontWeight: '800',
    marginTop: 5,
  },
  modalScrollContent: {
    paddingHorizontal: 15,
    paddingTop: 5,
    paddingBottom: 70, // Réduit légèrement pour éviter trop d'espace en bas
  },
  modalSection: {
    backgroundColor: '#f8f9fa',
    borderRadius: 12,
    padding: 15,
    marginVertical: 10,
  },
  modalSectionTitle: {
    fontSize: 18,
    fontWeight: '600',
    color: colors.primary,
    marginBottom: 12,
    borderBottomWidth: 2,
    borderBottomColor: colors.accent,
    paddingBottom: 8,
  },
  modalFeature: {
    fontSize: 15,
    color: colors.text,
    marginBottom: 12,
    paddingLeft: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  modalFooter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    paddingHorizontal: 15,
    paddingVertical: 12,
    backgroundColor: 'transparent', // Changé à transparent
  },
  modalButton: {
    backgroundColor: colors.accent,
    borderRadius: 12,
    paddingVertical: 14,
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 2,
  },
  closeButton: {
    position: 'absolute',
    right: 15,
    top: 15,
    zIndex: 1,
    padding: 5,
  },
  modalTitle: {
    fontSize: 20, // Réduit depuis 20 ou 22
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 10,
    color: colors.primary,
  },
  modalPrice: {
    fontSize: 16, // Réduit depuis 18
    color: colors.primary,
    fontWeight: '600',
    textAlign: 'center',
    marginBottom: 15,
  },
  modalFeature: {
    fontSize: 14, // Réduit depuis 16
    color: colors.text,
    marginBottom: 8,
    paddingLeft: 10,
  },
  modalButton: {
    backgroundColor: '#28a745', // Vert standard
    borderRadius: 8,
    paddingVertical: 15,
    alignItems: 'center',
    marginTop: 20,
  },
  modalButtonText: {
    color: '#fff',
    fontSize: 16, // Réduit depuis 18
    fontWeight: 'bold',
  },
  vipBanner: {
    backgroundColor: colors.accent,
    padding: 15,
    borderRadius: 12,
    marginHorizontal: 16,
    marginTop: 10, // Réduit l'écart par le haut
    marginBottom: 30, // Espace en bas pour éviter qu’il touche les bords
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    elevation: 5,
  },
  vipText: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
  },
  vipDescription: {
    color: '#fff',
    fontSize: 14,
    marginTop: 8,
    textAlign: 'center',
  },
  modalVisualSection: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 5, // Réduit de 20 à 5
    marginBottom: 15, // Réduit aussi
    gap: 8, // Ajoute un petit espace entre les cartes
  },
  visualCard: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f9f9f9',
    borderRadius: 10,
    padding: 8, // Réduit légèrement
    width: 95, // Un peu plus petit
    height: 95, // Un peu plus petit
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 3,
  },
  visualCardText: {
    marginTop: 10,
    fontSize: 12,
    fontWeight: '600',
    textAlign: 'center',
    color: colors.text,
  },  
});