import React from 'react';
import { View, ScrollView, RefreshControl, Platform } from 'react-native';
import HeaderWithAnimation from './HeaderWithAnimation';
import globalStyles from '../screenStyles/GlobalStyles';

const ScreenWrapper = ({ children, currentUser, onRefresh, refreshing }) => {
  return (
    <View style={globalStyles.container}>
      <HeaderWithAnimation currentUser={currentUser} />
      <ScrollView
        style={globalStyles.scrollView}
        refreshControl={
          onRefresh ? (
            <RefreshControl
              refreshing={refreshing || false}
              onRefresh={onRefresh}
              colors={[globalStyles.colors.primary]}
            />
          ) : null
        }
      >
        <View style={globalStyles.screenContent}>
          {children}
        </View>
        <View style={globalStyles.bottomSpacer} />
      </ScrollView>
    </View>
  );
};

export default ScreenWrapper;
