import React, { useState, useRef, useEffect } from 'react';
import { View, Text, Modal, TouchableOpacity, ActivityIndicator, Pressable, ScrollView, Image, Linking } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import styles from './AppointmentManagerStyles';
import { colors } from '../../../screenStyles/GlobalStyles';
import * as ImagePicker from 'expo-image-picker';
import { Video } from 'expo-av';
import { doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { Dimensions, useWindowDimensions } from 'react-native';
import { WebView } from 'react-native-webview';

export const InfoModal = ({ visible, appointment, onClose }) => {
  if (!visible || !appointment) return null;

  const formattedDate = new Date(appointment.dateTime).toLocaleDateString('fr-FR', {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}
    >
      <Pressable 
        style={styles.modalOverlay}
        onPress={onClose}
      >
        <Pressable 
          style={styles.modalContent}
          onPress={e => e.stopPropagation()}
        >
          {/* Header avec titre et bouton fermer */}
          <View style={styles.modalHeader}>
            <Text style={styles.modalTitle}>Détails du rendez-vous</Text>
            <TouchableOpacity 
              style={[styles.closeButton, styles.headerCloseButton]}
              onPress={onClose}
            >
            <Ionicons name="close" size={20} color={colors.text} />
            </TouchableOpacity>
          </View>

          {/* Contenu du modal */}
          <View style={styles.modalBody}>
            {/* Date et heure */}
            <Text style={styles.infoLabel}>Date et heure</Text>
            <Text style={styles.infoValue}>{formattedDate}</Text>
            <Text style={styles.infoHighlight}>à {appointment.time}</Text>
            
            <View style={styles.infoSeparator} />

            {/* Prestation */}
            <Text style={styles.infoLabel}>Prestation</Text>
            <View style={styles.priceRow}>
              <Text style={styles.infoValue}>{appointment.service?.name}</Text>
              <Text style={styles.priceValue}>{appointment.service?.price}€</Text>
            </View>

            {/* Suppléments */}
            {appointment.bookingDetails?.supplements?.length > 0 && (
              <>
                <View style={styles.infoSeparator} />
                <Text style={styles.infoLabel}>Suppléments</Text>
                {appointment.bookingDetails.supplements.map((supp, index) => (
                  <View key={index} style={styles.supplementRow}>
                    <Text style={styles.supplementName}>{supp.name}</Text>
                    <Text style={styles.supplementPrice}>+{supp.price}€</Text>
                  </View>
                ))}
              </>
            )}

            {/* Prix total */}
            <View style={styles.infoSeparator} />
            <Text style={styles.infoLabel}>Prix total</Text>
            <Text style={styles.totalPrice}>{appointment.finalPrice}€</Text>

            {/* Client */}
            <View style={styles.infoSeparator} />
            <Text style={styles.infoLabel}>Client</Text>
            <Text style={styles.clientName}>
              {appointment.userInfo?.firstName} {appointment.userInfo?.lastName}
            </Text>

            {/* Statut de paiement */}
            <View style={styles.infoSeparator} />
            <Text style={styles.infoLabel}>Statut du paiement</Text>
            <View style={[
              styles.paymentStatus,
              { backgroundColor: appointment.payment?.status === 'paid' ? colors.success : colors.primary }
            ]}>
              <Text style={styles.paymentStatusText}>
                {appointment.payment?.status === 'paid' ? 'Payé' : 'En attente'}
              </Text>
            </View>
          </View>
        </Pressable>
      </Pressable>
    </Modal>
  );
};

export const CancelModal = ({ visible, appointment, onClose, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = async () => {
    try {
      setIsLoading(true);
      await onCancel(appointment);
      onClose();
    } catch (error) {
      console.error('Error canceling appointment:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!visible || !appointment) return null;

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onClose}>
      <Pressable 
        style={styles.modalOverlay}
        onPress={onClose}
      >
        <Pressable 
          style={styles.modalContent}
          onPress={e => e.stopPropagation()}
        >
          <View style={styles.modalHeader}>
            <Text style={styles.modalTitle}>Annuler le rendez-vous ?</Text>
            <TouchableOpacity 
              style={[styles.closeButton, styles.headerCloseButton]}
              onPress={onClose}
            >
              <Ionicons name="close" size={20} color={colors.text} />
            </TouchableOpacity>
          </View>

          <View style={styles.modalBody}>
            <View style={styles.warningIcon}>
              <Ionicons name="warning" size={48} color={colors.warning} />
            </View>
            
            <Text style={styles.warningTitle}>
              Êtes-vous sûr de vouloir annuler ce rendez-vous ?
            </Text>
            
            <Text style={styles.warningText}>
              {new Date(appointment.dateTime).toLocaleDateString('fr-FR', {
                weekday: 'long',
                day: 'numeric',
                month: 'long'
              })} à {appointment.time}
            </Text>
            
            <Text style={styles.warningSubtext}>
              Cette action est irréversible.
            </Text>

            <View style={styles.modalActions}>
              <TouchableOpacity
                style={[styles.modalButton, styles.cancelButton]}
                onPress={onClose}
                disabled={isLoading}
              >
                <Text style={styles.buttonText}>Non, garder</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={[styles.modalButton, styles.confirmButton]}
                onPress={handleCancel}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ActivityIndicator color="white" size="small" />
                ) : (
                  <Text style={[styles.buttonText, styles.confirmText]}>
                    Oui, annuler
                  </Text>
                )}
              </TouchableOpacity>
            </View>
          </View>
        </Pressable>
      </Pressable>
  </Modal>
);
};

export const ValidationModal = ({ visible, appointment, onClose, onComplete, onNoShow }) => {
const [isLoading, setIsLoading] = useState(false);

if (!visible || !appointment) return null;

return (
  <Modal
    animationType="fade"
    transparent={true}
    visible={visible}
    onRequestClose={onClose}
  >
    <Pressable 
      style={styles.modalOverlay}
      onPress={onClose}
    >
      <Pressable 
        style={styles.modalContent}
        onPress={e => e.stopPropagation()}
      >
        <View style={styles.modalHeader}>
          <Text style={styles.modalTitle}>Finaliser le rendez-vous</Text>
          <TouchableOpacity 
            style={[styles.closeButton, styles.headerCloseButton]}
            onPress={onClose}
          >
            <Ionicons name="close" size={20} color={colors.text} />
          </TouchableOpacity>
        </View>

        <View style={styles.modalBody}>
          <Text style={styles.validationText}>
            Comment souhaitez-vous finaliser ce rendez-vous ?
          </Text>

          <TouchableOpacity
            style={[styles.validationButton, styles.completedButton]}
            onPress={() => onComplete(appointment)}
            disabled={isLoading}
          >
            <Ionicons name="checkmark-circle" size={24} color="white" />
            <Text style={styles.validationButtonText}>
              Rendez-vous effectué
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={[styles.validationButton, styles.noShowButton]}
            onPress={() => onNoShow(appointment)}
            disabled={isLoading}
          >
            <Ionicons name="close-circle" size={24} color="white" />
            <Text style={styles.validationButtonText}>
              Client non présent
            </Text>
          </TouchableOpacity>
        </View>
      </Pressable>
    </Pressable>
  </Modal>
);
};

export const MediaModal = ({ visible, appointment, onClose }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [currentMedia, setCurrentMedia] = useState([]);
  const [currentVideoUrl, setCurrentVideoUrl] = useState(null);
  const videoRef = useRef(null);

  // Effet pour charger les médias au chargement du modal
  useEffect(() => {
    if (visible && appointment) {
      // S'assurer que appointment.id existe
      if (appointment.id) {
        const fetchMediaUrls = async () => {
          const appointmentRef = doc(db, 'appointments', appointment.id);
          const appointmentDoc = await getDoc(appointmentRef);
          if (appointmentDoc.exists()) {
            const data = appointmentDoc.data();
            setCurrentMedia(data.mediaUrls || []);
          }
        };
        fetchMediaUrls();
      }
    }
  }, [visible, appointment]);

  const handleMediaUpload = async () => {
    if (!appointment?.id) {
      alert("L’ID du rendez-vous est manquant.");
      return;
    }
  
    const appointmentRef = doc(db, "appointments", appointment.id);
  
    try {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: false,
        allowsMultipleSelection: true,
        quality: 0.8,
      });
  
      if (!result.canceled && result.assets.length > 0) {
        setIsUploading(true);
  
        const uploadPromises = result.assets.map(async (asset) => {
          const base64Data = asset.uri.split(",")[1];
          const isVideo = asset.uri.startsWith("data:video/");
          const mimeType = isVideo ? "video/mp4" : "image/jpeg";
          const resourceType = isVideo ? "video" : "image";
  
          // Convertir Base64 en Blob
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: mimeType });
  
          const formData = new FormData();
          formData.append("file", blob, `${Date.now()}.${isVideo ? "mp4" : "jpg"}`);
          formData.append("upload_preset", "LmPlanner");
          formData.append("folder", `appointments/${appointment.id}`);
          formData.append("resource_type", resourceType);
  
          // Envoyer la requête POST à Cloudinary
          const response = await fetch(
            `https://api.cloudinary.com/v1_1/dscm3kzzo/${resourceType}/upload`,
            {
              method: "POST",
              body: formData,
            }
          );
  
          const data = await response.json();
          console.log("Réponse Cloudinary :", data); // Log complet de la réponse
  
          if (!response.ok) {
            console.error("Cloudinary Error:", data.error);
            return null;
          }
  
          // Vérifiez que les données nécessaires sont présentes
          if (!data.secure_url || !data.delete_token) {
            console.error("Cloudinary Response Missing Data:", data);
            return null;
          }
  
          return { secure_url: data.secure_url, deletion_token: data.delete_token };
        });
  
        const uploadedMedia = (await Promise.all(uploadPromises)).filter((item) => item);
  
        // Séparer les URLs et tokens
        const uploadedUrls = uploadedMedia.map((item) => item.secure_url);
        const uploadedTokens = uploadedMedia.map((item) => item.deletion_token);
  
        console.log("Uploaded URLs :", uploadedUrls); // Log des URLs uploadées
        console.log("Uploaded Tokens :", uploadedTokens); // Log des tokens générés
  
        // Vérifiez qu'il y a bien des URLs et tokens à ajouter
        if (uploadedUrls.length > 0 && uploadedTokens.length > 0 && uploadedUrls.length === uploadedTokens.length) {
          await updateDoc(appointmentRef, {
            mediaUrls: arrayUnion(...uploadedUrls),
            deletionTokens: arrayUnion(...uploadedTokens),
            hasMedia: true,
          });
  
          console.log("Mise à jour Firestore réussie :"); // Confirmer la mise à jour
          console.log("mediaUrls :", uploadedUrls);
          console.log("deletionTokens :", uploadedTokens);
  
          setCurrentMedia((prev) => [...prev, ...uploadedUrls]);
        } else {
          console.error("Mismatch between media URLs and deletion tokens.");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Erreur: " + error.message);
    } finally {
      setIsUploading(false);
    }
  };
  

  const handleDeleteMedia = async (urlToDelete) => {
    try {
      const appointmentRef = doc(db, "appointments", appointment.id);
  
      // Récupérer les données de Firestore
      const snapshot = await getDoc(appointmentRef);
      const { mediaUrls = [], deletionTokens = [] } = snapshot.data();
  
      console.log("Médias Firestore :", mediaUrls);
      console.log("Tokens Firestore :", deletionTokens);
  
      const index = mediaUrls.indexOf(urlToDelete);
      console.log("Index trouvé :", index);
  
      if (index === -1) {
        throw new Error("URL de média introuvable dans Firestore.");
      }
  
      const deletionToken = deletionTokens[index];
      console.log("Token correspondant :", deletionToken);
  
      if (!deletionToken) {
        throw new Error("Token de suppression introuvable.");
      }
  
      // Supprimer le fichier via le token
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dscm3kzzo/delete_by_token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: deletionToken }),
        }
      );
  
      const data = await response.json();
      if (!response.ok || data.result !== "ok") {
        console.error("Cloudinary Error Response:", data);
        throw new Error("Erreur lors de la suppression du fichier Cloudinary");
      }
  
      // Supprimer le média localement et dans Firestore
      const newUrls = mediaUrls.filter((url) => url !== urlToDelete);
      const newTokens = deletionTokens.filter((_, i) => i !== index);
      await updateDoc(appointmentRef, {
        mediaUrls: newUrls,
        deletionTokens: newTokens,
        hasMedia: newUrls.length > 0,
      });
      setCurrentMedia(newUrls);
    } catch (error) {
      console.error("Erreur lors de la suppression :", error);
      alert(error.message);
    }
  };
   
   const renderMediaItem = (mediaUrl, index) => {
    const isVideo = mediaUrl.includes('video') || mediaUrl.endsWith('.mp4');
  
    // URL de miniature vidéo avec transformation Cloudinary
    const videoThumbnailUrl = isVideo
      ? mediaUrl.replace('/upload/', '/upload/so_1/').replace('.mp4', '.jpg') // Capture à 1 seconde
      : null;
  
    return (
      <TouchableOpacity
        key={index}
        style={styles.mediaContainer}
        onPress={() => {
          if (isVideo) {
            Linking.openURL(mediaUrl); // Ouvre la vidéo
          } else {
            setSelectedImage(mediaUrl); // Ouvre l'image
          }
        }}
      >
        {isVideo ? (
          <>
            {/* Miniature pour les vidéos */}
            <Image
              source={{ uri: videoThumbnailUrl }}
              style={styles.mediaPreview}
              onError={() => console.error(`Thumbnail not found for video: ${mediaUrl}`)}
            />
            {/* Icône Play pour identifier les vidéos */}
            <View style={styles.videoOverlay}>
              <Ionicons name="play-circle" size={32} color="white" />
            </View>
          </>
        ) : (
          // Miniature pour les images
          <Image
            source={{ uri: mediaUrl }}
            style={styles.mediaPreview}
            resizeMode="cover"
          />
        )}
        {/* Bouton de suppression pour chaque média */}
        <TouchableOpacity
          style={styles.deleteButton}
          onPress={() => handleDeleteMedia(mediaUrl)}
        >
          <Ionicons name="close-circle" size={20} color={colors.error} />
        </TouchableOpacity>
      </TouchableOpacity>
    );
  };
  

  return (
    <Modal
  visible={visible}
  transparent={true}
  animationType="fade"
  onRequestClose={onClose}
>
  <Pressable style={styles.modalOverlay} onPress={onClose}>
    <Pressable style={styles.modalContent} onPress={(e) => e.stopPropagation()}>
      <View style={styles.modalHeader}>
        <Text style={styles.modalTitle}>Photos et Vidéos</Text>
        <TouchableOpacity 
              style={[styles.closeButton, styles.headerCloseButton]}
              onPress={onClose}
            >
              <Ionicons name="close" size={20} color={colors.text} />
            </TouchableOpacity>
      </View>

      <View style={styles.modalBody}>
        {currentMedia.length > 0 ? (
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            style={styles.mediaScroll}
            contentContainerStyle={styles.mediaScrollContent}
          >
            {currentMedia.map((mediaUrl, index) => renderMediaItem(mediaUrl, index))}
          </ScrollView>
        ) : (
          <View style={styles.emptyMedia}>
            <Ionicons name="images-outline" size={48} color={colors.textLight} />
            <Text style={styles.emptyMediaText}>Aucun média disponible</Text>
          </View>
        )}

        <TouchableOpacity
          style={[
            styles.uploadButton,
            isUploading && { backgroundColor: colors.primary + "80" },
          ]}
          onPress={handleMediaUpload}
          disabled={isUploading}
        >
          {isUploading ? (
            <>
              <ActivityIndicator color="white" size="small" />
              <Text style={[styles.uploadButtonText, { marginLeft: 10 }]}>
                Upload en cours...
              </Text>
            </>
          ) : (
            <>
              <Ionicons name="cloud-upload-outline" size={24} color="white" />
              <Text style={styles.uploadButtonText}>
                {currentMedia.length > 0 ? "Ajouter des médias" : "Upload des médias"}
              </Text>
            </>
          )}
        </TouchableOpacity>
      </View>
    </Pressable>
  </Pressable>

  {/* Affichage plein écran pour une image */}
{selectedImage && (
  <Modal
    visible={!!selectedImage}
    transparent={true}
    animationType="fade"
    onRequestClose={() => setSelectedImage(null)}
  >
    <Pressable
      style={[styles.modalOverlay, { backgroundColor: "rgba(0, 0, 0, 0.9)" }]}
      onPress={() => setSelectedImage(null)}
    >
      <Image
        source={{ uri: selectedImage }}
        style={styles.fullScreenImage}
        resizeMode="contain"
      />
    </Pressable>
  </Modal>
)}

</Modal>
  );
};