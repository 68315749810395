import React, { useRef, useState, useEffect } from 'react';
import { Animated, View, TouchableOpacity, Image, Text } from 'react-native';
import { useRoute, useFocusEffect, useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import globalStyles, { colors } from '../screenStyles/GlobalStyles';
import { db } from '../firebase';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';

const VIPBadge = () => (
  <View style={globalStyles.vipBadge}>
    <Text style={globalStyles.vipBadgeText}>
      👑 VIP
    </Text>
  </View>
);

const HeaderWithAnimation = ({ currentUser }) => {
  const route = useRoute();
  const translateY = useRef(new Animated.Value(30)).current;
  const opacity = useRef(new Animated.Value(0)).current;
  const [isVIP, setIsVIP] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    let unsubscribe;
    if (currentUser) {
      unsubscribe = onSnapshot(doc(db, 'users', currentUser.uid), (doc) => {
        if (doc.exists()) {
          setIsVIP(doc.data().isVIP || false);
        }
      });
    }
    return () => unsubscribe && unsubscribe();
  }, [currentUser]);

  function getTitleForRoute(routeName) {
    switch (routeName) {
      case 'Accueil':
        return '💈 LmPlanner 💈';
      case 'Réservation':
        return '📅 Réservation 📅';
      case 'Fidélité':
        return '👑 Fidélité 👑';
      case 'Compte':
        return '👤 Mon Compte 👤';
      case 'Admin':
        return '⚙️ Administration ⚙️';
      default:
        return routeName;
    }
  }

  const renderProfileImage = () => {
    if (currentUser?.photoURL) {
      return (
        <Image 
          source={{ uri: currentUser.photoURL }}
          style={{
            width: 36,
            height: 36,
            borderRadius: 18,
            borderWidth: 2,
            borderColor: '#FFFFFF'
          }}
          resizeMode="cover"
        />
      );
    }
    return (
      <View style={{
        width: 36,
        height: 36,
        borderRadius: 18,
        backgroundColor: '#F0F9FF',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 2,
        borderColor: '#FFFFFF'
      }}>
        <Ionicons name="person" size={20} color={colors.primary} />
      </View>
    );
  };

  useFocusEffect(
    React.useCallback(() => {
      // Reset animations
      translateY.setValue(30);
      opacity.setValue(0);

      // Animate
      Animated.parallel([
        Animated.timing(translateY, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,  // Utilisation de useNativeDriver: false pour une web app
        }),
        Animated.timing(opacity, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false,  // Utilisation de useNativeDriver: false pour une web app
        }),
      ]).start();
    }, [route.name])
  );

  return (
    <View style={[globalStyles.header, { flexDirection: 'row', alignItems: 'center' }]}>
      {currentUser && route.name !== 'Compte' && (
        <TouchableOpacity
          style={{ position: 'absolute', left: 15, zIndex: 10 }}
          onPress={() => navigation.navigate('Compte')}
        >
          {renderProfileImage()}
        </TouchableOpacity>
      )}
      <Animated.Text
        style={[
          globalStyles.headerTitle,
          {
            transform: [{ translateY }],
            opacity,
            fontWeight: '900',
            fontSize: 24,
            flex: 1,
            textAlign: 'center',
          },
        ]}
      >
        {getTitleForRoute(route.name)}
      </Animated.Text>
      {currentUser && isVIP && <VIPBadge />}
    </View>
  );
};

export default HeaderWithAnimation;