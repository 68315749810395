export const SERVICES = {
    BASIC_CUT: {
      id: 'coupe',
      name: 'Coupe',
      price: 10,
      points: 50,
      type: 'main'
    },
    CUT_BEARD: {
      id: 'coupe-barbe',
      name: 'Coupe + Barbe',
      price: 15,
      points: 75,
      type: 'main'
    },
    CONTOURS: {
      id: 'contours',
      name: 'Contours',
      price: 5,
      points: 25,
      type: 'main'
    }
  };
  
  export const SUPPLEMENTS = {
    NO_SUPPLEMENT: {
      id: 'no_supplement',
      name: 'Aucun supplément',
      price: 0,
      points: 0,
      type: 'supplement'
    },
    ENHANCEMENT_BASIC: {
      id: 'enhancement-basic',
      name: 'Fibre Noir',
      price: 2,
      points: 10,
      type: 'supplement'
    },
    ENHANCEMENT_PRO: {
      id: 'enhancement-pro',
      name: 'Fibre Noir avec fixateur',
      price: 5,
      points: 25,
      type: 'supplement'
    },
    DESIGN: {
      id: 'design',
      name: 'Design/Trait simple',
      price: 2,
      points: 10,
      type: 'supplement'
    }
  };
  
  export const REWARDS = {
    HALF_OFF: {
      id: 'half-off',
      name: '-50% sur une coupe',
      points: 125,
      discount: 5,
      description: 'Réduction de 50% sur votre coupe'
    },
    FREE_CUT: {
      id: 'free-cut',
      name: 'Coupe gratuite',
      points: 250,
      discount: 10,
      description: 'Une coupe gratuite'
    },
    FREE_CUT_BEARD: {
      id: 'free-cut-beard',
      name: 'Coupe + Barbe gratuite',
      points: 375,
      discount: 15,
      description: 'Une coupe et une barbe gratuites'
    },
    FREE_DESIGN: {
      id: 'free-design',
      name: 'Design simple gratuit',
      points: 50,
      discount: 2,
      description: 'Un design simple gratuit'
    },
    FREE_ENHANCEMENT: {
      id: 'free-enhancement',
      name: 'Enhancement gratuit',
      points: 250,
      discount: 5,
      description: 'Un enhancement avec fixateur gratuit'
    }
  };