// pointsService.js
import { db } from '../firebase';
import { 
  doc, 
  getDoc, 
  runTransaction,
  collection, 
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
  Timestamp
} from 'firebase/firestore';

const isItemAffectedByReward = (itemId, reward) => {
  if (!reward) return false;

  switch (reward.id) {
    case 'free-cut':
    case 'half-off':
      return ['coupe', 'coupe-barbe'].includes(itemId);
    case 'free-cut-beard':
      return itemId === 'coupe-barbe';
    case 'free-design':
      return itemId === 'design';
    case 'free-enhancement':
      return ['enhancement-basic', 'enhancement-pro'].includes(itemId);
    default:
      return false;
  }
};

const pointsService = {
  async processBookingPoints(userEmail, bookingData) {
    if (!userEmail) {
      console.error('No user email provided');
      throw new Error('Email utilisateur manquant');
    }

    try {
      console.log('Processing booking points with data:', bookingData);
      
      // S'assurer que service a toutes les données nécessaires
      if (!bookingData.service) {
        throw new Error('Service non défini');
      }

      const serviceData = {
        id: bookingData.service.id || 'coupe',
        name: bookingData.service.name || 'Coupe',
        points: bookingData.service.points || 50,
        price: bookingData.service.price || 10
      };

      // Mettre à jour bookingData avec les données complètes
      bookingData.service = serviceData;

      // Trouver l'utilisateur par email
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', userEmail));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        throw new Error('Utilisateur non trouvé');
      }

      const userDoc = querySnapshot.docs[0];
      const userRef = doc(db, 'users', userDoc.id);
      let historyEntries = [];

      await runTransaction(db, async (transaction) => {
        const userSnapshot = await transaction.get(userRef);
        
        if (!userSnapshot.exists()) {
          throw new Error('Utilisateur non trouvé');
        }

        const currentPoints = parseInt(userSnapshot.data().loyaltyPoints) || 0;
        const isVIP = userSnapshot.data().isVIP || false;
        let newPoints = currentPoints;
        const timestamp = Timestamp.now();

        console.log('Current points:', currentPoints);

        // Déduire les points de récompense lors d'une nouvelle réservation
        if (bookingData.reward && !bookingData.isVIPFreeHaircut && bookingData.status === 'scheduled') {
          if (currentPoints < bookingData.reward.points) {
            throw new Error('Points insuffisants');
          }

          newPoints = currentPoints - bookingData.reward.points;
          console.log('Points déduits:', -bookingData.reward.points);

          // Historique de la réduction
          const historyDoc = doc(collection(db, 'pointsHistory'));
          const historyEntry = {
            userId: userDoc.id,
            userEmail,
            pointsChange: -bookingData.reward.points,
            type: 'spent',
            description: `Utilisation récompense : ${bookingData.reward.name}`,
            timestamp,
            serviceId: serviceData.id,
            serviceName: serviceData.name,
            finalPoints: newPoints,
            rewardId: bookingData.reward.id,
            rewardName: bookingData.reward.name,
            bookingDetails: {
              service: serviceData,
              reward: bookingData.reward,
              supplements: bookingData.bookingDetails?.supplements || []
            }
          };
          historyEntries.push({ doc: historyDoc, data: historyEntry });
        }

        // Ajouter les points si complété et payé
        if (bookingData.status === 'completed' && 
            bookingData.payment?.status === 'paid' && 
            !bookingData.pointsProcessed) {
          
          let pointsToEarn = 0;
          
          // Points du service principal
          if (!isItemAffectedByReward(serviceData.id, bookingData.reward)) {
            pointsToEarn = isVIP ? serviceData.points * 2 : serviceData.points;
            console.log('Points service:', pointsToEarn);
          }

          // Points des suppléments
          if (bookingData.bookingDetails?.supplements?.length > 0) {
            bookingData.bookingDetails.supplements.forEach(supplement => {
              if (supplement.points && !isItemAffectedByReward(supplement.id, bookingData.reward)) {
                const suppPoints = isVIP ? supplement.points * 2 : supplement.points;
                pointsToEarn += suppPoints;
                console.log('Points supplément:', suppPoints);
              }
            });
          }

          if (pointsToEarn > 0) {
            newPoints = currentPoints + pointsToEarn;
            console.log('Points totaux ajoutés:', pointsToEarn);

            // Historique des points gagnés
            const historyDoc = doc(collection(db, 'pointsHistory'));
            const historyEntry = {
              userId: userDoc.id,
              userEmail,
              pointsChange: pointsToEarn,
              type: 'earned',
              description: `Points gagnés${isVIP ? ' (VIP)' : ''} : ${serviceData.name}${
                bookingData.bookingDetails?.supplements?.length ? 
                  ` + ${bookingData.bookingDetails.supplements.length} supplément(s)` : 
                  ''
              }`,
              timestamp,
              serviceId: serviceData.id,
              serviceName: serviceData.name,
              finalPoints: newPoints,
              isVIP,
              bookingDetails: {
                service: serviceData,
                supplements: bookingData.bookingDetails?.supplements || []
              }
            };
            historyEntries.push({ doc: historyDoc, data: historyEntry });
          }
        }

        console.log('Updating points:', { 
          currentPoints, 
          newPoints,
          pointsChange: newPoints - currentPoints,
          isVIP
        });

        // Mettre à jour les points si changement
        if (newPoints !== currentPoints) {
          transaction.update(userRef, {
            loyaltyPoints: newPoints.toString()
          });

          // Créer les entrées d'historique
          historyEntries.forEach(entry => {
            transaction.set(entry.doc, entry.data);
          });
        }
      });

      // Marquer les points comme traités si nécessaire
      if (bookingData.id && 
          bookingData.status === 'completed' && 
          bookingData.payment?.status === 'paid' &&
          !bookingData.pointsProcessed) {
        const appointmentRef = doc(db, 'appointments', bookingData.id);
        await updateDoc(appointmentRef, {
          pointsProcessed: true,
          pointsProcessedAt: Timestamp.now()
        });
      }

      return { success: true };
    } catch (error) {
      console.error('Erreur lors du traitement des points :', error);
      throw error;
    }
  }
};

export { pointsService };