import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, Alert, Pressable } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import styles from '../screenStyles/BookingStyles';
import { colors } from '../screenStyles/GlobalStyles';
import WeekView from './WeekView';
import TimeSlots from './TimeSlots';
import ConfirmationModal from './ConfirmationModal';

const API_URL = 'https://13-39-76-140.nip.io';

const formatDateForAPI = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}T00:00:00.000Z`;
};

const getWeekDates = (date) => {
  const week = [];
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);
  
  const currentDay = startDate.getDay();
  const diff = startDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1);
  startDate.setDate(diff);

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    week.push(currentDate);
  }
  return week;
};

const formatMonthYear = (date) => {
  if (!date) return '';
  const month = date.toLocaleDateString('fr-FR', { month: 'long' });
  return month.charAt(0).toUpperCase() + month.slice(1);
};

const formatDateKey = (date) => {
  if (!date) return '';
  return date.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
};

const BookingCalendar = ({ user, onBookingConfirmed }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  const [currentWeek, setCurrentWeek] = useState(getWeekDates(today));
  const [selectedDate, setSelectedDate] = useState(today);
  const [availableSlots, setAvailableSlots] = useState({});
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBookingInProgress, setIsBookingInProgress] = useState(false);

  const fetchAvailableSlots = useCallback(async () => {
    const currentDate = selectedDate; // Capture la date actuelle
    setIsLoading(true);
    try {
      const formattedDate = formatDateForAPI(currentDate);
      const response = await fetch(
        `${API_URL}/api/slots/${formattedDate}`,
        { 
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          cache: 'no-store'
        }
      );
      
      if (!response.ok) throw new Error('Erreur lors du chargement des créneaux');
      
      const result = await response.json();

      if (result.success && Array.isArray(result.data)) {
        // Vérifie si la date sélectionnée n'a pas changé pendant le chargement
        if (currentDate.getTime() === selectedDate.getTime()) {
          const dateKey = formatDateKey(currentDate);
          setAvailableSlots(prev => ({
            ...prev,
            [dateKey]: result.data
          }));
        }
      }
    } catch (error) {
      console.error('Error fetching slots:', error);
      Alert.alert('Erreur', 'Impossible de charger les créneaux disponibles');
    } finally {
      // Ne désactive le chargement que si on est toujours sur la même date
      if (currentDate.getTime() === selectedDate.getTime()) {
        setIsLoading(false);
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    fetchAvailableSlots();
  }, [selectedDate]);

  const handleRefresh = useCallback(() => {
    if (!isLoading && !isBookingInProgress) {
      fetchAvailableSlots();
    }
  }, [fetchAvailableSlots, isLoading, isBookingInProgress]);

  const handleDateSelect = useCallback(async (date) => {
    if (!date || isBookingInProgress) return;
    
    setSelectedDate(date);
    setSelectedSlot(null);
  }, [isBookingInProgress]);

  const handleSlotPress = useCallback((slot) => {
    if (isBookingInProgress) return;
    
    setSelectedSlot({
      ...slot,
      date: selectedDate
    });
    setShowConfirmation(true);
  }, [isBookingInProgress, selectedDate]);

  useEffect(() => {
    // Écouter l'événement de rafraîchissement
    const handleRefreshEvent = (event) => {
      const { date } = event.detail;
      if (date) {
        const refreshDate = new Date(date);
        // Si la date du rafraîchissement correspond à la date sélectionnée, rafraîchir
        if (selectedDate.toDateString() === refreshDate.toDateString()) {
          fetchAvailableSlots();
        }
      }
    };
  
    window.addEventListener('refreshSlots', handleRefreshEvent);
  
    return () => {
      window.removeEventListener('refreshSlots', handleRefreshEvent);
    };
  }, [selectedDate, fetchAvailableSlots]);
  
  const handleConfirm = useCallback(async (bookingData) => {
    if (!selectedSlot || !user || isBookingInProgress) return;
  
    setIsBookingInProgress(true);
    try {
      // Force un rafraîchissement des créneaux avant de tenter la réservation
      await fetchAvailableSlots();
      
      console.log('Received booking data:', JSON.stringify(bookingData, null, 2));
      
      // Extract the service from the booking data
      const selectedService = bookingData.service;
      
      // Ensure we have valid service data
      if (!selectedService || !selectedService.name) {
        throw new Error('Invalid service data');
      }

      // Create the booking request
      const bookingRequest = {
        service: bookingData.service,
        date: formatDateForAPI(selectedDate),
        time: selectedSlot.time,
        finalPrice: bookingData.finalPrice,
        pointsToEarn: bookingData.pointsToEarn,
        supplements: bookingData.supplements,
        reward: bookingData.reward
      };

      console.log('Sending booking request:', JSON.stringify(bookingRequest, null, 2));
      const success = await onBookingConfirmed(bookingRequest);
  
      if (success) {
        setShowConfirmation(false);
        setSelectedSlot(null);
        setTimeout(() => {
          fetchAvailableSlots();
        }, 1000);
      }
    } catch (error) {
      console.error('Error in booking:', error);
    } finally {
      setIsBookingInProgress(false);
    }
  }, [selectedSlot, user, selectedDate, onBookingConfirmed, fetchAvailableSlots, isBookingInProgress]);

  const handleCloseConfirmation = useCallback(() => {
    if (!isBookingInProgress) {
      setShowConfirmation(false);
      setSelectedSlot(null);
    }
  }, [isBookingInProgress]);

  const canNavigatePrevious = useCallback(() => {
    const firstDate = currentWeek[0];
    return firstDate >= today;
  }, [currentWeek, today]);

  const canNavigateNext = useCallback(() => {
    const lastDate = currentWeek[6];
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 60);
    return lastDate < maxDate;
  }, [currentWeek]);

  const navigateWeek = useCallback((direction) => {
    if (isBookingInProgress) return;

    const newStartDate = new Date(currentWeek[0]);
    newStartDate.setDate(newStartDate.getDate() + (7 * direction));
    const newWeek = getWeekDates(newStartDate);
    setCurrentWeek(newWeek);
    handleDateSelect(direction > 0 ? newWeek[0] : today);
  }, [currentWeek, handleDateSelect, today, isBookingInProgress]);

  useEffect(() => {
    // Écouter l'événement de rafraîchissement
    const handleRefreshEvent = (event) => {
      const { date } = event.detail;
      if (date) {
        const refreshDate = new Date(date);
        // Si la date du rafraîchissement correspond à la date sélectionnée, rafraîchir
        if (selectedDate.toDateString() === refreshDate.toDateString()) {
          fetchAvailableSlots();
        }
      }
    };
  
    window.addEventListener('refreshSlots', handleRefreshEvent);
  
    return () => {
      window.removeEventListener('refreshSlots', handleRefreshEvent);
    };
  }, [selectedDate, fetchAvailableSlots]);
  

  return (
    <View style={styles.calendarContainer}>
      <View style={styles.calendarHeader}>
        <Pressable
          onPress={() => canNavigatePrevious() && navigateWeek(-1)}
          style={[
            styles.navButton,
            !canNavigatePrevious() && styles.disabledNavButton
          ]}
          disabled={!canNavigatePrevious() || isBookingInProgress}
        >
          <Ionicons name="chevron-back" size={24} color={colors.text} />
        </Pressable>

        <View style={styles.monthContainer}>
          <Text style={styles.monthText}>
            {formatMonthYear(currentWeek[0])}
          </Text>
          <Text style={styles.yearText}>
            {currentWeek[0].getFullYear()}
          </Text>
        </View>

        <Pressable
          onPress={() => canNavigateNext() && navigateWeek(1)}
          style={[
            styles.navButton,
            !canNavigateNext() && styles.disabledNavButton
          ]}
          disabled={!canNavigateNext() || isBookingInProgress}
        >
          <Ionicons name="chevron-forward" size={24} color={colors.text} />
        </Pressable>
      </View>

      <WeekView
        dates={currentWeek}
        selectedDate={selectedDate}
        onSelectDate={handleDateSelect}
        isLoading={false}
      />

      <TimeSlots
        date={selectedDate}
        slots={availableSlots[formatDateKey(selectedDate)] || []}
        onSelectSlot={handleSlotPress}
        isLoading={isLoading}
        onRefresh={handleRefresh}
        user={user}
        disabled={isBookingInProgress}
      />

      <ConfirmationModal
        visible={showConfirmation}
        slot={selectedSlot}
        user={user} // Remplacer par userData car il contient les points
        onConfirm={handleConfirm}
        onClose={handleCloseConfirmation}
        isLoading={isBookingInProgress}
      />
    </View>
  );
};

export default BookingCalendar;