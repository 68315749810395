import React, { useState, useEffect } from 'react';
import { 
  View, 
  Text, 
  Modal, 
  ActivityIndicator,
  Animated,
  KeyboardAvoidingView,
  Platform,
  TextInput,
  StyleSheet
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { authService } from '../firebase';
import CustomButton from './CustomButton';
import { colors } from '../screenStyles/GlobalStyles';
import styles from '../screenStyles/AuthStyles';

const FormField = ({ 
  label, 
  icon, 
  error, 
  value, 
  onChangeText,
  success,
  required,
  ...props 
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const isValidPhone = (phone) => {
    const cleanNumber = phone.replace(/\D/g, '');
    return cleanNumber.length === 10 && (cleanNumber.startsWith('06') || cleanNumber.startsWith('07'));
  };

  return (
    <View style={styles.fieldContainer}>
      <View style={styles.fieldLabelContainer}>
        <Text style={styles.fieldLabel}>{label}</Text>
        {required && <Text style={styles.requiredStar}>*</Text>}
      </View>
      
      <View style={[
        styles.inputContainer,
        isFocused && styles.inputContainerFocused,
        error && styles.inputContainerError,
        success && styles.inputContainerSuccess
      ]}>
        <Ionicons 
          name={icon} 
          size={24} 
          color={error ? colors.secondary : isFocused ? colors.primary : colors.text} 
        />
        <TextInput
          style={styles.input}
          value={value}
          onChangeText={onChangeText}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholderTextColor={colors.placeholder}
          {...props}
        />
        {success && !error && isValidPhone(value) && (
          <Ionicons 
            name="checkmark-circle" 
            size={20} 
            color={colors.success}
            style={styles.validationIcon} 
          />
        )}
      </View>
      
      {error && (
        <View style={styles.errorContainer}>
          <Ionicons name="alert-circle" size={16} color={colors.secondary} />
          <Text style={styles.errorText}>{error}</Text>
        </View>
      )}
    </View>
  );
};

const PhoneInputModal = ({ visible, onClose, currentUser }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const fadeAnim = useState(new Animated.Value(0))[0];

  useEffect(() => {
    if (visible) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
  }, [visible]);

  const validatePhone = (phone) => {
    if (!phone) {
      return 'Le numéro de téléphone est requis';
    }
    const cleanNumber = phone.replace(/\D/g, '');
    if (cleanNumber.length !== 10) {
      return 'Le numéro doit contenir 10 chiffres';
    }
    if (!cleanNumber.startsWith('06') && !cleanNumber.startsWith('07')) {
      return 'Le numéro doit commencer par 06 ou 07';
    }
    return '';
  };

  const handleSubmit = async () => {
    const validationError = validatePhone(phoneNumber);
    if (validationError) {
      setError(validationError);
      return;
    }

    setIsLoading(true);
    try {
      // Vérifier si le numéro existe déjà
      const phoneCheck = await authService.checkPhoneExists(phoneNumber);
      if (phoneCheck.exists) {
        setError('Ce numéro est déjà utilisé');
        return;
      }

      // Mettre à jour le profil avec le numéro de téléphone
      const result = await authService.updateUserProfile(currentUser.uid, {
        phoneNumber,
        authMethod: 'google'
      });

      if (result.success) {
        onClose(true);
      } else {
        setError(result.error || 'Une erreur est survenue');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour:', error);
      setError('Une erreur est survenue');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      transparent
      animationType="fade"
      onRequestClose={() => onClose(false)}
    >
      <KeyboardAvoidingView 
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.modalOverlay}
      >
        <Animated.View 
          style={[
            styles.modalContent,
            { 
              opacity: fadeAnim,
              transform: [{
                scale: fadeAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0.9, 1],
                }),
              }],
            },
          ]}
        >
          <View style={styles.modalHeader}>
            <Text style={styles.modalHeaderTitle}>Numéro de téléphone requis</Text>
          </View>

          <View style={[styles.modalScrollView, { padding: 20 }]}>
            <Text style={styles.modalDescription}>
              Pour finaliser l'inscription avec Google, fourni ton numéro de téléphone.
            </Text>

            <FormField
              label="Numéro de téléphone"
              icon="call-outline"
              error={error}
              value={phoneNumber}
              onChangeText={(text) => {
                setPhoneNumber(text);
                setError('');
              }}
              keyboardType="phone-pad"
              required
              success={phoneNumber && !error}
              placeholder="Ex: 06 12 34 56 78"
            />

            <View style={[styles.buttonGroup, { marginTop: 20 }]}>
              <CustomButton 
                title="Valider"
                onPress={handleSubmit}
                style={[styles.saveButton]}
                disabled={isLoading}
                icon="checkmark-circle-outline"
              />
            </View>
          </View>

          {isLoading && (
            <View style={styles.loadingOverlay}>
              <ActivityIndicator size="large" color={colors.secondary} />
            </View>
          )}
        </Animated.View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

export default PhoneInputModal; 